
.containerPestañas{
    overflow-x: auto;
}

.botonCIA{
    border-radius: 7px;
    color: white;
    background-color: #213265;
    padding: 5px;

}
.botonCIA:hover{
    background-color: #778da9;
}

.containerPestañas {
  scrollbar-color: #213265 transparent;
  scrollbar-width: thin;
}
.containerPestañas::-webkit-scrollbar {
    height: 6px;
}
.containerPestañas::-webkit-scrollbar-thumb {
    background: #213265;
    border-radius: 10px;
}




.containerOcs{
    /* background-color: red; */
    width: 100%;
    display: flex;
    justify-content: space-between;

    flex-direction: row;
    height: 60vh;
}

.containerOcs.inv{
    /* background-color: red; */
    height: auto;
    align-items: center;
    justify-content: center;
}




.mapa{
    width: 7000px;
    height: 100%;
}

.mapa-invt{
    width: 400px;
    height: 400px;
}

.scrollSelectNoticiasMas{
    display: flex;
    width: 30%;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    background-color: RGBA(33,50,101,0.18);
    border-radius: 7px;
    /* border: 1px solid #e0f0f0; */
}


.descripcion{
    width: 100%;
    height: 30vh;
    margin-top: 20px;
    overflow-y: auto;
}

.cardScroll{
    display: flex;
    justify-content: center;
    border: 1px;
    padding: 2px;
    padding-top:10px;
    margin-bottom: 5px;
    cursor: pointer;
}

.noticias{
    background-color: white;
    text-align: center;
}

.containerNoticias{
    background-color: white;
}


.scrollSelectNoticiasMas::-webkit-scrollbar {
    width: 10px;
}
.scrollSelectNoticiasMas::-webkit-scrollbar-thumb {
    background: #213265;
    border-radius: 10px;
}

.descripcion::-webkit-scrollbar {
    width: 10px;
}
.descripcion::-webkit-scrollbar-thumb {
    background: #213265;
    border-radius: 10px;
}

.containerTitulo{
    cursor: pointer;
}




@media screen and ( max-width:800px) {


    .containerOcs{
        flex-direction: column;
    }

    .mapa{
        width: 100%;
        height: 50%;
    }



    .scrollSelectNoticiasMas{
        width: 100%;
        height: 50%;
        margin-bottom: 10px;
        flex-direction: row;

    }


}
