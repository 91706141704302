// @import '../../../../root.scss';

.titleCardConclusiones{
  width: 90%;
  margin-left: 4%;
  
}

#formConclusiones{
  padding: 0;
}

#contenedorSelectsConclusiones{
  display: flex;
  width: 90%;
  margin: 0 auto;
  padding: 0;

  .form-group{
    width: 100%;
  }

}

.textAreaGroupConclusiones{
  // background-color: green;
  width: 92%;
  margin: 0 auto;
  padding: 0;
  
  

  .textAreaConclusiones{
    width: 100%;
    min-height: 100px;
    resize: both;
  }
}

@media only screen and (max-width: 1020px)  {
  .textAreaGroupConclusiones{
    width: 100%;
  }
}