@import '/src/root.scss';

.hallazgo_item{
  // background-color: red;
  width: 100%;

  display: grid;
  grid-template: 1.3fr 4fr / 0.4fr 1fr 1fr ;
  height: 200px;
  margin-bottom: 15px;
  border-radius: 10px;
  overflow: hidden;
}

.hallazgo_item-header{
  background-color: #EFEFEF;
  grid-row: 1 / 2;
  grid-column: 1 / 4;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p{
    margin: 0;
    padding: 0 10px;
  }


  .id_hallazgo{
    margin: 0;
    font-size: 1.6em;
    font-weight: 500;
    color: $Blue-dark-soft;
  }
}

.hallazgo_item-pestanas{
  // background-color: green;
  grid-row: 2;
  grid-column: 1 / 2;

  .pestana_hallazgo{
    cursor: pointer;
    height: 35px;
    // background-color: yellow;
    width: 97%;
    margin: 0 7px ;
    border-radius: 7px;
    font-size: 1.6em;
    font-weight: 600;
    color: #0C2954;
    transition: 0.1s;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 5px;



    &:hover{
      background-color: rgb(223, 223, 223);
    }
  }
}

.hallazgo_item-main{
  background-color: rgb(244, 244, 244);
  grid-row: 2;
  grid-column: 2 / 4;
  width: 95%;
  height: 85%;
  margin: 0 auto auto;
  border-radius: 10px;
  overflow-y: auto;

  .hallazgo_item_contenido{
    font-size: 1.5em;
    text-align: justify;
    padding: 8px;
    //Mostrar saltos de línea establecidos con \n
    white-space: pre-wrap;


  }

}

.hallazgo_item-main::-webkit-scrollbar {
  width: 10px;     /* Tamaño del scroll en vertical */
  height: 8px;    /* Tamaño del scroll en horizontal */
  // display: none;  /* Ocultar scroll */
}

.hallazgo_item-main::-webkit-scrollbar-track {
  background: $White;
  border-radius: 4px;
}

.hallazgo_item-main::-webkit-scrollbar-thumb {
  background: $Gray-fonts;
  border-radius: 4px;
}

.btns_hallazgo{
  background-color: white;
  border-radius: 10px;
  display: flex;
  width: auto;
  margin: 0 10px;

  & button{
    width: 80px;
    margin: 0;
  } 
}