//@import url('primeicons/primeicons.css');

:root{
  font-size: 16px;
}

input[type=number]
{
    -webkit-appearance: textfield !important;
    margin: 0;
    -moz-appearance:textfield !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// .amcharts-amexport-menu {
//   // background-color: red;
//   // width: 60px;
// }

// .amcharts-amexport-menu amcharts-amexport-menu-level-0 amcharts-amexport-menu-root amcharts-amexport-right amcharts-amexport-top{
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

.amcharts-amexport-item.amcharts-amexport-item-level-0 > a{
  text-decoration: none;
}

.amcharts-amexport-item a {
  text-decoration: none !important;

  &:hover{
    color: #213265;
  }

}

.amcharts-amexport-item-level-1  {
  text-decoration: none;

}

.amcharts-amexport-item {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  background-color: #8b97a7 !important;
  // margin-top: 12% !important;
  // position: relative !important ;
  // margin-top: 20px;
  // padding: 0;


  a{
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // min-height: 30px;
    // padding: 0;
    color: white;
    font-size: 0.8rem;

    &:hover{
      // background-color: #213265 ;
      transition: 0.2s;
      font-weight: 600;
    }

  }

}

// .amcharts-amexport-item.amcharts-amexport-item-level-0 > a{
//   font-size: 2.2rem;
//   position: relative;
//   // background-color: white !important;
//   padding-top: -60px;
//   color: #213265;
//   // width: auto;
//   height: 35px;

//   font-weight: 600;

//   // &:hover{
//   //   // transform: rotate(-90deg);
//   //   background-color: #d5d5d5 !important ;
//   //   border-radius: 30px;
//   //   // width: 50px;
//   // }


// }

.amcharts-amexport-item.amcharts-amexport-item-level-0{
  border-radius: 50px;
  background-color: rgb(242, 242, 242) !important;

  > a{
    color: #213265;
    font-size: 1.2rem;
    font-weight: 900;


  }

  &:hover{
    background-color: #dadada !important ;

  }

}

.amcharts-amexport-menu-level-0.amcharts-amexport-top {
  top: -50% !important ;
  bottom: auto;
  right: 10px !important;
}



.amcharts-amexport-item:hover > .amcharts-amexport-menu, .amcharts-amexport-item.active > .amcharts-amexport-menu {
    margin-top: 33px;
  margin-right: 0px !important;

}


.cardGraficaGeneral{
  padding: 10px;
}

$Green: #00a20B;
$Red: #CC1A3A;
$Yellow: #E4A731;
$Light-yellow: #FEFBD2;
$Gray-dark: #323232;
$Blue-dark: #213265;
$Blue-dark-soft:#8b97a7;
$Blue-soft:#cbd7e6;
$White-dark: #e0e0e0;
$White-dark-soft: #efefef;
$White: #ffffff;
$Gray-fonts: #707070;
$FuenteValor: 18px;
// $BackgroundModales: rgba(22, 45, 68, 0.8);
$BackgroundModales: rgba(3, 14, 69, 0.338);
$Blue: #0275d8;;

//colores graficas
$rojo: #d63730 ;
$naranja: #F39C11 ;
$verde: #8BC34A ;
$marino: #009688 ;
$celeste: #05a4db ;
$azul: #2c5e99 ;

*{
  font-family: Lato, sans-serif;
}

.modal-backdrop{
  background-color: none;
}

.modal-backdrop.show {
  opacity: 0;
}

// body{
//   display: block;
// }

.iconoAyuda{
  cursor: pointer;
}

.titleContainer{
  display: flex;
  justify-content: stretch;
  // height: 60px;

  align-items: flex-end;

}

.titulo__pagina {
  font-size: 1.5rem;
  font-weight: 800;
  color: $Blue-dark;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
}

.pagesTitles{
  color: $Blue-dark;
  font-weight: 800;
  // margin: auto;
  text-align: justify;
  width: auto;
  padding: 0;
  margin: 0;
  text-align: center;
}

.titleLine{
  margin-left: 4px;
  // margin-top: 20%;
  // margin-bottom: 8px;
  height: 5px;
  width: 35px;
  background-color: $Blue-dark;
  border-radius: 20px;
}

.titleInsideCard{
  background-color: var(--gray-100);
  font-size: 2.5rem;
  color: #213265;
  font-weight: 800;
  border-radius: .75rem;
  padding: 4px 16px;
  margin-bottom: 1rem;
}

.cardGeneral{
  border-radius: 10px;
}

.simpleShadowCard {
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  border-radius: 10px;
}


.simpleGrayCard {
  background-color: #f8fafc;
  border-radius: 10px;
}

.cardUnderPestana{
  width: 100%;
  border-radius: 0px 10px 10px 10px;
  padding: 3%
  
}

.btn_circular_white{
  // display: block;
  position: float;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  left: 290px;
  top: 40px;
  transition: 0.10s;
  -webkit-box-shadow: 0px 3px 3px 2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 3px 3px 2px rgba(0,0,0,0.75);
  box-shadow: 0px 3px 3px -1px rgba(114, 114, 114, 0.75);

  &:hover{
    background-color: white;
    transform: scale(1.05);
  }
}

.confirmButtonWhite{
  -webkit-box-shadow: 0px 3px 3px 2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 3px 3px 2px rgba(0,0,0,0.75);
  box-shadow: 0px 3px 3px -1px rgba(114, 114, 114, 0.75);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: $White  !important;
  width: 90px;
  height: 35px;
  color: $Gray-fonts ;
  border: none !important;
  border-radius: 15px ;
  margin-right: 40px;
  // margin-top: 20px;
  font-size: 0.9rem;
  font-weight: 600;
  transition: 0.25s;

  &:hover{
    background-color: $Green !important;
    color: white;
  }
}

.confirmButton{
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: $Blue-dark  !important;
  min-width: 90px;
  width: auto;
  padding: 0 16px;
  height: 35px;
  color: $White !important ;
  border: none !important;
  border-radius: 7px !important;
  margin-right: 40px;
  // margin-top: 20px;
  font-size: 0.9rem;
  font-weight: 600;
  transition: 0.25s;

  &:hover{
    background-color: $Green !important;

  }
  //Disabled style
  &:disabled{
    background-color: #d7d7d7 !important;
    color: $Gray-fonts !important;
    cursor: not-allowed;
  }
}

.btn-busqueda{
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: $Blue-dark  !important;
  width: 90px;
  height: 35px;
  color: $White !important ;
  border: none !important;
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  margin-right: 40px;
  // margin-top: 20px;
  font-size: 0.9rem;
  font-weight: 600;
  transition: 0.25s;

  &:hover{
    background-color: $Green !important;

  }
}

.warningButton{
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: $Blue-dark  !important;
  width: 90px;
  height: 35px;
  color: $White !important ;
  border: none !important;
  border-radius: 7px !important;
  margin-right: 40px;
  // margin-top: 20px;
  font-weight: 600;
  font-size: 0.9rem;
  transition: 0.25s;


  &:hover{
    background-color: $Yellow !important;

  }
}

.dangerButton{
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: $Blue-dark;
  height: 35px !important;
  width: 90px;
  border-radius: 10px !important;
  font-size: 0.9rem;
  font-weight: 600;
  border: none !important;
  color: white !important;
  // margin: auto;


  &:hover{
    background-color: $Red !important;
    transition: 0.1s;
  }
}

table{
  border-radius: 10px;
  width: 100%;
}

select{
  border-radius: 20px;
  // min-width: 140px;
  width: auto;
  height: 35px;
  outline: none;
  padding: 0 10px;
  border: 2px solid $Blue-dark;

}

.searchContainer{
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 20px;
  border: 2px solid $Blue-dark;
  padding: 0 10px;

  input{
    border: none;
    padding: 0 5px ;
    outline: none;
    font-size: 16px;
  }
}

:root::-webkit-scrollbar{
  width: 12px;
  margin: 100px 0;
}

:root::-webkit-scrollbar-thumb{
  border-radius: 30px;
  background-color: $Blue-dark;
  // display: none;
  // color: red;
}

:root::-webkit-scrollbar-thumb:hover{
  // background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

:root::-webkit-scrollbar-track{
  background-color: $White;
  border-radius: 30px;
  height: 50vh;
}

.modalesGenerales{
  opacity: 1 !important;
  background-color: $BackgroundModales ;
}

form {
  width: 100%;
  margin-top: 10px;
  padding-right: 30px;
  label {
    // font-size: 0.9rem;
    display: flex;
    justify-content: space-between;
    // align-items: center;

    select {
      width: 20%;
      height: 30px;
      border-radius: 7px;
      border: 1px solid $Gray-fonts;
      outline: none;
      background-color: $White;
    }

    input {
      // width: 70%;
      height: 30px;
      margin: 3px 0;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 7px;
      border: 1px solid $Gray-fonts;
      outline: none;
    }
  }
}

.form-group{
  display: grid;
  grid-template: 1fr / 1fr 3fr;
  justify-content: stretch;
  align-items: center;
  margin-bottom: 10px;

  div > #textArea{
    height: auto;
  }


  .form-label{
    font-weight: 700;
    color: var(--gray-700) !important;
    margin-bottom: 4px;

    ~ div.card {
      background-color: transparent;
    }

  }

  .idContainer{
    display: grid;
    align-content: space-evenly;
    // justify-items: center;
    grid-template: 1fr / 5fr 4fr;

    .custom-select{
   

    &#sexoSelect{
      grid-column: 1 / 4;
      width: 100%;

    }

    }

  }

  .form-control {
    padding: 4px 8px;
    height: 40px;
  }

  .form-control, .css-yk16xz-control{
    width: 100%;
    margin-bottom: 8px;
    margin-top: 0;
    border-radius: 5px;
    border: 1px solid var(--gray-300);
    outline: none;
    font-size: 14px;
    color: var(--gray-800);
    height: 40px;
  }

  .select__multiple__control.css-yk16xz-control {
    height: auto !important;
  }

  .css-yk16xz-control:hover {
    border: 1px solid var(--gray-200);
  }

  .form-control:focus, .css-1pahdxg-control {
    border: 1px solid var(--blue-200);
    box-shadow: 0 0 0 0.2rem #dfebfe;
    height: 40px;
  }

  .select__multiple__control.css-1pahdxg-control {
    height: auto;
  }

  .form-control:disabled, .css-1fhf3k1-control {
    background-color: var(--gray-50);
    border: 1px solid var(--gray-200);
    color: var(--gray-600);
    cursor: not-allowed;
  }
}

.titleBox{
  display: flex;
  justify-content: space-between;
  align-items: center;
  // width: 100%;
  padding-right: 10px;
  margin-top: 32px;
}

.iconoGeneral{
  color: $Blue-dark;
}

.titleBlue{
  font-weight: 800;
  color: $Blue-dark;
}

.botonesAyuda{
  display: block;
  text-align: right;
  margin: 10px 10px 1px auto;
  cursor: pointer;
  top: 20px;
  right: 20px;
  width: auto;

  // &:hover{
  //   // background-color: $Blue-dark-soft;
  //   color: $White;
  //   border-radius: 50%;
  // }

}

.botonEditar{
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: $Blue-dark;
  height: 30px;
  font-weight: 600;
  margin-right: 10px;
  width: 90px;
  border-radius: 10px !important;
  font-size: 0.8rem;
  border: none !important;
  color: white !important;
  margin: auto;
  transition: 0.1s;

  &:hover{
    background-color: #F39C11 !important;
  }
}

.botonEliminar{
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: $Blue-dark;
  height: 35px !important;
  width: 90px;
  border-radius: 10px !important;
  font-size: 0.8rem;
  font-weight: 600;
  border: none !important;
  color: white !important;
  margin: auto;
  transition: 0.1s;


  &:hover{
    background-color: $Red !important;
  }
}

.contenedorTextAreaGeneral{
  display: flex;
  width: 85%;
  margin: auto;

  .formGroupTextArea{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .textAreaGeneral{
      height: 100px;
    }

  }

}

.formGroupVertical{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 7px;
}

p.labelInfoContent {
  margin-top: 0px;
}



.scrollSecundario{


  border-left: 5px solid transparent;


  &::-webkit-scrollbar{
    width: 13px;
    margin: 100px 10px;
    border-left: 5px solid transparent;
  }
  &::-webkit-scrollbar-thumb{
      border-radius: 20px;
      background-color: rgb(185, 185, 185);
      // display: none;
      // color: red;
  }

  &::-webkit-scrollbar-track{
      background-color: $White-dark-soft;
      border-radius: 30px;
      height: 50vh;
    border-left: 5px solid transparent;

      // padding: 5px;
    }
}

.contenedorMultiplesBotones{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
}

.botonTablaGeneral{
  background-color: rgba(204, 31, 31, 0) !important ;
  // border-radius: 10px;
  height: 30px;
  width: 65px;
  font-size: 0.9rem;
  margin: 0 3px;
  color: $Blue-dark-soft !important;
  border: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;

  &:hover{
    transform: scale(1.05) ;
  }


}

.botonEditarTabla{

  &:hover{
    color: $Yellow !important;
  }
}

.botonEliminarTabla{

  &:hover{
    color: $Red !important ;
  }
}

.botonGuardarTabla{

  &:hover{
    color: $Blue-dark !important ;
  }
}

.rowTabla{

  height: 35px;


  &:hover{
    .botonesTabla{
      display: flex;
      justify-content: space-around;
    }
  }
}

.botonesTabla{
  display: none;
  background-color: white;
  border-radius: 10px;
  border: none;
  padding: 0 2%;
  transition: 0.4s;
  // width: 200px;


}

.tdBotones{
  width: 180px;
}

.button-group-informe {
  width: 150px;
  color: #213265;
  font-family: Lato, sans-serif;
}

.td-auditorias {
  // white-space: nowrap;
  // text-overflow: ellipsis;
  overflow: hidden;
  max-width: 170px;
  text-align: justify;
}

.pagination-table {
  .page-link{
    border: none;
  }
}

.buttonDescargaPDF{
  // background-color: green;
  width: auto;
  display: flex;
  justify-content: flex-end;
  grid-column-start: 3;
  grid-column-end: 3;
  align-self: flex-end;
}

.widgetOp{
        min-width: 200px;
        margin: 8px 12px;
        border-radius: 15px;
        cursor: pointer;
        font-size: 1rem;
        // border-bottom: 8px solid #213265;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        text-align: start;
        transition: 0.3s;
        padding: 8px;
        // font-size: 1.2rem;
        font-weight: 700;
        color: $Blue-dark;
        word-break: break-word;

        p{
            margin: 0;
        }

        .widgetPorcentaje{
            grid-column-start: 3;
            // font-weight: 800;
            font-size: 1rem;
        }

        &:hover{
            transform: scale(1.05);

            .buttonWidget{
              display: flex;

            }
        }
}

.buttonWidget{
  background-color: rgb(215, 215, 215);
  border-radius: 50%;
  padding: 2px;
  display: none;
  transition: 0.2s;
  display: none;
  width: auto;

  &:hover{
    background-color: rgb(41, 171, 60);
  }
}

.enlaces_container{
  display: flex;
  flex-direction: column;

  width: 100%;


  .enlaces_item{
    width: 99%;
    padding: 5px;
    margin: 0 auto 10px;
    border-radius: 5px;

    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.1s;

    &:hover{
      transform: scale(1.01);
      -webkit-box-shadow: 0px 2px 10px -5px rgba(0,0,0,0.75);
      -moz-box-shadow: 0px 2px 10px -5px rgba(0,0,0,0.75);
      box-shadow: 0px 2px 10px -5px rgba(0,0,0,0.75);
      color: $Blue-dark;
    }
  }



}

.circular_btn{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  transition: 0.16s;
  // border: 1px solid whitesmoke;

  &:hover{
    background-color: rgb(237, 237, 237);
  }
}
.stepper_inicial{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;

  .step_separator {
    width: 100%;
    height: 1px;
    background-color: $White-dark-soft;
    margin: 0 1rem;
    max-width: 500px;
    //render a circle in the start and end of the separator whit after and before
  }
  .step_success{
    border: 2px solid $White-dark-soft;
    border-radius: 10px;
    padding: 1rem;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .step_success_title{
      font-size: 1.2rem;
      font-weight: 700;
      color: $Blue-dark;
      margin-bottom: 8px;
    }

    .step_success_icon{
      height: 32px;
      width: 32px;
      margin: 0 auto;
      margin-bottom: 8px;
      background-color: $Green;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 24px;
    }

    .step_undefined_text{
      color: $Gray-fonts;
    }

    .step_undefined_icon{
      height: 32px;
      width: 32px;
      margin: 0 auto;
      margin-bottom: 8px;
      background-color: $White-dark;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 24px;
      color: $Gray-fonts
    }
  }
}

.confirm__button {
  background-color: $Blue-dark;
  border: none !important;
  color: white !important;
  transition: 0.1s;
  font-size: 10px;

  &:hover{
    background-color: $Green !important;
  }
}

