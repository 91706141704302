.card-header {
    padding-bottom: 4px;
  }
  
  .card-title {
    font-size: 28px;
  }

  .card-tag {
    font-size: 16px;
    color: var(--gray-500);
  }
  
  .card-content {
    .text-muted {
      font-size: 14px;
      color: var(--gray-500);
    }
  }
  
  .card-footer {
    .progress-bar-container {
      width: 100%;
    }
  }