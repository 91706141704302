@import '../../root.scss';

.linkDetalle{
  color: $Blue-dark-soft;

  transition: 0.15s;
  
  &:hover{
    color: $Blue-dark;
    text-decoration: underline;
  }
}