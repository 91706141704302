.tooltip-inner {
  border-radius: 5px;
  padding: 4px 8px;
  border: none;
  color: white;
  height: auto;
  width: auto;
  font-size: 14px;
  background-color: var(--gray-900);
}

.help__icon {
  color: var(--blue-500);
  cursor: pointer;
  font-size: 18px !important;
  margin-left: 4px;
}



