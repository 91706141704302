

.cardContent {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  padding-bottom: 0.5rem;
}

.chartContainer {
  height: 140px;
  width: 100%;
}

.cardFooter {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #e5e7eb;
  padding: 1rem;
}

.footerItem {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 0.5rem;
}

.footerItemGrid {
  display: grid;
  flex: 1;
  grid-auto-rows: min-content;
  gap: 0.125rem;
}

.footerItemText {
  font-size: 14px;
  color: var(--gray-500);
}

.footerItemValue {
  display: flex;
  align-items: baseline;
  gap: 0.25rem;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: var(--gray-700);
}

.footerItemUnit {
  font-size: 14px;
  font-weight: normal;
  color: var(--gray-500);
}

.verticalSeparator {
  margin: 0 0.5rem;
  height: 2.5rem;
  width: 1px;
  background-color: #e5e7eb;
}