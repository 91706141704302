@import '../../root.scss';

.modalNewsContainer{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $BackgroundModales;
  backdrop-filter: blur(4px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;

  .modalNewsCard{
    -webkit-box-shadow: 0px 2px 23px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 2px 23px -6px rgba(0,0,0,0.75);
    box-shadow: 0px 2px 23px -6px rgba(0,0,0,0.75);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    overflow: hidden;
    align-items: center;
    position: relative;
    text-align: center;
    // padding: 6%;
    // top: 10rem;
    background-color: #f8f8f8e7;
    max-width: 800px;
    height: auto;
    border-radius: 10px;

    .closeButtonNewsContainer{
      background-color: rgba(237, 237, 237, 0.393);
      color: white;
      display: flex;
      position: absolute;
      top: 5px;
      justify-content: flex-end;
      right: 6px;
      cursor: pointer;
      border-radius: 50%;
      padding: 1px;
      z-index: 4;
      transition: 0.3s;
      font-weight: 700;
      font-size: 5px;

      &:hover{
        background-color: rgba(223, 223, 223, 0.886);
        color: gray;
      }
    }

    button{
      background-color: $Blue-dark;
      color: $White;
      height: 35px;
      padding-left:  20px;
      padding-right:  20px;
      border-radius: 15px;
      margin: 0 10px;

      &:hover{
        border: 2px solid $Blue-dark;
        background-color: $Blue-dark-soft;
        color: $Blue-dark;
        font-weight: 700;
      }
    }
  }
}

.modalContainer{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $BackgroundModales;
  backdrop-filter: blur(4px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1051;

  .modalCard{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    text-align: center;
    // padding: 6%;
    // top: 10rem;
    background-color: #f8f8f8e7;
    padding: 1.2rem;
    width: 400px;
    height: 500px;
    border-radius: 30px;

    .buttonSuccess{

    }

    .closeButtonContainer{
      display: flex;
      position: absolute;
      top: 20px;
      justify-content: flex-end;
      right: 20px;
      cursor: pointer;
      border-radius: 50%;
      padding: 1px;
      transition: 0.2s;

      &:hover{
        background-color: rgb(199, 199, 199);
        color: $White;
      }
    }

    .modern__close {
      color: $Blue-dark;
      top: 16px;
      right: 16px;

      &:hover{
        background-color: $Red;
        color: $White;
      }
    }

    button{
      background-color: $Blue-dark;
      color: $White;
      height: 35px;
      padding-left:  20px;
      padding-right:  20px;
      border-radius: 15px;
      margin: 0 10px;

      &:hover{
        border: 2px solid $Blue-dark;
        background-color: $Blue-dark-soft;
        color: $Blue-dark;
        font-weight: 700;
      }
    }

    .deniedButton{
      background-color: $Red;
      color: $White;

      &:hover{
        background-color: $Blue;
        color: white;
      }
    }
  }
  .buttonModalContainer{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .deniedButton{
      background-color: $Red;
      color: $White;
    }

    .cancelButton{
      background-color: $Gray-dark;
      color: $White;
    }
  }

}

.nuevoItemDofa{
  width: 85%;
}

.botonVolver{
  &:hover{
    background-color: orange !important;
  }
}

div#NotificationModal {
  z-index: 99999;
  position: relative;
}
