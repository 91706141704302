@import '/src/root.scss';

.audit-form-textarea-riesgo {
  // width: 60px !important ;
  margin: 0 auto;
  background-color: rgba(101, 101, 101, 0.105);
}

.audit-form-riesgo {
  width: 60px !important ;
  margin: 0 auto;
  background-color: rgba(101, 101, 101, 0.105);
}