@import '../../styles/root.scss';

.page_azul{
  background: url("https://www.ciacosinte.site/cia2/static/media/pdfs/fondo_azul.png");
  background-repeat: no-repeat;
  background-position: start;
  background-size: cover;
  height: 400px;
  width: 100%;
}

.paginaEjemplo{
  // background: url("https://www.ciacosinte.site/cia2/static/media/pdfs/fondo_azul.png");
  background-color: red;
  background-repeat: no-repeat;
  background-position: start;
  background-size: cover;
  height: 400px;
  width: 100%;
}
