.breadcrumb__item--active a {
    cursor: default !important;

    span {
        color: var(--blue-500) !important;
        font-weight: bold;
    }
}

.breadcrumb__item a {
    color: var(--gray-700);
    font-weight: bold;

}