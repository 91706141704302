.datatable__dinamica {
  /* Cambiar el tamaño de fuente de la tabla */
  font-size: 14px;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-radius: 10px;

  table {
    border-radius: 0 0 10px 10px;
  }

  th,
  td {
    /* Cambiar el tamaño de fuente de los encabezados */
    font-size: 14px;
  }

  /* Cambiar el color de fondo de la fila onHover */

  tbody tr:hover {
    background-color: #f8f9fa;
  }

  /* Agregar border radius a la última fila */

  tbody tr:last-child {
    td {
      border-bottom: none;
    }
  }

  .p-datatable-header {
    border-radius: 10px 10px 0px 0px;
  }

  .p-frozen-column {
    background-color: transparent;
  }
}

.p-button-sm {
  font-size: 0.875em !important;
  padding: 0.65625em 1.09375em !important;
}

.p-inputtext-sm {
  font-size: 0.875em !important;
  padding: 0.65625em 0.65625em !important;
}
.p-input-icon-left > .p-inputtext {
  padding-left: 2.5em !important;
}

.p-inputtext,
.p-component {
  font-size: 1em;
}

.p-0 {
  padding: 0 !important;
}

.mw-300 {
  max-width: 300px;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.375em 0.75em !important;
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.375em 0.75em;
}

.p-multiselect .p-multiselect-label {
  padding: 0.75em 0.75em;
}

.p-tooltip .p-tooltip-text {
  padding: 0.75em 0.75em;
}

.p-inline-message {
  padding: 0.5em 0.75em ;
  font-size: 12px;
  cursor: pointer;
  .p-inline-message-text{
    font-size: 14px;
  }
}

.button__link {
  padding: 4px 4px 4px 0px ;
  span{
    color: #213265;
    text-decoration: underline;
  }
  svg{
    color:#E4A731;
  }
}
