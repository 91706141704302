@import '../../root.scss';

.imagenInformes{
  width: 48%;
  height: auto;
}






#select{
    border: 0px solid $Blue-dark;
    // border: none;
    background-color: $White-dark;
    border-radius: 8px;
}

// .contenedorSeguimientoSolicitudes{
//   display: flex;

//   align-items: center;
//   justify-content: center;
// }

// #cardSeguimientoSolicitudes{
//   -webkit-box-shadow: 1px 1px 17px -5px rgba(0,0,0,0.75);
//     -moz-box-shadow: 1px 1px 17px -5px rgba(0,0,0,0.75);
//     box-shadow: 1px 1px 10px -4px rgba(0,0,0,0.75);      display: flex;
//     flex-direction: column;
//     align-items: space-between;
//     border-radius: 15px;
//     max-width: 270px;
//     // max-width: 290px;
//     padding: 0;
//     height: 120px;
//     margin: 3% 25px 2%;
//     transition: 0.1s;
//     border-bottom: 10px solid $Blue-dark;
//   font-size: 1.2rem;
//   font-weight: 700;
//   color: $Blue-dark;
//   text-align: center;
//   margin-top: 20px;

//   &:hover{
//     background-color: $Green;

//     .checboxGroupTitle{
//       color: $White;

//     }
//     }

//   .checboxGroupTitle{
//     font-size: 1.2rem;
//     font-weight: 700;
//     color: $Blue-dark;
//     text-align: center;
//     margin-top: 20px;

//   }

//   .formSelectFiltroInforme{
//     padding: 15px 0 0 0 ;

//       .selectFiltroInforme{
//         border: 0;
//         border-radius: 15px;
//         padding-right: 10px;
//         width: 90%;
//         margin: 0 auto;
//         background-color: $White-dark-soft;

//       }
//     }

//     &:hover{
//       border-bottom-left-radius: 0;
//       border-bottom-right-radius: 0;
//     }

//     &:hover .botonDescargaReportes{
//       display: block;
//     }


// }

.contenedorTarjetas::-webkit-scrollbar{
  border-radius: 20px;
  background-color: $White-dark-soft;
}
.contenedorTarjetas::-webkit-scrollbar-thumb{
  border-radius: 20px;
  background-color: $Gray-fonts;
}

.contenedorTarjetas{
  font-size: 0.7rem;
  display: flex;
  // justify-content: center;
  align-items: space-around;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin: 10px 15px;
  padding: 0 15px;
  width: auto;
  height: auto;

  // background-color: red;
}

  .cardBody{
    -webkit-box-shadow: 1px 1px 17px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 1px 17px -5px rgba(0,0,0,0.75);
    box-shadow: 1px 1px 10px -4px rgba(0,0,0,0.75);      display: flex;
    flex-direction: column;
    align-items: space-between;
    border-radius: 15px;
    min-width: 270px;
    padding: 0;
    height: 90px;
    margin: 3% 25px 2%;
    transition: 0.1s;
    border-bottom: 10px solid $Blue-dark;

    &:hover{
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:hover .botonDescargaReportes{
      display: block;
    }

    // #cardSeguimientoSolicitudes{
    //   font-size: 1.2rem;
    //   font-weight: 700;
    //   color: $Blue-dark;
    //   text-align: center;
    //   margin-top: 20px;
    // }


    .formSelectFiltroInforme{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      padding: 0;
      margin: 0 auto;
    // padding: 15px 0 0 0 ;

      .selectFiltroInforme{
        border: 0;
        border-radius: 15px;
        padding-right: 10px;
        width: 90%;
        margin: 0 auto;
        background-color: $White-dark-soft;

      }
    }


    .checboxGroupTitle{
      font-size: 1rem;
      font-weight: 600;
      color: $Blue-dark-soft;
      text-align: center;
      margin-top: 20px;
    }

    &:hover{
      transform: scale(1.05);

      &#cardSeguimientoSolicitudes{
        background-color: #2c5e99;
        }

      &#cardEstadoSalud, &#cardInvestigacion{
        background-color: #05a4db;
        }

      &#cardPerfilSocio, &#cardBeneficiario{
        background-color: #009688;
        }

      &#cardEscolaridad, &#cardNovedad{
        background-color: #8BC34A;
        }

      &#cardAntecedentes, &#cardFacturacion{
        background-color: #F9CF00;
        }
      &#cardBienestar, &#cardObservaciones {
        background-color: #F46036;
        }
      &#cardInfoFinanciera, &#cardSeguimiento{
        background-color: #D7263D;
        }
      &#cardNivelRiesgo, &#cardMediosProbatorios{
        background-color: #2c5e99;
        }
      &#cardInformeDetallado, &#cardSolicitudProrroga{
        background-color: #05a4db;
        }
      &#cardPrincipalesClientes, &#cardErroresRadicacion{
        background-color: #009688;
        }
      &#cardRutinaMantenimiento, &#cardDesbloqueoInvestigacion{
        background-color: #8BC34A;
        }

      .checboxGroupTitle{
        color: $Blue-dark;
      }

    }

    label{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 14px;
    }
  }


.buttonContainer{
  display: flex;
  justify-content: flex-end;
}


.cardCheckBox{

  font-size: 0.7rem;
  padding: 3%;

  .textInformes2{
    font-size: 1.2rem;
    color: $Blue-dark;
  }

  #selectTipoSolicitud{
    width: 160px;
    color: $Blue-dark;
    font-size: 1.2rem;
    font-weight: bolder;
  }



  .selectsFiltroInformesContainer{
    display: flex;
    flex-direction: column;
    width: 800px;
    height: 50px;

    .labelSelectFiltroInforme{
      width: 90px;
      color: $Blue-dark;
      font-size: 1.2rem;
      font-weight: bolder;
      text-align: center;
    }

    .selectsFechas{
      // background-color: green;
      display: flex;
      align-items: center;
      justify-content: stretch;
      width: 100%;

      .form-group{
        width: 60%;
      }
    }

  }
  input.formControlFiltrolGeneral{
    border-radius: 20px;
    border: 2px solid $Blue-dark;
    height: 35px;
    // background-color: red;
  }


}


.botonDescargaReportes{
  display: none;
  background-color: $Blue-dark;
  color: white;
  height: 40px;
  cursor: pointer;
  transition: 0.2s;
  width: 100%;
  margin-top: 20px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  -webkit-box-shadow: 1px 1px 17px -5px rgba(0,0,0,0.75);
  -moz-box-shadow: 1px 1px 17px -5px rgba(0,0,0,0.75);
  box-shadow: 1px 1px 10px -4px rgba(0,0,0,0.75);
  font-size: 18px;
  font-weight: 600;

}

.headerInformes{
  background-image: url('https://res.cloudinary.com/da6bzgewk/image/upload/v1620649909/background_reportes_jjqquq.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  // background-color: green;
  display: flex;
  justify-content: space-between;
  min-height: 40vh;
  margin: 0;
  border-radius: 15px;
  overflow: hidden;

  .contenedorInfoReportes{
    background-color: rgba(15, 45, 65, 0.579);
    width: 100%;
    // height: 100%;
    padding: 3% 2% ;


    .tituloInfoReportes{
      color: $White;
      font-weight: 900;
      font-size: 3rem;
      margin-top: -40px;
      margin-bottom: 30px;
    }

    .contenedorInstruccionesReportes{
      background-color: rgba(255, 255, 255, 0.918);
      border-radius: 15px;
      padding: 5px 15px ;
      width: 400px;
      max-width: 50%;
      font-size: 1.2rem;

      .textInformes{
        font-size: 1.2rem;
        color: rgb(109, 109, 109);

      }

      .tituloPasosGenerar{
        color: $Blue-dark;
        font-size: 1.2rem;
      }
    }
  }

}

.contenedorCardSelects{
  display: flex;
  justify-content: flex-end;
  height: auto;
  background-color: rgba(0, 128, 0, 0);
  padding-right: 30px;
  margin-top: -200px;

  .cardSelectsSeguimientoSolicitudes{
    background-color: $White;
    padding: 10px;
    border-radius: 10px;
    height: 240px;
    width: 45%;



    .cardBodySelectsSeguimientoSolicitudes{
      height: 100%;
      padding: 0;
      display: grid;
      align-content: center;
      grid-template: 1fr 1fr 1fr / 1fr 1fr;

      #groupSelecFechade{
        grid-column-start: 1;
      }

      #groupSelecFromFecha{
        grid-column-start: 1;
        grid-row-start: 2;
      }

      #groupSelecToFecha{
        grid-column-start: 2;
        grid-row-start: 2;
      }

      #groupSelectTipoSolicitud{
        grid-column-start: 2;
        grid-row-start: 1;
      }


      .fromGroupSelect{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 90%;

        .labelSelectFiltroInforme{
          font-size: 1.2rem;
          font-weight: 900;
          color: $Blue-dark;
        }

        .formControlFiltroGeneral{
          background-color: $White-dark;
          border-radius: 7px;
          border: none;
          margin-left: 10px;
        }

        .date__range__picker{
          width: 100%;
          height: 32px;
          box-shadow: 1px 2px 5px -1px rgba(0, 0, 0, 0.3);
          padding: 10px;
          font-size: 14px;
        }
      }
    }
  }

}

@media only screen and (max-width: 976px) {

  .headerInformes{
    flex-direction: column;
    justify-content: center;

    .contenedorInfoReportes{
      .tituloInfoReportes{
        font-size: 3rem;
      }

      .contenedorInstruccionesReportes{
        min-width: 100%;
        height: 100%;
      }
    }






  }

  .contenedorCardSelects{
    justify-content: center;
    margin-top: -10px;
    // width: 100%;
    margin: 0 auto;
    padding: 0;


    .cardSelectsSeguimientoSolicitudes{
    height: auto;
    width: 100%;
      .cardBodySelectsSeguimientoSolicitudes{
        grid-template: 1fr 1fr 1fr 1fr / 1fr;
        height: auto;

        #groupSelecFechade{
          grid-row-start: 1;
          grid-column-start: 1;
        }

        #groupSelecFromFecha{
          grid-column-start: 1;
          grid-row-start: 2;
        }

        #groupSelecToFecha{
          grid-column-start: 1;
          grid-row-start: 3;
        }

        #groupSelectTipoSolicitud{
          grid-column-start: 1;
          grid-row-start: 4;
        }

      }
    }


  }

  main{

    height: auto;

    .card-body{
      margin: 7% 25px;
    }
  }

  .selectsFechas{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .selectsFechas{
    // background-color: green;
    display: flex;
    justify-content: flex-start;
    width: 100%;

    .form-group{
      width: 100%;
    }
  }

}
