.form-control {
  padding: 4px 8px;
  height: 200px;
}

.form-control, .css-yk16xz-control{
  width: 100%;
  margin-bottom: 8px;
  margin-top: 0;
  border-radius: 5px;
  border: 1px solid var(--gray-300);
  outline: none;
  font-size: 14px;
  color: var(--gray-800);
  height: 40px;
}

.css-yk16xz-control:hover {
  border: 1px solid var(--gray-200);
}

.form-control:focus, .css-1pahdxg-control{
  border: 1px solid var(--blue-200);
  box-shadow: 0 0 0 0.2rem #dfebfe;
  height: 40px;
}

.form-control:disabled, .css-1fhf3k1-control {
  background-color: var(--gray-50);
  border: 1px solid var(--gray-200);
  color: var(--gray-600);
  cursor: not-allowed;
}


.form-label{
  font-weight: 700;
  color: var(--gray-700) !important;
  margin-bottom: 4px;

  ~ div.card {
    background-color: transparent;
  }

}
