.wrapper__grafica {
    height: auto;

    .shadow {
        box-shadow: none !important;
    }


    .cardGraficaGeneral  {
        border-radius: .75rem !important;
        
        /** if id containes cui__chart__ */
        [id^='cui__chart__'] {  min-height: auto !important;}
          
    }


}