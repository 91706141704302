@import '/src/root.scss';

#cancelarEdicionTituloBoton{
  background-color: white;
  margin-left: 20px;
  height: 30px;
  border-radius: 10px;
  padding: 0 5px;

  &:hover{
    background-color: $White-dark;
    transition: 0.2s;
  }
}


#inputNewTitlePlanEstrategico{
  outline: none;
  border: 0;
  width: 300px;
  font-weight: 800;
  color: $Blue-dark;
  font-size: 1rem;
}

#titleEditButton{
  padding: 2px;
  color: white;
  background-color: $Gray-fonts;
  &:hover{
    background-color: $Blue-dark;
  }
}

#titleSaveButton{
  background-color: $Gray-fonts;
  padding: 2px;
  color: white;
  &:hover{
    background-color: $Green;
  }
}

.toggleObj{
  -webkit-box-shadow: 0px 3px 10px -2px rgba(199,199,199,1);
  -moz-box-shadow: 0px 3px 10px -2px rgba(199,199,199,1);
  box-shadow: 0px 3px 10px -2px rgba(199,199,199,1);
}
.accordion1{
  margin: 0 0 7px 0;
  border: 1px solid rgb(243, 243, 243);
  border-radius: 5px;
  padding: 8px;
  background-color: white;
  color: $Blue-dark;
}

.options_cont{
  min-height: 35px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.toggleObj, .accordionToggleMetas{
  height: auto;

  // min-height: 35px;

  &:focus {
    border-bottom: 7px solid $Blue-dark; // Aumenta el grosor del borde
    border-bottom-left-radius: 18px; // Redondea la esquina inferior izquierda
    border-bottom-right-radius: 18px; // Redondea la esquina inferior derecha
    font-weight: bold; // Cambia el texto a negrita
  }
  &:hover{

    .contenedorAccionesObj{
      display: flex !important;
      width: auto;
    }

  }
}

.accordionToggleMetas{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  height: auto;
  width: 100%;
  border-radius: 8px;
  background-color: rgb(247, 247, 247);

}

.tareaItem{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3%;
  min-height: 35px;
  height: auto;
  border-bottom: 1px solid rgb(197, 197, 197);
  cursor: pointer;

  &:hover{
    background-color: $Blue-soft;
  }
  p{
    margin: 0;
  }
}

.contenedorAccionesObj{
  display: none !important;
  // background-color: green;
  width: 120px;
  margin-right: 20px;
  display: flex;
  justify-content: space-around;
  color: white;

  .detailButtonPE{
    background-color: gray;
  color: white;

  }

  .editButtonPE{
    background-color: $Yellow;
    color: white;
  }

  .deleteButtonPE{
    background-color: rgba(196, 63, 63, 0.864);
    color: white;
  }

  .accionObj{
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

#buttonAddObj{
  background-color: $Blue-dark;
  height: 35px;
  border-radius: 10px;
  width: 100%;
  color: white;
  font-weight: 800;

  &:hover{
    background-color: $Green;
    transition: 0.25s;
  }
}

#buttonAddMeta{
  background-color: $White-dark ;
  height: 35px;
  border-radius: 10px;
  width: 100%;
  font-weight: 800;

  &:hover{
    color: white;
    background-color: $Green;
    transition: 0.25s;
  }

}

#contenedorBotonAddTarea{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#buttonAddTarea{
  background-color: $White-dark-soft ;
  height: 35px;
  border-radius: 10px;
  width: 95%;
  margin: 5px auto;
  font-weight: 800;

  &:hover{
    color: white;
    background-color: $Green;
    transition: 0.20s;
  }

}

.numeracionLabel{
  min-height: 30px;
  min-width: 60px;
  border-radius: 10px;
  background-color: rgba(0, 64, 255, 0.2);
  color: $Blue-dark;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  margin-right: 1rem;
  font-size: 12px;
}
