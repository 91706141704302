@import '/src/root.scss';

.btn-stepper{
  background-color: rgba(204, 31, 31, 0) !important ;

  width: auto;
  cursor: pointer;
  transition: 0.1s;
  border-radius: 7px;
  padding: 3px;
  height: 30px;
  margin: 0 3px;
  border: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $Blue-dark-soft !important;


  &:hover{
    color: $Blue-dark !important
  }

  &:active{
    background-color: rgb(169, 182, 198) !important;
  }
}

.itemAnalista{
  background-color: rgb(237, 237, 237);
  margin: 6px auto;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.15s;
  padding: 0 10px;
  border-radius: 7px;
  font-weight: 600;
  color: rgb(61, 61, 61);
  cursor: pointer;


  &:hover{
    transform: scale(1.01);
    background-color: #F9CF00;
    // color: white;
    // font-weight: 800;
    font-size: 0.95rem;
  }

  .valoresAnalista{
    display: flex;
    align-items: center;
    justify-content: right;
    // background-color: yellow;
    height: 100%;
    width: 185px;
    padding: 0 10px;
    margin: 0 6% 0 0;
  }
}

.itemAnalistaHeader{
  background-color: rgb(254, 254, 254);
  border: 1px solid rgb(216, 216, 216);
  margin: 6px auto;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.15s;
  padding: 0 10px;
  border-radius: 7px;
  // font-weight: 600;
  color: rgb(61, 61, 61);
  font-weight: 800;

  &:hover{
    // transform: scale(1.01);
    // background-color: #F9CF00;
    // color: white;
    // font-weight: 800;
    font-size: 0.95rem;
  }

  .valoresAnalista{
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: yellow;
    height: 100%;
    width: 200px;
    padding: 0 10px;
  }
}

// .contenedorValores{
//   background-color: red;
// }

.lista_container{
  background-color: white;
  width: 100%;
  min-height: 100px;
  border-radius: 10px;
  padding: 5px;

  .lista_item{
    background-color: rgb(234, 234, 234);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    // gap: 5px;
    max-height: 35vh;

    width: 97%;
    margin: 10px auto 15px ;
    border-radius: 10px;
    overflow-y: auto;
    overflow-x: hidden;

  }

  .lista_item-ele{

    text-align: justify;
    transition: 0.15s;



    .lista_item-ele-header{
      background-color: $Blue-dark;
      padding: 0 5px;
      color: white;
      font-weight: 600;
      min-height: 25px;
      display: flex;
      align-items: center;
      font-size: 12px;


    }

    .lista_item-ele-content{
      min-height: 40px;
      padding: 0 5px;
      font-size: 12px;
    }
  }

}
