@import '/src/root.scss';

.itemAreaAsignacion{
  border: 2px solid $White-dark;
  color: $Blue-dark;
  margin: 30px auto;
  padding: 15px;
  border-radius: 8px;
  font-weight: bold;
}

#nombreArea{
  border: 2px solid $Blue-dark;
  border-radius: 15px;
  position: relative;
  height: 30px;
  margin-top: -30px;
  background-color: $Blue-dark;
  color: white;
  padding: 0  ;
  // max-width: 200px;
  // margin: -25px 0 0;
  min-width: 130px;
  display: flex;
  max-width: 40%;
  width: auto;
  // align-items: center;
  justify-content: center;
}

.buttonContainerArea{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 50px;
  padding: 15px 0 ;
  // margin: 10px 0;
}