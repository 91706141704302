
@import '/src/root.scss';

textarea.audit-form-textarea-obj.dis.form-control {
    background: transparent;
    border: none;
    max-height: 10px
}

.form-other-factor.form-group {
    display: block;
}

.botonesTablaPanorama{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

#contenedorFormNoticiaPanorama{
    height: 90%;
    width: 90%;
    // background-color: red;
}

#contenedorInputsNoticiaPanorama{
    // background-color: yellow;
    max-height: 80%;
    overflow-y: auto;
    overflow-x: hidden;
    // overflow: hidden;
}