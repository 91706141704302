@import '../../../../root.scss';

#botonAgregarAnalista{
  background-color: $Blue-dark;
  color: $White;
  height: 35px;
  padding-left:  20px;
  padding-right:  20px;
  border-radius: 15px;
  margin: 0 15px;

  &:hover{
    border: 2px solid $Blue-dark;
    background-color: $Blue-dark-soft;
    color: $Blue-dark;
    font-weight: 700;
  }
}