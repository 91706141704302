.card__box {
  //background-color: var(--gray-50);
  border: 1px solid var(--gray-200);
  //box-shadow: rgba(50, 50, 93, 0.01) 0px 50px 100px -20px, rgba(0, 0, 0, 0.05) 0px 30px 60px -30px;
  border-radius: 1rem;
}


.card__box__surface {
  border: none;
  border: 1px solid var(--gray-200);
  border-radius: 0.75rem;
  //box-shadow: 0px 4px 30px rgba(221, 233, 255, 0.54);
  //box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  box-shadow: rgba(17, 12, 46, 0.1) 0px 48px 100px 0px;
}


.card__plain__top__left {
  border-radius: 0 0.75rem 0.75rem 0.75rem;
}

.card__box__surface--compressed {
  height: 240px;
  overflow: auto;
}

.empty__card {
  border: 2px dashed var(--gray-200);
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  border-radius: .75rem;

  .icon {
    background-color: var(--gray-50);
    padding: 1rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      color: var(--gray-500);
      font-size: 2rem;
    }
  }

  span {
    color: var(--gray-500);
    font-size: 1rem;
    font-weight: 700;
  }
}


.card__popover {
  border-radius: 0.75rem;
  padding: .5rem;
  padding-bottom: 0;
  max-width: 300px;
  max-height: 120px;
  margin-bottom: 8px;
  overflow: auto;
  
  // Estilos del scrollbar para navegadores WebKit (Chrome, Safari, etc.)
  &::-webkit-scrollbar {
    width: 8px; // Ajusta el ancho del scrollbar
  }

  &::-webkit-scrollbar-track {
    background: var(--gray-200); // Color de la pista
    border-radius: 4px; // Radio de la esquina de la pista
  }

  &::-webkit-scrollbar-thumb {
    background: var(--gray-500); // Color del thumb (la barra que se desplaza)
    border-radius: 4px; // Radio de la esquina del thumb
  }

  // Estilos del scrollbar para Firefox
  scrollbar-width: thin; // "auto" o "thin"
  scrollbar-color: var(--gray-500) var(--gray-200); // color del thumb y la pista



}