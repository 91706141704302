@import '../../root.scss';

.contenedorActividadesFactores-titulo{
  text-align: center;
  font-weight: 700;
  color: $Blue-dark-soft;
  margin: 0;
  font-size: 0.6rem;
}

.contenedorInfoWindow{
  display: grid;
  grid-template: 1fr 3fr / 1fr 1r ;
  max-height: 200px;
  width: 300px;

  .imagenInfo{
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column: 1 / 3;
    display: flex; 
    justify-content: space-between;
    align-items: center;

    .titluloInfo{
      color: $Blue-dark;
      font-size: 1.4rem;
      font-weight: 800;
    }

  }

  .textInfo{
    grid-row: 2 / 3;
    grid-column: 1 / 3;
  }

}

.contenedorActividadesFactores{
  // background-color: red;

  #factores{
    // background-color: yellow;
    // padding: 2px 8px;

    .factores_item{
      // background-color: blue;
      border: 2px solid $Blue-soft;
      border-radius: 8px;
      margin:  6px 0 ;
      padding: 0;
      height: 100%;

      .factor_actividades-lista{
        display: flex;
        justify-content: space-between;
        // background-color: blue;
        width: 98%;
        margin-top: 4px;
        padding: 0 0 3px;
      }
    
    


      .factor_label{
        background-color: white;
        position: absolute;
        min-width: 10%;
        max-width: 50%;
        margin: -8px 5px 0;
        padding: 0px 8px;
        font-size: 0.6rem;
        font-weight: 600;
        color: $Blue-dark;
      }

      .actividad{
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px 3px;
        color: white;
        font-weight: 700;
        font-size: 0.5rem;

        // -webkit-box-shadow: 2px 2px 7px 0px rgba(0,0,0,0.75);
        // -moz-box-shadow: 2px 2px 7px 0px rgba(0,0,0,0.75);
        // box-shadow: 2px 2px 7px 0px rgba(0,0,0,0.75);

        p{
          margin: 0;
        }
      }
    }


  }


}

.gm-style-iw-d{
  width: 130px;

}

#cantidad_extra{
  // background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  width: 22px;
  color: gray;
  font-size: 0.7rem;
}

.gm-style .gm-style-iw-c {
  padding: 5px;
}

.gm-style .gm-style-iw-d{
  overflow: hidden;
}