.card__ui {
    border-radius: 0.75rem;
    border: 1px solid #e5e7eb;
    background-color: #ffffff;
    color: var(--gray-700);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    height: 100%;
}

.card__ui__header {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
    padding: 1.5rem;
}


.header__underline {
    border-bottom: 1px solid #e5e7eb;
}

.card__ui__title {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1;
    letter-spacing: -0.025em;
}

.card__ui__description {
    font-size: 0.875rem;
    color: var(--gray-500);
}

.card__ui__content {
    padding: 1.5rem;
    padding-top: 0;
}

.card__ui__footer {
    display: flex;
    align-items: center;
    padding: 1.5rem;
    padding-top: 0;
}


/*Borrar*/

.w-200 {
    width: 200px;
}