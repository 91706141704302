@import '../../root.scss';

.handlerstatus_cont{
  width: 90%;
  margin: 10px auto;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  min-height: 40px;
  // justify-content: space-around;

  .handlerstatus_btn{
    cursor: pointer;
    position: relative;
    // display: inline-block;
    border-radius: 7px;
    cursor: pointer;
    // transition: 0.14s;
    font-size: 0.7rem;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: width 0.14s;
    font-weight: 700;
  }


  .handlerstatus_btn:before,
  .handlerstatus_btn:after{
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    padding: 3px 7px 3px 8px;

  }

  #status_prev{
    grid-column-start: 1;
    text-decoration: none;
    padding: 3px 7px;
  }



  #status_prev::before{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(208, 208, 208);

    // background-color: rgba(208, 208, 208, 0);

  	content: attr(data-status);
    -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 50%, 100% 100%, 50% 100%);
    clip-path: polygon(0% 0%, 100% 0,  100% 100%, 0% 100%, 0% 50%);
    transition: clip-path 0.1s cubic-bezier(0.8, 1, 0.8, 1)
  }

  #status_prev:hover::before{
    background-color: rgb(173, 173, 173);

	-webkit-clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
	clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 20% 100%, 0% 50%);
  }

  #status_act{
    grid-column-start: 3;
    text-decoration: none;
    transition: transform 0.1s cubic-bezier(0.2, 1, 0.8, 1);

  }

  #status_prev:hover::after{
    background-color: rgb(98, 96, 96);

  }

  #status_act::before{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(208, 208, 208);

    // background-color: rgba(208, 208, 208, 0);

  	content: attr(data-status);
    -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 50%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 100% 0, 100% 50%, 100% 100%, 0% 100%);
    transition: clip-path 0.1s cubic-bezier(0.2, 1, 0.8, 1), -webkit-clip-path 0.4s cubic-bezier(0.2, 1, 0.8, 1);

  }

  #status_act:hover::before{
    background-color: rgb(173, 173, 173);

    -webkit-clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 80% 0%, 100% 50%, 80% 100%, 0% 100%);
  }
}

.evidenciaItem{
  display: flex;
  flex-direction: column;
}

.labelEditForm{
  text-align: justify;
}

.textContent{
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none !important;

  white-space: pre-line;
}

.autMensaje-cont{
  background-color: rgba(255, 166, 0, 0.715);
  // min-width: 75%;
  width: auto ;
  max-width: 180%;
  padding: 3px 6px;
  text-align: justify;
  border-radius: 7px;
  margin-top: 5px;
  position: absolute;
  top:   101%;
  // right: -45%;
}


#probabilidad_num{
  display: flex;
  justify-content: center;
}

.riesgo_modal_select{
  padding: 0 10px;
}

.tituloGestorDinamico{
  // background-color: rgb(199, 193, 193);
  width: 90%;
  height: 100px;
  font-size: 1.5rem;
  margin-top: 20px;
  font-weight: 700;
  color: $Blue-dark-soft;
}



.contenidoGestorDinamico{
  background-color: white;
  height: 70vh;
  width: 90%;
  // overflow-y: auto;
  border-radius: 15px;
  padding: 10px;
  color: gray;


}

.autorizacion-cont{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.contendorInfoDetalle{
  width: 100%;
  height: 90%;
  // background-color: red;

  .headerSeccionDetalle{
    // background-color: $Yellow;
    border-radius: 10px;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 10px auto 0;
    // height: auto;

    .tituloSeccion{
      font-size: 0.8rem;
      font-weight: 500;
      // color: white;
      max-height: 20vh;
      text-align: justify;
      padding: 6px;
      overflow-y: auto;
    }
  }

  .contenedorAside{
    width: 90%;
    padding: 2%;
    height: 70%;
    margin: 0 auto;
    // background-color: green;
    border-radius: 15px;
    display: grid;
    grid-template: 1fr / 1fr 2fr;
    row-gap: 2px solid blue;

    & aside{
      // background-color: blue;
      border-right: 2px solid rgb(213, 213, 213);
      padding-right: 5px;

      .btnSeccionDetalle{
        width: 100%;
        margin: 0 0 5px;
        border-radius: 8px;
        border: none !important;
        transition: 0.15s;
        color: $Blue-dark;
        font-weight: 600;


        &:hover{
          background-color: rgba(255, 255, 255, 0.503) !important ;
        }


      }
    }

    & main {
      border-radius: 10px;
      // background-color: yellow;
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow-y: auto;

      .detalleItemList{
        background-color: white;
        border-radius: 8px;
        // min-height: 40px;
        height: auto;
        text-align: justify;
        padding: 5px;
        margin: 0 12px 10px;
        color: gray;
        transition: 0.2s;

        &:hover{
          transform: scale(1.02);
        }
      }
    }

  }
}

#contenedorTablaGerencia{
  // background-color: red;
  overflow-y: auto;
  overflow-x: auto;
  width: 800px;
  height: 90%;
  margin: 0 auto;
  padding-right: 10px;
  margin-top: 30px;
}

#tablaInformeGerencial{
  width: 100%;
  margin: auto;
  // margin: 30px 0 0;
  overflow-y: hidden;
}

.editMmodalContainer{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $BackgroundModales;
  backdrop-filter: blur(4px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;

  .editModalCard{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
    text-align: center;
    // padding: 6%;
    // top: 10rem;
    background-color: #f8f8f8e7;
    padding: 2rem;
    padding-bottom: 1.2rem;
    width: 50%;
    //min-height: 650px;
    max-height: 70%;
    border-radius: 30px;



    // button{
    //   background-color: $Blue-dark;
    //   color: $White;
    //   height: 35px;
    //   padding-left:  20px;
    //   padding-right:  20px;
    //   border-radius: 15px;
    //   margin: 0 15px;

    //   &:hover{
    //     border: 2px solid $Blue-dark;
    //     background-color: $Blue-dark-soft;
    //     color: $Blue-dark;
    //     font-weight: 700;
    //   }
    // }
  }

  .editModalCardTablaGerencia{
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    text-align: center;
    // padding: 6%;
    // top: 10rem;
    background-color: #f8f8f8e7;
    padding: 1.2rem;
    width: auto;
    // max-width: 70%;
    // min-height: 600px;
    height: auto;
    max-height: 50%;
    border-radius: 30px;



    button{
      background-color: $Blue-dark;
      color: $White;
      height: 35px;
      padding-left:  20px;
      padding-right:  20px;
      border-radius: 15px;
      margin: 0 15px;

      &:hover{
        border: 2px solid $Blue-dark;
        background-color: $Blue-dark-soft;
        color: $Blue-dark;
        font-weight: 700;
      }
    }
  }

  .contenedorEditorObsCriteriosAud{
    // background-color: red;
    overflow-y: auto;
    height: 400px;
  }

  .editModalCardPE{
    display: block;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    text-align: center;
    // padding: 6%;
    // top: 10rem;
    background-color: #f8f8f8e7;
    padding: 1.2rem;
    width: 50%;
    min-height: 600px;
    border-radius: 30px;




  }

  .closeButtonContainer{
    display: flex;
    position: absolute;
    top: 20px;
    justify-content: flex-end;
    right: 20px;
    cursor: pointer;
    border-radius: 50%;
    transition: 0.15s;
    // width: auto;
    // height: 20px;

    &:hover{
      background-color: rgb(172, 172, 172);
      color: $White;
    }
  }

  .buttonModalContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .deniedButton{
      background-color: $Red;
      color: $White;
    }

    .cancelButton{
      background-color: $Gray-dark;
      color: $White;
    }
  }

  .formGroupAddActions{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  @media only screen and(max-width: 768px){
    .modalCard{
      width: 90%;
    }
  }

  .select-button {
    text-align: center;
    margin-bottom: 3em;
    display: flex !important ;
    justify-content: center;
    width: 100%
  }


};

.formGroupModal{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 10px;

}



.areaDiagnosticoModal{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $BackgroundModales;
  backdrop-filter: blur(4px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;

  .cardAreaDiagnostico{
    display: flex;
    flex-direction: column;
    position: relative;
    // padding: 6%;
    // top: 10rem;
    // background-color: #f8f8f8e7;
    width: 50%;
    border-radius: 30px;
    height: 60%;

    .closeButtonContainer{
      display: flex;
      width: 100%;
      justify-content: flex-end;
    transition: 0.15s;

    }

    .closeButtonContainer{
      display: flex;
      position: absolute;
      top: 15px;
      justify-content: flex-end;
      right: 15px;
      cursor: pointer;
      border-radius: 50%;
      z-index: 4;
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      background-color: transparent;
      padding: 0;
      color: gray;
      transition: 0.15s;

      // color: $White;

      &:hover{
        background-color: rgb(231, 231, 231);
      }
    }

    .closeButtonContainerDown{
      display: flex;
      position: absolute;
      // top: 87%;
      top: 440px;
      justify-content: flex-end;
      right: 50px;
      cursor: pointer;
      border-radius: 7px;
      z-index: 4;
      height: 30px;
      width: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.9rem;
      padding: 0;
      color: gray;
      background-color: rgb(181, 181, 181);

      color: $White;

      &:hover{
        background-color: $Blue-dark;
      }
    }

  }

  .buttonModalContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .deniedButton{
      background-color: $Red;
      color: $White;
    }

    .cancelButton{
      background-color: $Gray-dark;
      color: $White;
    }
  }

  @media only screen and(max-width: 768px){
    .modalCard{
      width: 90%;
    }
  }
};

.detalleNormativaContainer{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $BackgroundModales;
  backdrop-filter: blur(4px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;



  .detalleNormativaModal{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // align-items: center;
    position: relative;
    text-align: center;
    // padding: 6%;
    // top: 10rem;
    background-color: #fff;
    width: 50%;
    min-height: 400px;
    border-radius: 30px;
    overflow: hidden;

    .closeButtonContainer{
      display: flex;
      position: absolute;
      top: 15px;
      justify-content: flex-end;
      right: 15px;
      cursor: pointer;
      border-radius: 50%;
      z-index: 4;
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      background-color: transparent;
      padding: 0;
      transition: 0.15s;

      color: white;

      &:hover{
        background-color:  rgba(230, 230, 230, 0.457);
        color: $White;

      }
    }
  }

  .normatividadBaja{
    background: #bdbdbd;
    background: linear-gradient(348deg,#888888 32%, #bdbdbd 100%);
  }

  .normatividadMedia{
    background: linear-gradient(348deg, #e0ac28 32%, #f7be2e 100%);
  }

  .normatividadAlta{
    background: #08569B;
    background: linear-gradient(348deg, #08569B 32%, #2687dc 100%);
  }

  .detalleNormativaHeader{
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
      width: 100%;
      height: 87%;
      display: grid;
      padding-top: 60px;
        color: white;
        grid-template: 1fr 2fr / 1fr 1fr  ;

      .tituloModalNormativa{
        grid-column: 1/3;
        font-size: 2rem;
        color: white;
        font-weight: 900;
      }

      .porcentajeModalNormativa{
        grid-row: 2/3;
        grid-column: 2;
        font-size: 5rem;
        font-weight: 900;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .descripcionModalNormativa{
        // position: absolute;
        grid-row: 2/3;
        grid-column: 1;
        background-color: rgba(255, 255, 255, 0.211);
        border-radius: 15px;
        padding: 10px;
        margin: 10px 0px 10px 10%;
        z-index: 200;
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: justify;
        font-size: 1rem;
        // bottom: 13%;
      }
  }

}

.detalleRiesgoContainer{

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $BackgroundModales;
  backdrop-filter: blur(4px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;

  .closeButtonContainer{
    display: flex;
    position: absolute;
    top: 15px;
    justify-content: flex-end;
    right: 15px;
    cursor: pointer;
    border-radius: 50%;
    z-index: 4;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    background-color: transparent;
    padding: 0;
    transition: 0.15s;

    color: $White;

    &:hover{
      background-color: rgba(216, 216, 216, 0.367);
    }
  }



  .detalleRiesgoModal{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // align-items: center;
    position: relative;
    text-align: center;
    // padding: 6%;
    // top: 10rem;
    background-color: #fff;
    width: 80%;
    height: 80%;
    min-height: 400px;
    border-radius: 30px;
    overflow: hidden;
    // background-color: green;

    .detalleRiesgoCritico{
      // background-color: orange;
      background: rgb(179, 54, 8);
      background: linear-gradient(348deg, rgb(179, 54, 8) 32%, rgb(241, 91, 37) 100%);
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
      width: 100%;
      height: 50%;
      display: flex;
      align-items: flex-start;
      padding-top: 60px;
      justify-content: space-around;

      .tituloModalRiesgo{
        font-size: 2rem;
        color: white;
        font-weight: 800;
        max-width: 50%;
        padding-right: 15px;
        text-align: start;
        // background-color: green;
        margin: 0;
      }

      .infoGeneralRiesgo{
        width: 200px;
        height: 100px;
        background-color: #F99F4C;
        border-radius: 10px;
        display: grid;
        grid-template: 1fr / 1fr 1fr;

        #contenedorProbabilidadImpacto{
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: flex-start;
          padding-left: 7px;
          padding-bottom: 5px;

          .labelInfoGeneral{
            color: white;
            font-weight: 700;
          }

          .dataInfoGeneral{
            background-color: white;
            width: 100%;
            border-radius: 5px;
            color: rgb(235, 137, 39);
            font-weight: 700;

          }

          p{
            margin: 0;
          }
        }


      }

    }

    .detalleRiesgoAltoHeader{
      // background-color: orange;
      background: rgb(207,108,10);
      background: linear-gradient(348deg, rgba(207,108,10,1) 32%, rgba(255,169,0,1) 100%);
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
      width: 100%;
      height: 60%;
      display: flex;
      align-items: flex-start;
      padding-top: 60px;
      justify-content: space-around;

      .tituloModalRiesgo{
        font-size: 2rem;
        color: white;
        font-weight: 800;
        max-width: 50%;
        padding-right: 15px;
        padding-left: 15px;
        text-align: start;
        // background-color: green;
        margin: 0;
      }

      .infoGeneralRiesgo{
        width: 200px;
        height: 100px;
        background-color: #F99F4C;
        border-radius: 10px;
        display: grid;
        grid-template: 1fr / 1fr 1fr;

        #contenedorProbabilidadImpacto{
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: flex-start;
          padding-left: 7px;
          padding-bottom: 5px;

          .labelInfoGeneral{
            color: white;
            font-weight: 700;
          }

          .dataInfoGeneral{
            background-color: white;
            width: 100%;
            border-radius: 5px;
            color: rgb(235, 137, 39);
            font-weight: 700;

          }

          p{
            margin: 0;
          }
        }


      }

    }

    .detalleRiesgoAuditoriaAltoHeader{
      // background-color: orange;
      background: rgb(207,108,10);
      background: linear-gradient(348deg, rgba(207,108,10,1) 32%, rgba(255,169,0,1) 100%);
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
      width: 100%;
      height: 45%;
      display: flex;
      align-items: flex-start;
      padding-top: 60px;
      justify-content: space-around;

      .tituloModalRiesgo{
        font-size: 2rem;
        color: white;
        font-weight: 800;
        max-width: 50%;
        padding-right: 15px;
        text-align: justify;
        // background-color: green;
        margin: 0;
      }

      .infoGeneralRiesgo{
        width: 200px;
        height: 100px;
        background-color: #F99F4C;
        border-radius: 10px;
        display: grid;
        grid-template: 1fr / 1fr 1fr;

        #contenedorProbabilidadImpacto{
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: flex-start;
          padding-left: 7px;
          padding-bottom: 5px;

          .labelInfoGeneral{
            color: white;
            font-weight: 700;
          }

          .dataInfoGeneral{
            background-color: white;
            width: 100%;
            border-radius: 5px;
            color: rgb(235, 137, 39);
            font-weight: 700;

          }

          p{
            margin: 0;
          }
        }


      }
    }

    .detalleRiesgoHeader{
      // background-color: orange;
      background: detalleRiesgoHeader;
      background: linear-gradient(348deg, rgb(252, 194, 20) 32%, rgb(201, 172, 44)100%);
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
      width: 100%;
      height: 60%;
      display: flex;
      align-items: flex-start;
      padding-top: 60px;
      justify-content: space-around;

      .tituloModalRiesgo{
        font-size: 2rem;
        color: white;
        font-weight: 800;
        max-width: 50%;
        padding-right: 15px;
        text-align: justify;
        // background-color: green;
        margin: 0;
      }

      .infoGeneralRiesgo{
        width: 200px;
        height: 100px;
        background-color: rgb(63, 150, 49);
        border-radius: 10px;
        display: grid;
        grid-template: 1fr / 1fr 1fr;

        #contenedorProbabilidadImpacto{
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: flex-start;
          padding-left: 7px;
          padding-bottom: 5px;

          .labelInfoGeneral{
            color: white;
            font-weight: 700;
          }

          .dataInfoGeneral{
            background-color: white;
            width: 100%;
            border-radius: 5px;
            color: rgb(162, 162, 162);
            font-weight: 700;

          }

          p{
            margin: 0;
          }
        }
      }

    }

    .rangoRiesgo{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding:0 0 10px ;
      color: white;
      box-sizing: border-box;

      #numeroRango{
        font-size: 2.5rem;
        font-weight: 800;
      }

      #labelRango{
        font-size: 1.3rem;
        font-weight: 600;
      }

      p{
        margin: 0 ;
      }
    }


    .detalleRiesgoMain{
      background-color: $White-dark;
      border-radius: 20px;
      border-top-right-radius: 20px;
      width: 80%;
      margin: 30px auto 0;
      height: 150px;
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      overflow: hidden;
      margin-top: -4%;

      textarea.textarea-tabs-risk.form-control
      {
        width: 100%;
        height: 100%;
        border-radius: 0;
        border: 1px solid rgb(230, 230, 230);
        background-color: white;
      }

      .contenedorPestanasRiesgo{
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: stretch;
        // background-color: green;

        .modal-risk-audit {
          display: flex;
          justify-content: center;
          margin-top: 1em;
        }

        .pestanaRiesgo{
          min-width: 25%;
          width: 100%;
          height: 100%;
          // min-width: 100px;
          font-weight: 700;
          color: rgb(106, 106, 106);

          &:hover{
            border-bottom: 2px solid $Gray-fonts;
          }
        }

        .pestanaActive{
          background-color: white;
          color: rgb(43, 43, 43);
          min-width: 25%;
          width: 100%;
          height: 100%;
          // min-width: 100px;
          font-weight: 700;
          border-bottom: 2px solid $Gray-fonts;


          &:hover{
            border-bottom: 2px solid $Gray-fonts;
          }
        }
      }

      .infoPestanaRiesgo{
        // background-color: green;
        width: 95%;
        height: 59%;
        margin: 10px auto 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 10px;
        text-align: justify;
        overflow-y: auto;
      }

    }

  }

  .infoPestanaRiesgo::-webkit-scrollbar {
    width: 10px;     /* Tamaño del scroll en vertical */
    height: 8px;    /* Tamaño del scroll en horizontal */
    // display: none;  /* Ocultar scroll */
}

  .infoPestanaRiesgo::-webkit-scrollbar-track {
    background: $White;
    border-radius: 4px;
  }

  .infoPestanaRiesgo::-webkit-scrollbar-thumb {
    background: $Gray-fonts;
    border-radius: 4px;
  }

  .modalRiesgoHeader{
    background-color: green;
    width: 100%;
    height: 200px;
    z-index: 26;
  }

  .buttonModalContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .deniedButton{
      background-color: $Red;
      color: $White;
    }

    .cancelButton{
      background-color: $Gray-dark;
      color: $White;
    }
  }

  @media only screen and(max-width: 768px){
    .modalCard{
      width: 90%;
    }
  }
};

.select-button .confirmButton {
  margin-right: 3px;
}

.confirm-container .confirmButton {
  margin-right: 0px;
}


.componentesModal{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $BackgroundModales;
  backdrop-filter: blur(4px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;

  .MuiCollapse-wrapper{
    max-width: 500px;
    margin: 0 auto;
    margin-top: 3rem;
  }

  .MuiStepLabel-label {
    font-size: 18px;
    text-align: start;
  }

  .MuiStepIcon-root.MuiStepIcon-completed {
  color: $Green !important;
  }

  .MuiStepIcon-root.MuiStepIcon-active {
    color: $Blue-dark !important;
  }

  .MuiStepLabel-active{
    font-weight: 700;
    color: $Blue-dark !important;
  }

  .MuiStepLabel-completed {
    color: $Green !important;
  }

  .MuiStepLabel-label.MuiStepLabel-completed {
    font-weight: 700;
  }

  .cardComponentes{
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    // padding: 6%;
    // top: 10rem;
    background-color: white;
    padding: 1.2rem;
    width: 42%;
    min-height: 50%;
    border-radius: 30px;

    .contenedorComponentes{
      background-color: #f8f8f8e7;
      width: 80%;
      margin: auto;
      padding: 10px 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 150px;
      overflow: auto;
      border-radius: 10px;

      .componenteItem{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-height: 25px;
        width: 100%;
        border-bottom: 1px solid $White-dark;

        .chkComponentes{
          margin: 0 10px;
        }
      }
    }

    .contenedorSelect{
      width: 100%;
      max-width: 500px;
      margin-top: 20px;
      margin: 0 auto;
      margin-bottom: 2rem;
      background-color: #f8f8f8e7;
    }

    .closeButtonContainer{
      display: flex;
      width: 100%;
      justify-content: flex-end;
      transition: 0.15s;

    }

    .closeButtonContainer{
      display: flex;
      position: absolute;
      top: 15px;
      justify-content: flex-end;
      right: 15px;
      cursor: pointer;
      border-radius: 50%;
      z-index: 4;
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      background-color: transparent;
      padding: 0;
      color: gray;
      transition: 0.15s;

      // color: $White;

      &:hover{
        background-color: rgb(181, 181, 181);
      }
    }

  }

  .buttonModalContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .deniedButton{
      background-color: $Red;
      color: $White;
    }

    .cancelButton{
      background-color: $Gray-dark;
      color: $White;
    }
  }

  @media only screen and(max-width: 768px){
    .modalCard{
      width: 90%;
    }
  }
};

.cardComponentesPrecarga{
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  // padding: 6%;
  // top: 10rem;
  background-color: white;
  padding: 1.2rem;
  width: 42%;
  // min-height: 45%;
  border-radius: 30px;

  .contenedorComponentes{
    background-color: #f8f8f8e7;
    width: 80%;
    margin: auto;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 150px;
    overflow: auto;
    border-radius: 10px;

    .componenteItem{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-height: 25px;
      width: 100%;
      border-bottom: 1px solid $White-dark;

      .chkComponentes{
        margin: 0 10px;
      }
    }
  }

  .closeButtonContainer{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    transition: 0.15s;

  }

  .closeButtonContainer{
    display: flex;
    position: absolute;
    top: 15px;
    justify-content: flex-end;
    right: 15px;
    cursor: pointer;
    border-radius: 50%;
    z-index: 4;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    background-color: transparent;
    padding: 0;
    color: gray;
    transition: 0.15s;

    // color: $White;

    &:hover{
      background-color: rgb(181, 181, 181);
    }
  }

}

#detalleCargaInicialContainer{
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;
  min-height: 50px;
  width: 80%;
  margin: auto;
  display: flex;
}


.detalleCargaInicial{
  background-color: $Yellow;
  color: whitesmoke;
  width: 50%;
  // min-height: 100px;
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1rem;
  padding: 7px;
  box-sizing: border-box;

}

#contenedorComponentesCargaInicial{
  width: 80%;
  margin: auto;
  background-color: $White-dark;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  max-height: 60%px;
  overflow-y: auto;
  padding: 10px;

  .itemPrecarga{
    width: 70%;
    border-bottom: 1px solid whitesmoke;
    margin: 8px auto;
  }

}

.detalleRiesgoAuditoriaContainer{

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $BackgroundModales;
  backdrop-filter: blur(4px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;

  .closeButtonContainer{
    display: flex;
    position: absolute;
    top: 5px;
    justify-content: flex-end;
    right: 10px;
    cursor: pointer;
    border-radius: 50%;
    z-index: 4;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    background-color: transparent;
    padding: 0;
    transition: 0.15s;

    color: $White;

    &:hover{
      background-color: rgb(179, 179, 179);
    }
  }

  .contenedorModal{
    width: 70%;
    height: 95%;
    border-radius: 30px;
    overflow-x: hidden;
    overflow-y: auto;

  }

  .contenedorModal::-webkit-scrollbar{
    width: 12px;
    margin: 0 0 0 200px !important;
    position: absolute;
  }

  .contenedorModal::-webkit-scrollbar-thumb{
    border-radius: 30px;
    background-color: rgb(160, 160, 160);
    // display: none;
    // color: red;
  }

  .contenedorModal::-webkit-scrollbar-thumb:hover{
    // background: #b3b3b3;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }

  .contenedorModal::-webkit-scrollbar-track{
    background-color: $White;
    border-radius: 30px;
    height: 50vh;
  }

  .detalleRiesgoAuditoriaModal{
    display: flex;
    // flex-direction: column;
    justify-content: flex-start;
    // align-items: center;
    position: relative;
    // text-align: center;
    // padding: 6%;
    // top: 10rem;
    background-color: #fff;
    width: 100%;
    min-height: 100%;
    border-radius: 30px;
    overflow: hidden;
    height: auto;
    // max-width: 625px;
    // background-color: green;

    form {
      margin-top: 0;
      padding-right: 0;
    }

    .detalleRiesgoCritico{
      background: rgb(185, 22, 10);
      background: linear-gradient(348deg, rgb(185, 22, 10) 32%, rgb(216, 33, 19) 100%);
      border-bottom-left-radius: 35px;
      border-bottom-right-radius: 35px;
      width: 100%;
      height: 40%;
      display: flex;
      align-items: flex-start;
      padding-top: 30px;
      justify-content: space-around;
      padding: 30px 7%;

      .tituloModalRiesgo {
        font-size: 1.4rem;
        color: white;
        font-weight: 800;
        max-width: 100%;
        margin: 0;
        margin-bottom: 0.8em;
        padding-right: 15px;
        text-align: justify;
      }

      .infoGeneralRiesgo{
        width: 100%;
        height: 100px;
        background-color: #F99F4C;
        border-radius: 10px;
        display: block;
        padding-top: 2px;
        max-width: 250px;
        // grid-template: 1fr / 1fr 1fr;

        #contenedorProbabilidadImpacto{
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: revert;
          padding-left: 16px;
          padding-right: 16px;
          padding-bottom: 16px;

          .labelInfoGeneral{
            color: white;
            font-weight: 700;
            margin-right: 1em;
          }

          .dataInfoGeneral{
            background-color: white;
            width: 100%;
            border-radius: 5px;
            color: rgb(235, 137, 39);
            font-weight: 700;

          }

          p{
            margin: 0;
          }

          .info-content {
            display: inline-flex;
            margin-bottom: 0.5em;
            gap: 16px;
            width: 50%;

            .info-content-header{
              display: flex;
              display: flex;
              width: 13.255555em;
              margin-top: 0.3em;

              .info-content-header-res {
                width: 100%;
              }

            }

            .info-content-res {
              width: 100%;
              display: inline-flex;
              align-items: center;

              p.labelInfoGeneral {
                font-size: 0.7em;
                font-weight: 400;
                width: 100%;
              }

              p.dataInfoGeneral {
                font-size: 1.3333333333em;
                font-weight: 800;
                width: 50%;
                background: none;
                color: #fff;
              }
            }

          }
        }


      }

    }

    .detalleRiesgoAltoHeader{
      // background-color: orange;
      background: rgb(207,108,10);
      background: linear-gradient(348deg, rgba(207,108,10,1) 32%, rgba(255,169,0,1) 100%);
      border-bottom-left-radius: 35px;
      border-bottom-right-radius: 35px;
      width: 100%;
      height: 240px;
      display: flex;
      align-items: flex-start;
      padding-top: 30px;
      justify-content: space-between;
      padding: 30px 7%;


      .tituloModalRiesgo {
        font-size: 1.4rem;
        color: white;
        font-weight: 800;
        max-width: 100%;
        margin: 0;
        margin-bottom: 0.8em;
        padding-right: 15px;
        text-align: justify;
      }

      .infoGeneralRiesgo{
        width: 100%;
        height: 100px;
        background-color: #F99F4C;
        border-radius: 10px;
        display: block;
        padding-top: 2px;
        max-width: 250px;
        // grid-template: 1fr / 1fr 1fr;

        #contenedorProbabilidadImpacto{
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: revert;
          padding-left: 7px;
          padding-bottom: 5px;

          .labelInfoGeneral{
            color: white;
            font-weight: 700;
            margin-right: 1em;
          }

          .dataInfoGeneral{
            background-color: white;
            width: 100%;
            border-radius: 5px;
            color: rgb(235, 137, 39);
            font-weight: 700;

          }

          p{
            margin: 0;
          }

          .info-content {
            display: inline-flex;
            margin-bottom: 0.5em;

            .info-content-header{
              display: flex;
              display: flex;
              width: 13.255555em;
              margin-top: 0.3em;

              .info-content-header-res {
                width: 50%;
              }

            }

            .info-content-res {
              width: 50%;
              display: inline-flex;
              align-items: center;

              p.labelInfoGeneral {
                font-size: 0.7em;
                font-weight: 400;
              }

              p.dataInfoGeneral {
                font-size: 1.3333333333em;
                font-weight: 800;
                width: 50%;
                background: none;
                color: #fff;
              }
            }

          }
        }


      }

    }

    .detalleRiesgoHeader{
      // background-color: orange;
      background: rgb(207, 161, 10);
      background: linear-gradient(348deg, rgb(240, 163, 30) 32%, rgb(241, 188, 80) 100%);
      border-bottom-left-radius: 35px;
      border-bottom-right-radius: 35px;
      width: 100%;
      height: 240px;
      display: flex;
      align-items: flex-start;
      padding-top: 30px;
      justify-content: space-between;
      padding: 30px 7%;


      .tituloModalRiesgo {
        font-size: 1.4rem;
        color: white;
        font-weight: 800;
        max-width: 100%;
        margin: 0;
        margin-bottom: 0.8em;
        text-align: justify;
        padding-right: 15px;
      }

      label.custom-control-label {
        color: #fff;
        font-size: 1em;
        font-weight: 800;
        padding-top: 0.2em;
      }

      .infoGeneralRiesgo{
        width: 100%;
        height: 100px;
        background-color: #F99F4C;
        border-radius: 10px;
        display: block;
        padding-top: 2px;
        max-width: 250px;
        padding-right: 8px;
        padding-left: 8px;
        // grid-template: 1fr / 1fr 1fr;

        #contenedorProbabilidadImpacto{
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: revert;
          padding-bottom: 5px;

          .labelInfoGeneral{
            color: white;
            font-weight: 700;
            margin-right: 1em;
          }

          .dataInfoGeneral{
            background-color: white;
            width: 100%;
            border-radius: 5px;
            color: rgb(235, 137, 39);
            font-weight: 700;

          }

          p{
            margin: 0;
          }

          .info-content {
            display: inline-flex;
            margin-bottom: 0.5em;

            .info-content-header{
              display: flex;
              display: flex;
              width: 13.255555em;
              margin-top: 0.3em;

              .info-content-header-res {
                width: 50%;
              }

            }

            .info-content-res {
              width: 50%;
              display: inline-flex;
              align-items: center;

              p.labelInfoGeneral {
                font-size: 0.7em;
                font-weight: 400;
              }

              p.dataInfoGeneral {
                font-size: 1.3333333333em;
                font-weight: 800;
                background: none;
                color: #fff;
              }
            }
          }
        }
      }
    }

    .rangoRiesgo{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding:0 0 10px ;
      color: white;
      box-sizing: border-box;

      #numeroRango{
        font-size: 2.5rem;
        font-weight: 800;
      }

      #labelRango{
        font-size: 1.3rem;
        font-weight: 600;
      }

      p{
        margin: 0 ;
      }
    }

    .selects-risk-modal {
      width: 85%;
      margin: 0 auto;
      height: 160px;
      background-color: #fff;
      border-radius: 15px;
      max-height: 160px;
      display: flex;
      position: relative;
      justify-content: space-between;
      top: -1.7em;
      padding: 1em 1em 25px;
      h6 {
        font-size: 1em;
        font-weight: 800;
      }
    }

    .container-select {
      width: 50%;
    }

    .selects-risk-modal .form-label {
      margin: 10px;
      width: 30%;
      font-size: 0.7rem;
      color: #a59c9c;
    }

    .selects-risk-modal .form-control {
      width: 60%;
      background-color: #dddddd;
      border: none;
      height: 24px;
      font-size: 0.8em;
    }

    .selects-risk-modal .form-group {
      display: flex;
      margin-bottom: 0.3em;
      height: 32px;
      text-align: left;
    }

    .modal-risk-audit {
      display: flex;
          justify-content: center;
      margin-top: 1em;
    }

    .detalleRiesgoMain{
      // background-color: red;
      border-radius: 20px;
      border-top-right-radius: 20px;
      width: 80%;
      margin: 30px auto 0;
      // min-height: 170px;
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      // overflow-x: hidden;
      // overflow-y: auto;
      margin-top: -3px;

      textarea.textarea-tabs-risk.form-control
      {
        width: 100%; 
        min-height: 73px;
        height: 70%;
        border-radius: 10px;
        border: 1px solid rgb(230, 230, 230);
        background-color: white;
      }

      .contenedorPestanasRiesgo{
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: stretch;
        // background-color: green;

        textarea.textarea-tabs-risk.form-control {
          width: 100%;
          height: 100%;
          border-radius: 0;
          border: 1px solid rgb(230, 230, 230);
        background-color: white;
        }

        .modal-risk-audit {
          display: flex;
          justify-content: center;
          margin-top: 1em;
        }

        .pestanaRiesgo{
          min-width: 25%;
          width: 100%;
          height: 100%;
          // min-width: 100px;
          font-weight: 700;
          color: rgb(106, 106, 106);

          &:hover{
            border-bottom: 2px solid $Gray-fonts;
          }
        }

        .pestanaActive{
          background-color: white;
          color: rgb(43, 43, 43);
          min-width: 25%;
          width: 100%;
          height: 100%;
          // min-width: 100px;
          font-weight: 700;
          border-bottom: 2px solid $Gray-fonts;


          &:hover{
            border-bottom: 2px solid $Gray-fonts;
          }
        }
      }

      .infoPestanaRiesgo{
        // background-color: green;
        width: 95%;
        height: 59%;
        margin: 10px auto 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 10px;
        text-align: justify;
        overflow-y: auto;
        cursor: none;
      }


    }

  }

  .infoPestanaRiesgo::-webkit-scrollbar {
    width: 10px;     /* Tamaño del scroll en vertical */
    height: 8px;    /* Tamaño del scroll en horizontal */
    // display: none;  /* Ocultar scroll */
}

  .infoPestanaRiesgo::-webkit-scrollbar-track {
    background: $White;
    border-radius: 4px;
  }

  .infoPestanaRiesgo::-webkit-scrollbar-thumb {
    background: $Gray-fonts;
    border-radius: 4px;
  }

  .modalRiesgoHeader{
    background-color: green;
    width: 100%;
    height: 200px;
    z-index: 26;
  }

  .buttonModalContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .deniedButton{
      background-color: $Red;
      color: $White;
    }

    .cancelButton{
      background-color: $Gray-dark;
      color: $White;
    }
  }

  @media only screen and(max-width: 768px){
    .modalCard{
      width: 90%;
    }
  }
};

.formRiesgoModal{
  height: 100%;
}

label.custom-control-label {
  padding-top: 0.2em;
  color: white;
  font-weight: 800;
}

.detalleRiesgoMain .tab-content {
  // max-height: 141px;
  height: 100%;
  margin-top: 10px;
  border-radius: 10px;
}

.detalleRiesgoMain .fade.tab-pane.active.show {
  height: 100%;
}

.impact-result {
  width: auto;
  margin: 0 auto;
  position: relative;
  top: -0.4em;
  height: 30px;
  // background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
}

.detalleRiesgoMain .nav-tabs .nav-link {
  min-width: 25%;
  width: 25%;
  font-weight: 700;
  border: none;
  height: 100%;
  color: #6a6a6a;
  justify-content: center;
  align-items: center;
  display: flex;
}

.detalleRiesgoMain .nav-tabs .nav-link.active {
  color: #2b2b2b;
  border-bottom: 2px solid #2b2b2b;
}

.tabs-risk-form{
  height: auto;
}

nav.tabs-risk-form.nav.nav-tabs {
  border: 0;
}

.detalleRiesgoMain .tab-pane {
  height: 100%;
}

.impact-result .list-group-item {
  display: inline-block;
  font-weight: 700;
}

.impact-result .list-group.list-group-horizontal {
  display: inline-block;
}

.card-riesgo.riesgoAltoItem .tipoRiesgo.inactivo {
  background: grey;
}

.detalleRiesgoMain .form-modal-audit {
  height: auto;
}

table.table-detail.table {
  -webkit-transform: rotate(0deg);
}

.table-detail thead {
  background-color: #E4A731;
  color: white;
}

.table-detail thead tr:hover {
  background-color: #E4A731;
}

.container-table-detail {
  width: 80%;
  margin: 0 auto;
  margin-top: 2em;
  height: auto;
  max-height: 350px;
  overflow-y: scroll;
}

.container-table-detail::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.container-table-detail::-webkit-scrollbar-thumb {
  background: $Blue-dark;
  border-radius: 4px;
}


