.card-riesgos.shadow.card {
    border-radius: 10px;
}

#contenedorCardsRiesgos{
    // background-color: red;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr ;
    grid-auto-rows: auto;
}

.card-riesgo.card {
    background-color: #ffffff;
    height: 140px;
    // min-width: 130px;
    width: 95%;
    margin: 10px auto;
    border-radius: 6px;
    border: 1px solid whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 0.7rem;
    box-sizing: border-box;
    overflow: hidden;
}

p.labelDatoRiesgo {
    font-weight: 800;
    font-size: 0.7rem;
    margin: 0;
}

p.dataDatoRiesgo {
    margin: 0;
}

.contenedorDatoRiesgo {
    display: flex;
    flex-direction: column;
    height: 30px;
    font-size: 0.8rem;
}

.rangoRiesgo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-right: 3px;
    margin-left: -12px;
    top: -9px;
    left: 1em;
    position: relative;
}

.tipoRiesgo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    font-weight: 800;
    color: white;
    width: 100%;
    margin-bottom: 0;
    text-align: center;
}

.card-riesgo.riesgoCriticoItem .tipoRiesgo {
    background-color: #ff0000;
}

.card-riesgo.riesgoAltoItem .tipoRiesgo {
    background-color: #f58617;
}

.card-riesgo.riesgoItem .tipoRiesgo {
    background-color: #ffcd2d;
}

.card-riesgo.riesgo1 .tipoRiesgo {
    background-color: #5B99C0;
    &:hover {
        background-color: #5B99C0;
    }
}

.card-riesgo.riesgo2 .tipoRiesgo {
    background-color: #009688;
}

.card-riesgo.riesgo3 .tipoRiesgo {
    background-color: #FFC000;
}

.card-riesgo.riesgo4 .tipoRiesgo {
    background-color: #F58617;
}

.card-riesgo.riesgo5 .tipoRiesgo {
    background-color: #FF0000;
}

.card-riesgo.riesgo5:hover {
    background-color: #FF0000;
    color: white;
}

.card-riesgo.riesgo5:hover .tipoRiesgo {
    background-color: #fff;
    color: #ff0000;
    width: 85%;
    margin: auto auto 10px;
    border-radius: 6px;
}


.card-riesgo.riesgo4:hover {
    background-color: #F58617;
    color: white;
}

.card-riesgo.riesgo4:hover .tipoRiesgo {
    background-color: #fff;
    color: #f58617;
    width: 85%;
    margin: auto auto 10px;
    border-radius: 6px;
}

.card-riesgo.riesgo3:hover {
    background-color: #FFC000;
    color: white;
}

.card-riesgo.riesgo3:hover .tipoRiesgo {
    background-color: #fff;
    color: #FFC000;
    width: 85%;
    margin: auto auto 10px;
    border-radius: 6px;
}

.card-riesgo.riesgo2:hover {
    background-color: #009688;
    color: white;
}

.card-riesgo.riesgo2:hover .tipoRiesgo {
    background-color: #fff;
    color: #009688;
    width: 85%;
    margin: auto auto 10px;
    border-radius: 6px;
}

.card-riesgo.riesgo1:hover {
    background-color: #5B99C0;
    color: white;
}

.card-riesgo.riesgo1:hover .tipoRiesgo {
    background-color: #fff;
    color: #5B99C0;
    width: 85%;
    margin: auto auto 10px;
    border-radius: 6px;
}

.card-riesgo.riesgoCriticoItem:hover {
    transition: 0.2s;
    background-color: #ff0000;
    color: white;
}

.card-riesgo.riesgoAltoItem:hover {
    transition: 0.2s;
    background-color: #e47a08;
    color: white;
}

.card-riesgo.riesgoItem:hover {
    transition: 0.2s;
    background-color: #ffc400;
    color: white;
}

.card-riesgo.riesgoAltoItem:hover .tipoRiesgo {
    background-color: #fff;
    color: #e47a08;
    width: 85%;
    margin: auto auto 10px;
    border-radius: 6px;
}

.card-riesgo.riesgoCriticoItem:hover .tipoRiesgo {
    background-color: #fff;
    color: #ff0000;
    width: 85%;
    margin: auto auto 10px;
    border-radius: 6px;
}

.card-riesgo.riesgoItem:hover .tipoRiesgo {
    background-color: #fff;
    color: #ffc400;
    width: 85%;
        margin: auto auto 10px;
    border-radius: 6px;
}

.card-riesgo.riesgoItem .tipoRiesgo {
    background-color: #ffc400;
}

.card-riesgo.riesgoInactivo:hover .tipoRiesgo {
    background-color: #fff;
    color: grey;
    width: 85%;
    margin: auto auto 10px;
    border-radius: 6px;
}

.card-riesgo.riesgoInactivo:hover {
    transition: 0.2s;
    background-color: grey;
    color: white;
}

.card-riesgo.riesgoInactivo .tipoRiesgo {
    background-color: grey;
}

.card-riesgo .card-deck {
    width: 90%;
    margin: 0 auto;
}

.detalleRiesgoHeader.inactivo {
    background: linear-gradient(
348deg
, #6b6660 32%, #94928d 100%) !important;
}

.detalleRiesgoHeader.inactivo p.dataInfoGeneral {
    color: grey !important;
}