.table-modal-audit {
    -webkit-transform: rotateX(0deg);
}

td.td-flex {
    display: flex;
    min-width: 100px;
}

.table-modal-audit .unavailable-input {
    background-color: transparent;
    width: 200px;
}

.table-modal-audit .available-input {
    background-color: #fff;
    width: 200px;
    resize: both;
}

.card-content-table {
    background-color: #e7e9eb;
}

button.buttonBeneficiarios.modal-audit {
    background-color: transparent;
}

.editBeneficiarios.buttonBeneficiarios.modal-audit:hover {
    background-color: #E4A731;
    color: #ffffff;
    border-color: #E4A731;
}

.editBeneficiarios.buttonBeneficiarios.modal-audit:focus {
    background-color: #E4A731;
    color: #ffffff;
    border-color: #E4A731;
}

.deleteBeneficiarios.buttonBeneficiarios.modal-audit:hover {
    background-color: #CC1A3A;
    color: #ffffff;
    border-color: #CC1A3A;
}

.deleteBeneficiarios.buttonBeneficiarios.modal-audit:focus {
    background-color: #CC1A3A;
    color: #ffffff;
    border-color: #CC1A3A;
    box-shadow: none;
}

.saveBeneficiarios {
    border: 2px solid #0275d8;
    color: #0275d8;
}

.saveBeneficiarios.buttonBeneficiarios.modal-audit:hover {
    background-color: #0275d8;
    color: #ffffff;
    border-color: #0275d8;
}

.saveBeneficiarios.buttonBeneficiarios.modal-audit:focus {
    background-color: #0275d8;
    color: #ffffff;
    border-color: #0275d8;
    box-shadow: none;
}

.table-modal-audit .MuiDropzoneArea-root {
    width: 100px !important;
    height: 40px;
    min-height: 40px;
}

.table-modal-audit .MuiDropzoneArea-text {
    display: none;
}

.table-modal-audit .MuiSvgIcon-root.MuiDropzoneArea-icon {
    width: 20px;
    height: 35px;
}
