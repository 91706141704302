@import '../../root.scss';


.addIconContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-control{
  width: 90%;
}

.formAddBeneficiarioContainer{
  display: grid;
  grid-template: 0.8fr auto 0.5fr / 1fr 1fr;
  width: 95%;
  margin: 0 auto;

  .formItem{
    width: 95%;
  }

  

  #basicFormB{
    grid-column-start: 2;
    grid-row-start: 1;
  }

  #observacionesAddBeneficiario{
    display: flex;
    flex-direction: column ;
    grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: 3;

    #observacionesFormGroup{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      #observacionesTextArea{
        width: 97.5%;
        min-height: 100px;
      }
    }

  }
  .botonAddBeneficiario{
    display: flex;
    justify-content: flex-end;
    grid-row-start: 3;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  #confirmButtonAddBeneficiarioContainer{
    display: flex;
    justify-content: flex-end;
    grid-row-start: 3;
    grid-column-start: 1;
    grid-column-end: 3;

  }
  
  
    @media screen and (max-width: 768px) {

      grid-template: auto auto auto auto  /  1fr;
      width: 100%;
  
      #basicFormB{
        display: flex;
        flex-direction: column;
        grid-column-start: 1;
        grid-row-start: 2;
      }

      #observacionesAddBeneficiario{
        grid-column-start: 1;

        grid-row-start: 3;

      }

      #confirmButtonAddBeneficiarioContainer{
        grid-column-start: 1;

        grid-row-start: 4;

      }

      .namesContainer{
        width: 100%;
      }
  
      .formItem{
        width: 100%;

        .form-group{
          display: flex;
          align-items: flex-start;
          flex-direction: column;
        
          .namesContainer{
            flex-direction: column;
          }

        }

      }
      
    }
}


.namesContainer{
  display: flex;
  justify-content: space-between;

  // div{
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-around;
  //   width: 80%;
  // }
}

.apellidosContainer{
  display: flex;
}

.form-control{
  font-size: 16px;
}

.buttonBeneficiarios{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 5px;
  border-radius: 5px;
  width: 70px;
  height: 25px;
  padding: 0 2%;
  font-size: 12px;
  font-weight: 600;
  // background-color: $White;

}

.deleteBeneficiarios{

  border: 2px solid $Red;
  color: $Red;

  &:hover{
    background-color: $Red;
    color: $White;
  }

}

.editBeneficiarios{
  border: 2px solid $Yellow;
  color: $Yellow;

  &:hover{
    background-color: $Yellow;
    color: $White;
  }
}

.formEditBeneficiarioContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 90%;
  margin: 2% 5%;

  select{
    background-color: $White;
    width: 40%;
    border-radius: 10px;
    padding: 5px;
    font-size: 14px; 
    border: 1px solid $Gray-fonts;
    height: 30px;
  
  }

  input{
    width: 96%;
    border-radius: 10px;
    padding: 5px;
    margin: 5px 0;
    border: 1px solid $Gray-fonts;
    outline: $Blue-dark-soft;
    height: 30px;


  }

  #idContainerEditBeneficiario{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    select{
      width: 40%;
      border-radius: 10px;
      padding: 5px;
      border: 1px solid $Gray-fonts;
    
    }
    input{
      width: 50%;
      border-radius: 10px;
      padding: 5px;
      border: 1px solid $Gray-fonts;
      outline: $Blue-dark-soft;

    }
  }

  .nombresContainerEditBeneficiario{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin: 2% 0;
  }

}

.formEditBeneficiarioObservacionesContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin: 0 5%;

  #inputObservacionesBeneficiarios{
    border-radius: 10px;
    border: 1px solid $Gray-fonts;
    width: 100%;
    min-height: 100px;
    padding: 10px;
    resize: both;
    outline: $Blue-dark-soft;
  }

}

.nombresContenedor{
  display: flex;
  width: 100%;
}