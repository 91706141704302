@import '/src/root.scss';

.formAuditoriaContainer{
  margin: auto;
  padding: 0;
  width: 87%;
  display: grid;


  .contenedorFormGroupsAuditorias{
    display: grid;
    grid-template-columns: 2fr ;
    grid-auto-rows: auto ;
  }

  #observacionesContainerEstudioBasico{
    grid-column: 1 / 3;
  }

  
}

.buttonContainers{
  grid-column: 1 / 3;
  display: flex ;
  justify-content: flex-end;
  width: 100%;
  margin: 20px 0;
}
