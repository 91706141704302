@import '../../root.scss';

.MuiTimelineItem-missingOppositeContent:before{
  flex: 0 !important;
  padding: 0 !important;
  display: none !important;
}

.TLItem{
  font-weight: bold;
  cursor: default;
  transition: 0.15s;
  &:hover{
    // transform: scale(1.01);
    & .TLItem-box{
      -webkit-box-shadow: 0px 3px 15px -8px rgba(88, 88, 88, 0.85);
      -moz-box-shadow: 0px 3px 15px -8px rgba(88, 88, 88, 0.85);
      box-shadow: 0px 3px 15px -8px rgba(88, 88, 88, 0.85);
    }
  }
}

.TLItem-box_title{
  // background-color: $Blue-dark;
  box-shadow: 0px 4px 4px 0px #00000040;
  font-size: 0.7rem;
  color: white;
  border-radius: 3px;
  width: 100%;
  min-height: 20px;
  padding: 0 3px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 2px 0 5px 0;
  // font-weight: 600;
}

.TLItem-box{
  background-color: rgb(244, 244, 244);
  border-radius: 7px;
  margin: 0;
  padding: 3px 7px;
  text-align: justify;
  transition: 0.15s;

  -webkit-box-shadow: 0px 3px 15px -8px rgba(158, 158, 158, 0.75);
  -moz-box-shadow: 0px 3px 15px -8px rgba(158, 158, 158, 0.75);
  box-shadow: 0px 3px 15px -8px rgba(158, 158, 158, 0.75);



  .TLItem-box_text{
    font-size: 0.7rem;
    color: gray;
  }
}
