.card-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.5rem;
  }
  
  .card-title {
    font-size: 16px;
    font-weight: 700;
    color: var(--gray-700);
  }
  
  .dollar-sign {
    height: 1rem;
    width: 1rem;
    color: var(--gray-500);
  }
  
  .card-content {

    .revenue {
      font-size: 28px;
      font-weight: bold;
      color: var(--gray-700);
    }
  
    .percentage {
      font-size: 14px;
      color: var(--gray-500);
    }
  }