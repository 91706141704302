@import '/src/root.scss';

.bodyEstudioRiesgosContainer{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
}

// observacionesEstudioRiesgo


.formGroupEstudioRiesgo{
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cardContainerSolicitud{
  padding: 3%;
}

#observacionesContainer{
  // display: flex;
  align-items: flex-start;
}

#btnConfirmarEstudioRiesgo{
  // background-color: red;
  grid-column-start: 1;
  grid-column-end: 3;
}

  #observacionesEstudioRiesgo{
    display: flex;
    // flex-direction: column;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 5 ;
    width: 100%;

    #observacionesFormGroup{
      width: 100%;

      .textAreaContainer{
        width: 100%;
      }

    }

    .form-group{
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      #observacionesTextArea{
        width: 100%;
        min-height: 100px;

      }

    }

  }

.formEstudioBasicoContainer {
  display: grid;
  grid-template: 0.8fr auto 0fr / 1fr 1fr;
  // grid-template: 2fr 1fr auto  / 1fr 1fr;
  margin: auto;
  padding: 0;
  // background-color: red;

  .formItem {
    display: flex;
    flex-direction: column;
    align-items: space-between;
    // padding: 3%;
    // justify-content: flex-end;
  }



  .buttonContainer{
    grid-row-start: 3;
    grid-column-start: 1;
    grid-column-end: 3;

    justify-content: flex-end;
  }



  .form-group{
    display: grid;
    grid-template: 1fr / 1fr 3fr;
    justify-content: stretch;
    align-items: center;
    margin-bottom: 10px;

    div > #textArea{
      height: auto;
    }

    .idContainer{
      display: grid;
      align-content: space-evenly;
      // justify-items: center;
      gap: 8px;
      grid-template: 1fr / 3fr 4fr;
      width: 90%;
      

    }

    .inputContainer{
      width: 100%;
    }

  }
}

#contenedorInputsInfoDictamen{
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: space-around;

  .formgroupInputsInfoDictamen{
    // background-color: green;
    // max-width: 70%;
    min-width: 20%;
    margin: 0 10px;
  }

}

@media only screen and (max-width: 976px) {
  #contenedorInputsInfoDictamen{
    flex-direction: column;
    align-items: flex-start;

    .formgroupInputsInfoDictamen{
      // background-color: green;
      // max-width: 70%;
      width: 100%;

      .inputContainer{
        width: 100%;

      }

    }

  }
}


@media only screen and (max-width: 768px) {
  .formEstudioBasicoContainer {
    display: grid;
    grid-template: auto auto auto 0.3fr / 1fr ;

    #basicFormA{
      grid-row-start: 1;
    }

    #basicFormB{
      grid-row-start: 2;
    }

    #observacionesContainerEstudioBasico{
      grid-row-start: 3;

    }

    .buttonContainer{
      grid-row-start: 4;
    }
  }


}

// #basicFormA{
//   grid-column-start: 1;
//   grid-row-start: 2;
//   padding: 0 5%;
// }

// #basicFormB{
//   grid-column-start: 2;
//   grid-row-start: 2;
//   padding: 0 5%;
//   width: 100%;
// }

// #observacionesContainerEstudioBasico{
//   grid-row-start: 3;
//   grid-column-start: 1;
//   grid-column-end: 4;
// }

// .buttonContainer{
//   grid-row-start: 4;

// }

label.label-estudio-riesgos.form-label {
  margin-left: 0.75em !important;
}
