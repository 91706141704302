
.editModalCard{
  height: 90vh !important;
  background-color: white;
}

#contenedorGraficas{
  // background-color: green;
  width: 90%;
  overflow-y: auto;
  max-height: 79%;

  #graficasList{
    width: 98%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0 10px;
  }
}

#cardResumenFactor{

  max-height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
