
  .card__header {
    padding: 1rem;
    padding-bottom: 0;
    gap: 0px;
  }
  
  .card__title {
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1;
  }
  
  .card__description {
    font-size: 1rem;
    color: var(--color-muted-foreground);
  }
  
  .card__content {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 1rem;
    padding: 1rem;
    padding-top: 0;
    margin-top: 16px;
  }
  
  .card__value {
    display: flex;
    align-items: baseline;
    gap: 0.25rem;
    font-size: 32px;
    font-weight: bold;
    font-variant-numeric: tabular-nums;
    line-height: 1;
  }
  
  .card__value__unit {
    font-size: 14px;
    font-weight: normal;
    color: var(--gray-500);
  }
  
  .chart__container {
    margin-left: auto;
    width: 72px;
  }
