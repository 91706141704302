@import '../../../../root.scss';

.buttonAddFotoContainer{
  position: relative;
  display: inline-block;
  display: flex;
  // grid-template: 1fr / 1fr 8fr;
  width: 100%;
  // border: 2px solid $Blue-dark-soft;
  // #selectFile{
  //   display: flex;
  //   align-items: center;
  //   grid-column: 1 / 2;
  //   margin: 0;
  // }
  .selectFoto{
    display: flex;
    // opacity: 0;
    align-items: center;
    height: 50%;
    border-radius: 15px;
    // z-index: -1;
  
  
    // input#file-upload-button{
      background-color: $Blue-dark-soft;
    // }
  
    label{
      background-color: green;
    }
  }

  .uploadFoto{
    background-color: $Blue-dark-soft;
    padding: 0 10px;
    border-radius: 10px;
    border: none;
    color: $White;
    height: 30px;
    width: 120px;
    font-weight: 700;

    &:hover{
      background-color: $Blue-dark;
      transition: 0.3s;
    }

  }


  
}

.file-buttonAddFotoContainer input[type="file"] {
  opacity: 0;
  width: 200px;
  height: 32px;
  display: inline-block;            
}



#addButton{
  grid-column: 1 / 3;
  background-color: $Blue-dark-soft;
  z-index: 1;

  color: $White;
  // width: 100px;
  height: 35px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  // border-top-right-radius: 12px;
  // border-bottom-right-radius: 12px;
  border-radius: 10px;
  font-weight: 700;
  margin: 0;

  &:hover{
  background-color: $Blue-dark;

    // color: $Blue-dark;
  }

}

.fileRow{
  font-size: 14px;
}

.deleteButton{
  background-color: $Red;
  height: 20px;
  color: white;
  width: 80px;
  border: none;
  border-radius: 5px;
  font-size: 12px;
}

// .selectFile{
//   display: flex;
//   align-items: center;
//   padding: 0;
// }

