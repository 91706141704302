@import '../../../root.scss';

// .cardGraficaGeneral{
//   width: 95%;
//   padding: 2%;
//   margin: 0 auto;
//   box-sizing: border-box;
// }



tr:hover {
  background-color: #cbd7e6;
}

table {
  border-collapse: collapse;
  border-radius: 1em;
  overflow: hidden;
  font-size: x-small;
}

td,
th {
  padding: 4px;
  text-align: center;
}
tbody {
  background-color: #efefef;
}

thead {
  background-color: #e0e0e0;
  color: #213265;
}

#tableButton {
  width: 45px;
  height: 20px;
  font-size: xx-small;
  padding: 1px;
  background-color: #e4a731;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  color: white;
  font-weight: 900;
}

tr:nth-child(even) {
  background-color: #cbd7e6;
}


.contenedorBotonGenerarReporte{
  display: flex;
  justify-content: flex-end;
}



.buttonGenerarReporte{
  background-color: $Blue-dark;
  color: $White;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 35px;
  width: 120px;
  font-weight: 800;
  margin: 0 10px;


  &:hover{
    background-color: $Green;
    transition: 0.25s;

  }

}

.cardReporte{
  width: 95%;
  padding: 2%;
  margin: 0 auto;
  box-sizing: border-box;
}

.botonesGraficasReportes{
  display: flex;
  justify-content: center;
  // background-color: red;
}

.graficasReportesContenedorPrincipal{
  // background-color: green;
}