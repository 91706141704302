.card-reportes {
    background: url('https://www.ciacosinte.site/cia2/static/media/reportesoperativos.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: start;
    border-radius: 10px;
}

.body-reportes {
    background: rgba(32, 61, 114, 0.741);
    background-size: cover;
    border-radius: 10px;
}

.configButton {
    background: transparent;
    color: #BABABA;
    text-decoration: underline;
}
