.widgets {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 16px;
    height: auto;
    padding: 1rem;

    .widget {
        padding: 16px;
        border-radius: 5px;
        background-color: #fff;
        box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.02) 0px 0px 0px 1px;
        transition: all 0.3s ease;
        border-bottom: 5px solid var(--blue-600);
        cursor: pointer;

        &:hover {
            box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
        }

        .widget__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px ;
            margin-bottom: 8px;
        }

        .widget__title {
            font-size: 1.2rem;
            font-weight: 600;
            color: var(--gray-700);
            margin-bottom: 0;
        }

        .widget__description {
            font-size: 14px;
            color: var(--gray-500);
        }
    }
}