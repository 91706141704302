@import '/src/root.scss';

.formEstudioBasicoContainerSolVis {
  display: grid;
  grid-template: 0.8fr auto 0.5fr / 1fr 1fr;
  // grid-template: 2fr 1fr auto  / 1fr 1fr;
  margin: auto;
  padding: 0;
  width: 87%;
  // background-color: red;

  #nombreInterno{
    grid-column: 1 / 2;
  }
  #nitEmpresa{
    grid-column: 2 / 3;
  }

  

  .formItem {
    display: flex;
    flex-direction: column;
    align-items: space-between;
    // padding: 3%;
    // justify-content: flex-end;
  }

  #observacionesContainerEstudioBasico{
    display: flex;
    // flex-direction: column;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    width: 100%;

    #observacionesFormGroup{
      width: 100%;

      .textAreaContainer{
        width: 100%;
      }

    }

    .form-group{
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      #observacionesTextArea{
        width: 100%;
        min-height: 100px;

      }

    }

  }

  #observacionesContainerSolInv{
    display: flex;
    // flex-direction: column;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 7;
    width: 100%;

    #observacionesFormGroup{
      width: 100%;

      .textAreaContainer{
        width: 100%;
      }

    }

    .form-group{
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      #observacionesTextArea{
        width: 100%;
        min-height: 100px;

      }

    }

  }

  .buttonContainer{
    grid-row-start: 3;
    grid-column-start: 1;
    grid-column-end: 3;

    justify-content: flex-end;
  }



  .form-group{
    display: grid;
    grid-template: 1fr / 1fr 3fr;
    justify-content: stretch;
    align-items: center;
    margin-bottom: 10px;

    div > #textArea{
      height: auto;
    }


    .form-label{
      margin: 0;
    }

    .idContainer{
      display: grid;
      align-content: space-evenly;
      // justify-items: center;
      grid-template: 1fr / 3fr 4fr;
      
      .custom-select{
        margin: auto 0;
        width: 83%;
      }
    }

    .inputContainer{
      width: 100%;
    }

    .form-control{      
      height: 32px;
      width: 100%;
      margin: 3px 0;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 7px;
      border: 1px solid $Gray-fonts;
      outline: none;

    }

    .custom-select{
      // width: 30%;
      width: 20%;
      height: 30px;
      border-radius: 7px;
      border: 1px solid $Gray-fonts;
      outline: none;
      background-color: $White;
      padding-top: 0px;
    }
  }
}