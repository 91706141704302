@import '../../root.scss';

.titleBox{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 10px;
  height: 25px;
  margin-top: 10px;
}