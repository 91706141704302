.card {
    &__header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      padding-bottom: 0.5rem;
  
      & > div {
        flex: 1;
      }
    }
  
    &__description {
      font-size: 1rem;
      color: var(--color-muted-foreground);
    }
  
    &__title {
      display: flex;
      align-items: baseline;
      gap: 0.25rem;
      font-size: 2.5rem;
      font-variant-numeric: tabular-nums;
  
      &__unit {
        font-size: 0.875rem;
        font-weight: normal;
        letter-spacing: normal;
        color: var(--color-muted-foreground);
      }
    }
  
    &__content {
      display: flex;
      flex: 1;
      align-items: center;
    }
  }