@import '/src/root.scss';

#selectPersonalGerencia{
  width: 100%;
}

#selectPersonalGerencia90{
  width: 90%;
}

#contenedorFiltrosGerencia{
  display: grid;
  grid-template: 1fr / repeat(3, 1fr);
}

#contenidoWidgets{
  display: flex;
  height: auto;
  background-color: rgba(9, 28, 48, 0.694);

  #mainWidgets{
    // background-color: green;
    width: 75%;

    #contenedorContenidoWidget{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      #tituloContenidoWidget{
        font-size: 3rem;
        font-weight: 800;
        color: white;
      }

      #contenidoWidget{
        font-size: 1rem;
        color: rgb(181, 181, 181);
      }
    }

    #contenedorOpcionesWidget{
      display: flex;
      justify-content: flex-start;
      width: 98%;
      margin: 0 1% 0 0 ;
      overflow-x: auto;
      padding-bottom: 5px;
      
      .opcionWidget{
        // background-color: yellow;
        // min-height: 35px;
        min-height: 100%;
        min-width: 160px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        border-left: 2px solid rgb(189, 189, 189);
        margin-right: 10px;
        transition: 0.15s;
        cursor: pointer;

        &:hover{
          #labelOpcion{
            background-color: rgba(141, 141, 141, 0.612);
            color: rgb(213, 213, 213);
          }
        }

        #labelOpcion{
          margin: 0 10px;
          // margin-right: 10px;
          // background-color: red;
          height: 100%;
          display: flex;
          align-items: center;
          transition: 0.2s;
          padding: 2px 10px !important;
          border-radius: 4px;
          font-size: 0.8rem;
          color: white;



          
        }
      }
      .opcionWidgetSelected{
  
        height: 35px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        border-left: 2px solid rgb(189, 189, 189);
        margin-right: 10px;
        transition: 0.15s;
        cursor: pointer;
  
          
          
        #labelOpcion{
          color: rgb(90, 90, 90);
          background-color: rgb(220, 220, 220);
          margin: 0 10px;
          // margin-right: 10px;
          // background-color: red;
          height: 100%;
          display: flex;
          align-items: center;
          transition: 0.2s;
          padding: 2px 10px !important;
          border-radius: 4px;
          font-size: 0.8rem;
          // color: white;
  
  
  
            
          }
      }

    }
  }

  #selectoresWidget{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 25%;
      // min-height: 30vh;
      font-weight: 600;
      color: rgb(209, 209, 209);
      overflow-y: auto;
      padding-right: 10px;
    
    .selectorWidget{
      background-color: rgba(172, 172, 172, 0.3);
      min-height: auto;
      margin-bottom: 10px;
      margin-left: 2%;
      padding: 5px 2%;
      display: flex;
      align-items: center;
      border-radius: 7px;
      transition: 0.15s;
      cursor: pointer;
      color: rgb(213, 213, 213);
      font-size: 1.10rem;
      font-weight: 500;


      &:hover{
        background-color: rgba(150, 150, 150, 0.653);
        color: rgb(199, 199, 199);


      }
    }

    .selectorWidgetSelected{
      background-color: rgba(233, 233, 233, 0.817);
      color: rgb(20, 44, 68);
      margin-bottom: 10px;
      font-size: 1.10rem;
      font-weight: 500;

      
      min-height: auto;
      // margin-bottom: 2%;
      margin-left: 2%;
      padding: 0 2%;
      display: flex;
      align-items: center;
      border-radius: 7px;
      transition: 0.2s;
      cursor: pointer;  

    }
  }

}

#seccionDataInforme{
  display: flex;

  #columnTiposInforme{
    // background-color: green;
    width: 23%;
    border-right: 2px solid $Blue-soft;

    .tipoInforme{
      background-color: rgb(225, 225, 225);
      // height: 35px;
      margin: 0 5% 5px;
      width: 90%;
      display: flex;
      align-items: center;
      padding: 5px 10px;
      border-radius: 7px;
      transition: 0.15s;
      cursor: pointer;
      height: auto;


      &:hover{
        background-color: rgb(201, 201, 201);
      }
    }

    .tipoInformeSelected{
      background-color: $Blue-dark;
      height: auto;
      margin: 0 5% 5px;
      width: 90%;
      display: flex;
      align-items: center;
      padding: 5px 10px;
      border-radius: 7px;
      color: white;
      transition: 0.15s;
      cursor: pointer;
    }
  }

  #contenedorTablaInforme{
    // background-color: red;
    width: 78%;

    #contenedorGraficasInforme{
      width: 95%;
      margin: 0 auto;
    }

    #tablaInforme{
      width: 90%;
      margin: 0 auto;
      font-size: 0.8rem;
    }
  }
}

#infoComplementaria{
  // background-color: green;
  display: flex;
  justify-content: space-between;
  align-items: center;

  #divEstructuraBasica{
    // background-color: green;
    display: flex;

    .divInfoComplementaria{
      margin-right: 15px;
    }
  }
}


@media only screen and (max-width: 768px) {
  #contenedorFiltrosGerencia{
    display: flex;
    flex-direction: column;
  }
}