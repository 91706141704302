
.selector-indice-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    padding: 1rem;
    gap: .5rem;

    .selector-indice {
        background-color: var(--gray-200);
        color: var(--gray-700);
        padding: 0.5rem 1.5rem;
        font-weight: bold;
        border-radius: 6px;
        cursor: pointer;
        font-size: 1rem;
        transition: all 0.3s;

        &:hover {
            background-color: var(--gray-300);
        }

        &.active {
            background-color: var(--darkblue-600);
            color: var(--white);
        }
    }
}

