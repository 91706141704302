@import '/src/root.scss';

.toggleObj{
    height: auto;
    text-align: justify;
    display: flex;
    justify-content: space-between;
}

#contenedorDataGeneralTarea{
    // background-color: green;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5%;
    color: $Blue-dark;

    .btn_gestion_tarea_container{
        display: flex;
        // flex-direction: column;
        margin-top: 10px;
        flex-wrap: nowrap;
    }

    #tituloTarea{
        font-size: 1.5rem;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        // align-items: center;
        text-align: justify;
        width: 100%;

        .btnEditarTarea{
            background-color: $Blue-soft;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            margin-right: 20px;

            &:hover{
                background-color: $Blue-dark-soft;
                transition: 0.2s;
            }
        }

        .btnEditarTareaDone{
            background-color: $Blue-soft;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            margin-left: 20px;

            &:hover{
                background-color: $Green;
                transition: 0.2s;
            }
        }



    }
    #inputTituloTarea{
        background-color: rgba(128, 128, 128, 0);
        outline: none;
        border-top: 0;
        border-right: 0;
        border-left: 0;
        border-bottom: 1px solid $Blue-dark;
        font-weight: 800;
        color: gray;
        margin-top: 10px;
        height: auto;
        // border: none;
    }

    #descripcionTarea{
        text-align: justify;
        max-height: 90px;
        overflow-x: auto;
        padding-right: 15px;

        &::-webkit-scrollbar{
            width: 13px;
            margin: 100px 0;
        }
        &::-webkit-scrollbar-thumb{
            border-radius: 20px;
            background-color: $Gray-fonts;
            // display: none;
            // color: red;
        }

        &::-webkit-scrollbar-track{
            background-color: $White;
            border-radius: 30px;
            height: 50vh;
        }
    }

    #inputDescTarea{
        width: 100%;
        height: 100px;
        outline: none;
        background-color: rgba(255, 0, 0, 0);
        // padding-right: 15px;
        padding: 10px 15px  ;
        border-radius: 15px;
        color: gray;
    }

}
#contenedorAcordeonActividades{
    // background-color: red;
    height: 200px;
    padding-right: 15px;
    overflow-x: auto;
}

.ContainerModalStrategic {
    width: 85%;
    margin: 0 auto;
}

.ContainerModalStrategic .table {
    -webkit-transform: rotateX(0deg);
}

.action-section {
    max-height: 250px;
    overflow-y: scroll;
}

.activities-section {
    max-height: 80%;
    overflow-y: scroll;
}

.ContainerModalStrategic .form-group {
    display: block;
}

.container-button-add {
    text-align: left;
    margin-bottom: 1em;
}

button.buttons-pe.btn.btn-primary {
    height: 30px;
    width: 30px;
    padding: 0;
    border: 0;
    border-radius: 50%;
    margin: 0 2px;
}

button#button-pe-edit {
    background-color: #ffa719;
}

button#button-pe-edit:hover {
    background-color: #fff;
    color: #ffa719;
    border: 1px solid #ffa719;
}

button#button-pe-actions {
    background-color: #0084ff;
}

button#button-pe-actions:hover {
    background-color: #fff;
    color: #0084ff;
    border: 1px solid #0084ff;
}

button#button-pe-delete {
    background-color: #ca0000;
}

button#button-pe-delete:hover {
    background-color: #fff;
    color: #ca0000;
    border: 1px solid #ca0000;
}

.section-title {
    text-align: left;
}

.section-title h6 {
    font-weight: 700;
}

.ContainerModalStrategic .form-group .form-label {
    margin: 0;
    font-weight: 700;
}

