.custom__scrollbar {

    // Estilos del scrollbar para navegadores WebKit (Chrome, Safari, etc.)
    &::-webkit-scrollbar {
        height: 12px; // Ancho del scrollbar vertical
    }


    &::-webkit-scrollbar-track {
        background: var(--gray-200); // Color de la pista
        border-radius: 4px; // Radio de la esquina de la pista
    }

    &::-webkit-scrollbar-thumb {
        background: var(--gray-400); // Color del thumb (la barra que se desplaza)
        border-radius: 10px; // Radio de la esquina del thumb
    }


    // Estilos del scrollbar para Firefox
    scrollbar-width: thin; // "auto" o "thin"
    //Just apply to Firefox
    scrollbar-color: #213265 var(--gray-200); // color del thumb y la pista
}
