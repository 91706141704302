@import '../../../root.scss';

#NoticiasOcensaContainer{
  grid-row-start: 2;
  // background-color: red;
  width: 100%;
  height: 100%;

  #noticiasOcensa{
    background-color: $White;
    border-radius: 10px;
    width: 100%;
    height: 95%;
    padding:7%;

    .contenedorNoticias{
      // background-color: red;
      overflow-y: scroll;
      height: 86%;

      .noticiaItem{
        display: grid;
        grid-template: 0.8fr 0.3fr 1.1fr / 1fr 1fr 1.2fr;
        border-radius: 20px;
        padding: 10px 15px;
        margin-bottom: 10px;
        margin-right: 10px;
        margin-left: 10px;
        background-color: white;
        border: 1px solid $White-dark-soft;
        // justify-items: center;
        align-content: center;

        &:hover{
          transform: scale(1.02);
          transition: 0.2s;
        }

        .tituloNoticiaItem{
          grid-column: 1 / 3;
          grid-row-start: 1;
          font-weight: 700;
          color: $Blue-dark;
          padding-right: 10px;
        }

        .noImage{
          grid-column: 3 / 3;
          grid-row-start: 1;
          border-radius: 15px;
          overflow: hidden;
          background-size: cover;
        }
        #enlaceNoticiaItem{
          grid-column: 1 / 2;
          grid-row-start: 2;
        }
        
        .descripcionNoticiaItem{
          grid-column: 1 / 4;
          grid-row-start: 3;
          margin: 0;
          display: flex;
          width: 100%;
          align-items: center;
          color: $Gray-fonts;
        }

      }

      &::-webkit-scrollbar{
        background-color: $White-dark-soft;
        border-radius: 20px;
        width: 10px;
      }

      &::-webkit-scrollbar-thumb{
        background-color: $Gray-fonts;
        border-radius: 20px;
      }
    }

  }
}