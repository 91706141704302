.form-modal-audit.form-group {
    display: block;
}

.mdl-aud form {
    padding: 0;
}

.mdl-aud .form-group .form-label {
    margin-bottom: .5rem;
    margin-left: 0;
}

.drop-zone-area {
    height: 100%;
}

.mdl-aud .modal-content {
    background-color: #f8f8f8e7;
}

.fade.audit-image-form {
    opacity: 1;
}

.center-image-modal {
    text-align: center;
    margin-top: .5em;
    margin-bottom: .5em;
}

.img-mdl-audit .modal-content {
    background-color: #e7e9eb;
    border: none;
}

.fade.modal-backdrop.fade.audit-image-form.show.show {
    opacity: 0.25;
    background-color: rgba(22, 45, 68, 0.8);
}

.img-mdl-audit .deniedButton {
    background-color: #CC1A3A;
    color: #ffffff;
    border-radius: 15px;
    height: 35px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 15px;
    box-shadow: none;
    border: 1px solid #CC1A3A;
    font-weight: 800;
}

.img-mdl-audit .deniedButton:hover {
    border: 2px solid #213265;
    background-color: #8b97a7;
    color: #213265;
    font-weight: 800;
}

.fade.img-mdl-audit.modal.show {
    opacity: 1;
}

.table-responsive::-webkit-scrollbar {
    width: 8px;     /* Tamaño del scroll en vertical */
    height: 8px;    /* Tamaño del scroll en horizontal */
    // display: none;  /* Ocultar scroll */
}

.table-responsive::-webkit-scrollbar-thumb {
    background: #2e4257;
    border-radius: 4px;
}

.table-responsive::-webkit-scrollbar-thumb:active {
    background-color: #2e4257;
}

.table-responsive::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 4px;
}

.table-responsive::-webkit-scrollbar-track:hover,
.table-responsive::-webkit-scrollbar-track:active {
  background: #ffffff;
}


.mdl-aud .table-responsive{
    overflow-y: scroll;
    height: 300px;
    min-height: 300px;
}

.validate-fields-form.valid-feedback {
    color: #dc3545;
}

.MuiDropzoneArea-root.validated-dropzone {
    border-color: #dc3545;
}

p.invalid-feedback.audit-form {
    display: block;
}