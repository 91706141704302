@import '../../../root.scss';

.item-question {
    display: inline-flex;
    // max-height: 124px;
    padding: 0;
    border: 0;
    margin: 6px 0;
    border-radius: 10px !important;
    box-shadow: rgba(136, 165, 191, 0.2) 6px 2px 16px 0px, rgba(255, 255, 255, 0.2) -6px -2px 16px 0px;
}

.botonOpcionesCriterio {
    cursor: pointer;
    // background-color: $White-dark-soft;
    border-radius: 50%;
    margin: 0 4px;
    padding: 2px;
    transition: 0.5s;

    &:hover {
        background-color: rgb(182, 182, 182);
        color: white;
        // transition: 0.3s;
    }
}

.accordionAuditorias {
    // background-color: red;
    // border-radius: 0px 15px 15px 15px;
    overflow: hidden;
}

.bodyCardQuestions {
    max-height: 370px;
    overflow-y: auto;
    padding-left: 0;
    border: none !important;
}

.container-question {
    width: 100%;
    min-height: 64px;
    height: auto;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    border: 1px solid var(--gray-200);


    .question-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        color: var(--gray-600);
        gap: 12px;
        padding-left: 16px;

        .p-question {
            font-size: 14px;
            padding: .5rem 0;
        }

        .question-actions {
            height: 100%;
            border-radius: 0 10px 10px 0;
            display: flex;
            flex-direction: row;

            .question-info {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                border-left: 1px solid var(--gray-200);
                background-color: white;
                color: var(--gray-600);
                border-radius: 0 10px 10px 0;
                margin-left: 8px;

                .info-item {
                    display: flex;
                    justify-content: center;
                    gap: 4px;
                    align-items: center;
                    padding: 0.5rem;
                    border-bottom: 1px solid var(--gray-200);
                    width: 100%;
                    font-size: 12px;
                    flex: 1;
                    font-weight: 700;
                }

                .info-item:last-child {
                    border-bottom: none;
                }
            }
        }
    }

}

.icon-list-question {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    // background-color: green;
    // width: 500px;
    text-align: right;
}

.selected-status {
    // background-color: $Gray-fonts;
    border-radius: 10px;
}

.question-accordion-card.card {
    // box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    background-color: $White;
    border-left: 1px solid $White-dark-soft;
    border-right: 1px solid $White-dark-soft;
}

.question-accordion-card.card .card-header {
    height: 45px;
    padding: 0.5em 1em;
    color: $Gray-dark;
    font-size: 0.81rem;
    // font-weight: bold;
    text-align: justify;
}

button.button-accord.btn.btn-link {
    height: 45px;
    padding: 0.5em 1em;
    color: $Gray-dark;
    font-size: 0.81rem;
    text-align: justify;
    text-decoration: none;
}

button.obs-item-audit.btn.btn-primary {
    margin-bottom: 1em;
    background-color: #213265;
    border-color: #213265;
}

.items-audit-left {
    text-align: right;
    width: 96%;
}

.form-group .audit-form-textarea.form-control {
    height: 100px;
}

.container-audit-form {
    width: 80%;
    margin: 0 auto;
}

.btn-form-row {
    float: right;
}

.btn-form-row .confirmButton {
    margin-right: 0;
}

.audit-form .form-group {
    display: block;
}

.card-header {
    background-color: $Blue-soft;
}

.audit-button-modal {
    background-color: #e0e0e0;
    border: none;
    font-weight: 700;
    height: 30px;
    width: 100px;
    margin: 0 3px;
    color: #1d1d1d;
    line-height: 0;
}

.audit-button-modal:hover {
    background-color: #213265;
    box-shadow: none;
}

.audit-button-modal:focus {
    background-color: #213265;
    box-shadow: none;
}

.audit-button-modal:active {
    background-color: #213265 !important;
    box-shadow: none;
}

button#abm-left {
    border-radius: 6px 0 0 6px;
}

button#abm-right {
    border-radius: 0 6px 6px 0;
}


span.sp-icon-res {
    // position: absolute;
    // top: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0;
    // transform: translate(-50%);
    transition: 0.25s;
}

.icon-question-res {
    width: 48px;
    color: white;
    border-radius: 10px 0 0 10px;
}

span.sp-icon svg.MuiSvgIcon-root {
    width: 30px;
    height: 30px;
    color: $Gray-fonts;
    border-radius: 50%;
    padding: 2px;

    &:hover {
        background-color: rgb(221, 221, 221);
        // color: white;
        transition: 0.3s;
    }

}

span.sp-icon {
    color: $White-dark;
    margin: 0 2px;
    text-align: center;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

button.button-accord.btn.btn-link:focus {
    box-shadow: none;
}

.MuiBadge-anchorOriginTopRightRectangle {
    top: 4px !important;
    right: 3px !important;
}

.MuiBadge-badge {
    min-width: 15px !important;
    width: 15px !important;
    font-size: 11px !important;
    height: 15px !important;
}

.headerComponente {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-weight: 800;
    color: var(--gray-600);
    // background-color: red;

    .tituloComponente {
        font-size: 1.7rem;
    }
}

.items-left {
    float: right;
    width: 50%;
    text-align: left;
}

.items-right {

    text-align: right;
    padding-left: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 8px;
}

#tooltip-help>.tooltip-inner {
    background-color: #fff;
    color: #213265;
    border: 1px solid #213265;
}

// #tooltip-help > .arrow {
//     border-top: 0.5px solid #062e56;
// }

.tooltip.bs-tooltip-top .arrow::before {
    border-top-color: #213265;
}

.tooltip.bs-tooltip-bottom .arrow::before {
    border-bottom-color: #213265;
}

.tooltip.bs-tooltip-start .arrow::before {
    border-left-color: #213265;
}

.tooltip.bs-tooltip-end .arrow::before {
    border-right-color: #213265;
}


/* Tarjeta de criterios */

.tarjeta__criterios {
    border-radius: 10px;
    margin: .5rem 0;
    display: inline-flex;
    width: auto;
    flex-direction: row;
    border: 1px solid var(--gray-200);
    box-shadow: rgba(136, 165, 191, 0.2) 6px 2px 16px 0px, rgba(255, 255, 255, 0.2) -6px -2px 16px 0px;

    .criterio {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        padding: .5rem .5rem;
        gap: 12px;
        border-right: 1px solid var(--gray-200);


        .criterio__info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            color: var(--gray-600);
            font-size: 14px;

            // Selecciona el primer span
            span:nth-of-type(1) {
                font-weight: 700;
                line-height: 1;
            }

            // Selecciona el segundo span
            span:nth-of-type(2) {
                font-weight: 400;
                line-height: 1;
            }

        }
    }

    .criterio:last-child {
        border-right: none;
    }
}

.divider__label {
    font-weight: 700;
    color: var(--gray-600);
}