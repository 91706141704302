.accordion__toggle {
  background-color: var(--gray-50);
  border: 1px solid var(--gray-200);
}

.accordion__line {
  width: 1px;
  background-color: var(--gray-300);
  margin-left: .5rem;
}
