@import '/src/root.scss';

.icons-cont-arr{
  display: flex  ;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin: 0 3px;
}

.icon-cont{
  transition: .1s;
  cursor: pointer;
  border-radius: 50%;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  &:hover{
    background-color: rgb(232, 232, 232);
  }
}

.link_modal{
  transition: 0.15s;

  &:hover{
    color: rgb(86, 142, 192);
  }
}

p{
  margin: 0;
}

#header_tabla{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  background-color: #F5F5F5;
  padding: 12px 16px;
  border-radius: 8px;
  color: $Blue-dark;
  font-weight: 600;
  text-align: center;

  #header_tabla-numero_registros{

    display: flex;
    align-items: center;
    // background-color: green;

    p{
      margin-right: 10px;
    }



    #cantidad_regs{
      margin: 0;
      width: auto;
      border: 2px solid #e2e8f0;
      padding: 0 16px;
      text-align: center;
      color: #1e293b;
    }


  }

  #header_tabla-busqueda{
    display: flex;
    align-items: center;
    // background-color: green;
    width: 250px;

    p{
      margin-right: 10px;
    }
  }

  #header_tabla-paginado{
    display: flex;
    justify-content: space-around;
    padding: 0 15px;
    // background-color: red;
    min-width: 100px;

    .paginadoItems{
      display: flex;
      align-items: center;
    }

    #pagactualnum{
      display: flex;
      justify-content: space-around;
    }

    #pag_total_num{
      display: flex;
      justify-content: space-around;
    }

    .paginado__numeros {
      display: flex;
      gap: 5px;
      padding: 0 16px ;
    }
  }

  #header_tabla-total_registros{
    display: flex;
    justify-content: space-between;
    align-items: center;
    // background-color: green;
    min-width: 130px;

    #total_regs{
      background-color: $Blue-dark;
      height: 30px;
      width: auto;
      color: white;
      padding: 0 10px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      margin-left: 10px;
    }
  }
}


#tabla_reportes{

  // overflow-x: overlay;
  // background-color: red;
  border-radius: 10px;
  margin-top: 20px;
  margin: 20px 10px 0;

}

.resaltado_col{
  width: 15% !important;
}

#cols{
  display: flex;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  width: auto;
  border-radius: 10px 10px 0 0;
  margin: 0 0 15px;



  .col_item{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    background-color: #EEEEEE;
    color: $Blue-dark;
    margin: 0;
    font-weight: 800;
    width: 100%;
    flex: 1;
  }

  .col_check{
    cursor: pointer;

    input {
      accent-color: $Blue-dark;
    }
  }

  .resaltados{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    background-color: #EEEEEE;
    color: $Blue-dark;
    // margin: 0;
    font-weight: 800;
    // width: 100%;
    // flex: 1;
  }


}

.table_reps-container{
  // overflow-y: overlay;

  padding: 15px;
  border-radius: 10px;
  // -webkit-transform: rotateX(180deg);
  // transform: rotateX(180deg);
  // z-index: -1000000000000000000000;

  .table_reps{
    // overflow-anchor: 10px;
    // overflow-x: scroll;
    // overflow: visible;
    // padding: 0 10px;
    overflow-x: auto;
    // background-color: blue;
    // border-collapse: collapse;
    width: auto;

    border-radius: 10px;
    font-size: 14px;
    text-align: start;
    // -webkit-transform: rotateX(180deg);
    // transform: rotateX(180deg);

  }
}

.table_reps-container::-webkit-scrollbar {
  // background-color: green;
  border-radius: 15px;

}


.table_reps-container::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background-color: $Gray-fonts;
}

.table_reps-container::-webkit-scrollbar-track {
  border-radius: 15px;
  background-color: $White-dark;
}


.reg_item{
  // background-color: red;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  width: auto;

  .colreg{
    min-height: 35px;
    // min-width: 300px;
    display: flex;
    justify-content: center;
    flex: 1 1;
    word-break: break-word;
    font-size: 12px;
    text-align: center;
    text-overflow: ellipsis;

  }

  .colreg--selected {
    background-color: #dbeafe;
    color: #2563eb;
    padding: 8px;
    border-radius: 10px;
  }

}

.salto__linea {
  white-space: pre-wrap
}

.colors_container{
  display: flex;
  justify-content: center;
  // min-width: 15%;
  flex: 1 1;

  // background-color: green;
}

.contenedor_resaltador{
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: rgb(247, 247, 247);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 3px;
  transition: 0.2s;

  &:hover{
    background-color: rgb(218, 218, 218);
    transform: scale(1.1);
    // border-bottom: 1px solid green;

  }
}

#regs{
  width: 100%;
  overflow-x: visible;
}

.colreg_cont{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 35px;
  flex: 1 1;
  word-break: break-word;
  font-size: 12px;
  text-align: left;
  text-overflow: ellipsis;
  width: 100%;

}

.colreg_check {
  /* Change the color of input checkbox */
  input {
    accent-color: $Blue-dark;
  }
}


.label_col{
  padding: 4px;
  background-color: rgb(238, 238, 238);
  border-radius: 4px;
  width: 100%;
  display: none;
  text-align: center;
}

.p-overlaypanel .p-overlaypanel-close {
  background: $Blue-dark !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: $Blue-dark !important;
}

.p-inputswitch {
  transform: scale(.8);
}

.myElement:hover{
  color: 'red';
}

.generalCard {
  border-radius: 10px;
}

.modal__list__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e2e8f0;
  transition: 0.2s;
  padding: 4px;

  &:hover{
    background-color: #f5f5f5;
  }

  .salto__linea {
    white-space: pre-wrap;
    font-size: 12px;
    padding-right: .5rem;
  }

}

.pagesTitles--small {
  font-size: x-large;
}

.vacio__container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 2rem 2rem 2rem;

  .vacio__titulo{
    color: $Blue-dark;
    font-weight: 900;
    font-size: 18px;
  }

  .vacio__descripcion {
    color: var(--gray-600);
    font-weight: 400;
    font-size: 14px;
    margin-top: 4px;
  }
}

@media only screen and ( max-width: 425px) {
  .reg_item{
    flex-direction: column;
  }


}

@media only screen and ( min-width: 769px ) and ( max-width: 976px) {

  // .reg_item{
  //   flex-direction: column;
  // }

}

@media only screen and ( max-width: 600px) {



  #header_tabla{
    flex-direction: column;
    font-size: 0.7rem;
  }



}

@media only screen and ( max-width: 768px) {

  .label_col{
    display: flex;
    justify-content: center;
  }

  .reg_item{
    flex-direction: column;
    margin: 4%;
    border-radius: 10px;

  }

  .card-body{
    margin: 0;
  }


  .col_tabla{
    display: none;
  }

  // #header_tabla{
  //   flex-direction: column;
  //   font-size: 0.7rem;
  // }

  #header_tabla-total_registros{
    flex-direction: column;
  }

  #header_tabla-numero_registros, #header_tabla-paginado, #header_tabla-total_registros{
    margin: 5px auto;
  }
}
