@import '/src/root.scss';

.item_toggle{
  height: 50px;
  background-color: $Blue-soft;
  // border: 1px solid $Blue-dark-soft;
  font-size: 1.1rem;
  font-weight: 700;
  color: $Blue-dark;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 2%;
  border-bottom: 1px solid $Blue-dark-soft;
}

// .labelWidget{
//   grid-row-start: 1;
//   font-weight: 600;
//   font-size: 1rem !important ;
//   width: 150px;
//   color: rgb(69, 69, 69);
// }

.contenidoWidget-panelPresidencia{
  display: grid;
  grid-template: 20% 80% / 1fr;
  height: 100%;

  .cantidadWidget{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 1.4rem;
    font-weight: 700;
    padding: 10px 0;
    margin: 0;
  }

  .labelWidget{
    grid-row-start: 1;
    font-weight: 600;
    font-size: 1rem !important ;
    width: 150px;
    color: rgb(69, 69, 69);
  }

  
  
}