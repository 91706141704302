.bodyGraficasGeneral{
  padding: 0;
}

.panelCard{
  // background-color: red;
  height: 97%;
  width: 33vw;
  padding: 1%;
  border-radius: 20px;
  margin: 2% 0 2% 0;
  box-sizing: border-box;
  // display: flex;
  // justify-content: center;
  // align-items: flex-start;
}

.donutContainer{
  display: flex;
  // flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  width: auto;
  overflow-y: none;
  height: 80%;
}

.rowDonut{
  display: flex;
  flex-wrap: wrap;
  padding: 3% 0;
  margin-left: 10px;
}



.recharts-pie{
  width: 100%;
  height: 100%;
}

pre{
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  text-align: center;
  flex-wrap: wrap;
}

pre div{
  width: auto;
}
.panelFull{
  width: 73vw;
  border-radius: 20px;
  height: 100%;
}


rect{
  // width: 100%;
  min-height: 280px;
}

.convencionesContainer{
  display: flex;
  justify-content: space-around;
  // background-color: gainsboro;
  width:auto;
  margin: 0 auto;
  height: 50px;

  .contenedorx{
    display: flex;
    align-items: center;
    width: 150px;

    #sinAnotacion{
      color: #ed8232;
    }

    #conAnotacion{
      color: #F9CF00;
    }

    #vd{
      color: #ed8232;
    }

    #de{
      color: #A1CAF1;
    }

    #e{
      color: #BF3833;
    }

    #c{
      color: #C2B280;
    }
  }

  .convencion{
    font-size: 100px;
    font-weight: 700;
    color: red;
    margin: -60px 0 0 0 ;
    padding: 0;
  }
}

.cardGraficaAnalistas, .cardCombinada, .cardColumnas3d, .cardEmbudo, .cardDona1{
  min-width: 49%;
  height: auto;
}


.cardGraficaTipos{
  width: 100%;

}

.cardDivergente{
  width: 100%;

}



@media only screen and (max-width: 1020px){
  .panelCard{
    width: 62vw;
  }
  .panelFull{
    width: 62vw;
    border-radius: 20px;
    height: 100%;

  }

  .cardGraficaAnalistas, .cardColumnas3d, .cardEmbudo, .cardDona1{
    width: 100%;
  }
  
}