@import '../../root.scss';

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

// body{
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
//   width: 100%;
//   background-color: $White-dark;
// }

.mainContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: $White-dark;


  .mainCardContainer{
    display: grid;
    grid-template: 1fr / 1fr 1.3fr;
    width: 80%;
    // padding: 10%;
    // height: 86%;
    height: 80vh;
    border-radius: 20px;
    background-color: $White-dark;
    -webkit-box-shadow: 1px 1px 81px -28px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 1px 81px -28px rgba(0,0,0,0.75);
    box-shadow: 1px 1px 81px -28px rgba(0,0,0,0.75);

    #loginContainer{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: white;
      padding: 7% 7% 0 7%;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;

      img{
        width: 260px;
        margin: 0 auto;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }

    }

    #loginImage{
      background-image: url('../../static/login-image.jpg');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      grid-column-start: 2;
      width: 100%;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      overflow: hidden;
    }
  }

  @media only screen and (max-width: 1120px) {

    .mainCardContainer{
      display: flex;
      flex-direction: column;

      #loginContainer{
        // grid-column-start: 1;
        width: 100%;
        height: 100%;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;

        img{
          width: 80%;
          margin: 0 auto;
        }

      }

      #loginImage{
        display: none;
      }
    }

  }
}
