@import '../../root.scss';
@import './panelCards.scss';

#contenedorComponentesSeleccionados{
  // background-color: green;
  display: flex;
  align-items: center;
}

.MuiStepper-root{
  padding: 15px 24px !important;
}

.componenteSeleccionadosLabel{
  font-weight: 700;
  color: $Gray-fonts;
  margin-right: 10px;
  // width: 300px;

}

.componenteSeleccionadosList{
  display: flex;
  // background-color: red;
  align-items: center;
  width: 80%;
  flex-wrap: wrap;

  .itemSeleccionado{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    min-width: 80px;
    font-weight: 700;
    // max-width: 150px;
    width: auto;
    border-radius: 5px;
    background-color: $Yellow;
    color: white;
    margin:  5px;
    font-size: 0.8rem;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}


#contenedorEnlacePrecarga{
  height: 36px;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: auto;
  // background-color: red;
  top: 15px;
  right: 20px;

  #enlacePrecarga{
  background-color: $White-dark;
  height: 35px;
  border-radius: 15px;
  padding: 5px ;
  box-sizing: border-box;
  display: flex;

    color: white;
    font-size: 1rem;

    #labelInfoPrecargadaDetail{
      // display: none;
      // background-color: white;
      // color: $Blue-dark;
      width: 0;
      overflow: hidden;
      border-radius: 15px;
      font-weight: 600;
      font-size: 0.7rem;


    }

    &:hover{
      background-color: $Blue-dark;
      transition: 0.2s;


      cursor: pointer;
      // color: $Blue-dark;
      // text-decoration: underline ;
      #iconoPrecarga{
        transform: rotate(180deg);
        transition: 0.2s;

      }

      #labelInfoPrecargadaDetail{
        // display: relative;
        width: auto;
        font-size: 1rem;
        transition: 0.2s;
      margin: auto 6px;

      }

    }
  }


}

.mainCardsContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // flex-wrap: wrap;
  // background-color: green;
  height: auto;
  // padding-top: 70px;
  padding: 0;

  .cardsContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;
  }
}

.contenedorSecciones{
  width: 75%;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 10px;
  z-index: 2;
  margin: auto;
  // top: 60px;

}

.seccionesGraficas{
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  justify-content: space-between;
  min-width: auto;
  // border-top-left-radius: 7px;
  // border-top-right-radius: 7px;
  border-radius: 7px;
  border-bottom: 3px solid $Blue-dark;
  background-color: none;
  // top: 80px;
  background-color: $White;
  overflow: hidden;

  .seccionPanel{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: $White;
    color: $Blue-dark;
    height: 45px;
    padding: 3px 10px;
    min-width: 90px;
    width: auto !important;
    font-size: 11px;
    font-weight: 600;
    transition: 0.1s;

    &:hover{
      background-color: $White-dark;
    }

    &:focus{
      background-color: $Blue-dark-soft;
      color: $White;
    }

  }

}

.carousel-indicators li {
  background-color: $Blue-dark;
}

.carousel-control-next, .carousel-control-prev {
  width: 40px;


}

.carousel-control-prev-icon, .carousel-control-next-icon{
  background-color: $Blue-dark;
  padding: 5px;
  border-radius: 4px;
  margin: 5px;
  width: 20px;
  height: 20px;
}

.carousel-indicators{
  z-index: 1 !important ;
  bottom: -50px;
}

.divGraficasDiarias{
  height: auto;
  // max-width: 50%;
  width: 93%;
  margin: auto;
  // background-color: green;
  color: red;
  margin-bottom: 20px;
}

.titleContainer{
  padding-bottom: 20px;
}

.contenedorDosCards{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}

.contenedorGraficasDiarias{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 49%;
}

// .contenedorDosCards{
//   display: flex;
//   justify-content: space-around;
//   flex-wrap: wrap;
//   width: 100%;
// }

.tituloReporteGeneral{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding-top: 20px;
  font-size: 30px;
  text-align: center;
  font-weight: 900;
  color: $Blue-dark;
  border-top: 2px solid rgb(189, 189, 189);
  margin: 35px auto;
}

.labelSelect{
  width: 90%;
  color: $Blue-dark;
  font-size: 1.2rem;
  font-weight: bolder;
  text-align: justify;
}

.formControlFiltroGeneral , .date{
  background-color: $White-dark;
  border-radius: 7px;
  border: none;
  margin-left: 10px;
  font-size: 16px;

  & .date{
    padding: 10px;
  }
}

.form-control{
  border: 0;

}

.form-label{
  margin: 0;
}

.selectsFechas{
  // background-color: green;
  display: flex;
  align-items: center;
  justify-content: stretch;
  width: 100%;

  .form-group{
    width: 60%;
  }
}

.list-criteria {
  margin: 0.2rem 2.5555555rem;
}

.list-group-criteria {
  margin-top: 0.5555555em;
}

.list-group-criteria .list-group-item {
  color: #213265;
  border: 1px #213265 solid;
}

#btnConfirmar{
  background-color: $Blue-dark;
  color: white;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  margin-top: 24px;

  &:hover{
    background-color: $Green;
    color: white;
    transition: 0.2s;
  }
}

#btnAtras{
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.btnSecundario{
  width: 100%;
  height: 40px;
  margin: 0 3px;
  background-color: transparent;
  border: 2px solid $Blue-dark;
  font-weight: 700;
  border-radius: 8px;
  margin-top: 24px;
  color: #213265;
  // color: white;

  &:hover{
    background-color: $Blue-dark;
    color: white;
    transition: 0.2s;
  }
}


#contenedorBotonesFinalizarFiltro{
  // background-color: green;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;

  button{
    width: 100px;
    height: 35px;
    margin: 0 3px;
    border: 0;
    font-weight: 700;
  }

  #btnLimpiarPaso3{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;

    &:hover{
      background-color: $Gray-fonts;
      color: white;
      transition: 0.2s;
    }
  }

  #btnFiltrarPaso3{
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: $Blue-dark;
    color: white;



    &:hover{
      background-color: $Green;
      color: white;
      transition: 0.2s;
    }

  }
}

.label_item_select{
  color: $Gray-fonts;
  font-size: 1rem;
  font-weight: bolder;
  text-align: justify;
  margin-bottom: 12px;
}

.contenedor_step{
  text-align: left;
  font-size: 1rem;
  margin-top: 2rem;

  // background-color: green;
}

.step_label_active{
  color: $Blue-dark;
  font-weight: 700;
}

.input_departamento{
  width: 100%;
}

.contenedor_componentes_filtro{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  span{
    font-weight: 700;
  }
}


@media only screen and (max-width: 1020px){
  .mainCardsContainer{
    justify-content: center;
  }

  .contenedorDosCards{
    flex-direction: column;
  }
}


