.card {
    &__header {
      padding-bottom: 0;
    }
  
    &__description {
      font-size: 16px;
    }
  
    &__title {
      font-size: 40px;
      font-variant-numeric: tabular-nums;
    }
  
    &__title__unit {
      font-family: sans-serif;
      font-size: 0.875rem;
      font-weight: normal;
      letter-spacing: normal;
    }
  
    &__content {
      padding: 1rem;
    }
  
    &__footer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.25rem;
    }
  
    &__footer__description {
      font-size: 16px;
      color: var(--gray-500);
    }
  
    &__footer__highlight {
      font-weight: bold;
      color: var(--gray-700);
    }
  }