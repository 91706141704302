  .card__header {
    padding-bottom: 0;
    .card__description {
      margin-bottom: 0;
      font-size: 16px;
    }
  }
  
  .card__title {
    display: flex;
    align-items: baseline;
    gap: 0.25rem;
    font-size: 2.5rem;
    font-variant-numeric: tabular-nums;
  }
  
  .card__title__unit {
    font-family: sans-serif;
    font-size: 0.875rem;
    font-weight: normal;
    letter-spacing: normal;
    color: var(--color-muted-foreground);
  }
  
  .card__content {
    padding: 0;
  }
  
  .chart__tooltip {
    display: flex;
    min-width: 120px;
    align-items: center;
    font-size: 0.75rem;
    color: var(--color-muted-foreground);
  }
  
  .chart__tooltip__value {
    margin-left: auto;
    display: flex;
    align-items: baseline;
    gap: 0.125rem;
    font-family: monospace;
    font-weight: medium;
    font-variant-numeric: tabular-nums;
    color: var(--color-foreground);
  }
  
  .chart__tooltip__unit {
    font-weight: normal;
    color: var(--color-muted-foreground);
  }