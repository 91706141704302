.checkbox__green .p-checkbox-box.p-highlight {
    background-color: var(--green-600) !important;
    border-color: var(--green-600) !important;
}

.checkbox__green .p-checkbox-box.p-highlight .p-checkbox-icon {
    color: white !important;
}

.checkbox__green .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
    border-color: var(--green-600) !important;
    background: var(--green-600) !important;
    color: #ffffff;
}

.checkbox__green.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: var(--green-600);

}

.checkbox__green.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px var(--green-100);
    border-color: var(--green-600) !important;
}


.checkbox-wrapper-4 {
    font-size: 0px;

    .cbx {
      -webkit-user-select: none;
      user-select: none;
      cursor: pointer;
      padding:11px 8px;
      border-radius: 6px;
      overflow: hidden;
      display: inline-block;
      transition: all 0.2s ease;   
      margin: 0;
      background: rgba(0,119,255,0.06);
      //height: 40px;

    
      &:not(:last-child) {
        margin-right: 6px;
      }
  
      &:hover {
        background: rgba(0,119,255,0.1);
  
        span:first-child {
          border-color: var(--darkblue-600);
        }
      }
  
      span {
        float: left;
        vertical-align: middle;
        transform: translate3d(0, 0, 0);
        align-self: center;

  
        &:first-child {
          position: relative;
          width: 18px;
          height: 18px;
          border-radius: 4px;
          transform: scale(1);
          border: 1px solid var(--darkblue-600);
          transition: all 0.2s ease;
          box-shadow: 0 1px 1px rgba(0, 16, 75, 0.05);
  
          svg {
            position: absolute;
            top: 3px;
            left: 2px;
            fill: none;
            stroke: #fff;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-dasharray: 16px;
            stroke-dashoffset: 16px;
            transition: all 0.3s ease;
            transition-delay: 0.1s;
            transform: translate3d(0, 0, 0);
          }
        }
  
        &:last-child {
          padding-left: 8px;
          line-height: 18px;
        }
      }
    }
  
    .inp-cbx {
      position: absolute;
      visibility: hidden;
  
      &:checked + .cbx span:first-child {
        background: var(--darkblue-600);
        border-color: var(--darkblue-600);
        animation: wave-4 0.4s ease;
  
        svg {
          stroke-dashoffset: 0;
        }
      }

      //Disabled

        &:disabled + .cbx span:first-child {
            background: var(--gray-200);
            border-color: var(--gray-300);
            animation: wave-4 0.4s ease;
    
            svg {
            stroke: var(--gray-400);
            }
        }

        &:disabled + .cbx {
           cursor: not-allowed;
           background-color: var(--gray-100);

           &:hover {
            background: var(--gray-200);
           }
        }
    }
  
    .inline-svg {
      position: absolute;
      width: 0;
      height: 0;
      pointer-events: none;
      user-select: none;
    }
  
    @media screen and (max-width: 640px) {
      .cbx {
        width: 100%;
        display: inline-block;
      }
    }
  }
  
  @keyframes wave-4 {
    50% {
      transform: scale(0.9);
    }
  }

  