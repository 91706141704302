@import '../../../root.scss';

.newPasswordForm{
  display: grid;
  grid-template: 1fr auto / 1fr 1fr;
  width: 80% !important;
  margin: 0 auto;
}

.buttonContainer{
  grid-row-start: 2;
  grid-column-start: 1;
  grid-column-end: 3;
}

.formGroupVertical{
  width: 100%;
}

.inputContainer{
  width: 90% !important;

  .button_show_hide {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    position: relative;
    margin: 10px;
    color: $Blue-dark;
  }
}

.form_cont{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.parametros_cont{
  color: $Blue-dark
}

.parametros-list{
  background-color: $Blue-dark;
  border-radius: 10px;
  padding: 10px;
  color: white;

  li{
    margin: 0 auto 5px;
  }
}

@media only screen and ( max-width: 790px ) {
  .newPasswordForm{
    display: flex;
    flex-direction: column;
    // grid-template: auto auto auto /  1fr;
    width: 80% !important;
    margin: 0 auto;
  }

  .parametros-list{
    margin: 10px 0;
  }

  .form_cont{
    width: 100%;
  }
}
