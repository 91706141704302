@import '../../../root.scss';

.addButtonContainer{
  display: grid;
  grid-template: 1fr / 1fr 8fr;
  width: 10%;
  // border: 2px solid $Blue-dark-soft;
  border-radius: 15px;

  // #selectFile{
  //   display: flex;
  //   align-items: center;
  //   grid-column: 1 / 2;
  //   margin: 0;
  // }

  
}

.selectFile{
  display: flex;
  opacity: 0;
  align-items: center;
  height: 100%;
  border-radius: 15px;
  z-index: -1;


  // input#file-upload-button{
  //   background-color: $Blue-dark-soft;
  // }

  label{
    background-color: green;
  }
}


#addButton{
  grid-column: 1 / 3;
  background-color: $Blue-dark-soft;
  z-index: 1;

  color: $White;
  // width: 100px;
  height: 35px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  // border-top-right-radius: 12px;
  // border-bottom-right-radius: 12px;
  border-radius: 10px;
  font-weight: 700;
  margin: 0;

  &:hover{
  background-color: $Blue-dark;

    // color: $Blue-dark;
  }

}

.fileRow{
  font-size: 14px;
}

.deleteButton{
  background-color: $Red;
  height: 20px;
  color: white;
  width: 80px;
  border: none;
  border-radius: 5px;
  font-size: 12px;
}

// .selectFile{
//   display: flex;
//   align-items: center;
//   padding: 0;
// }

