@import '/src/root.scss';

.item_dofa{
  &:hover{
    .btn-container{
      opacity: 1;
      transition: 0.2s;
    }
  }
}

.btn-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 23px;
  width: 23px;
  margin: 0 8px;
  opacity: 0;
  border-radius: 50%;
  background-color: rgb(230, 230, 230);
  cursor: pointer;

  &:hover{
    background-color: rgba(218, 33, 33, 0.722);
  }

}

.MuiSlider-mark{
  height: 10px !important;
}

.buttonContainerDOFA{
  position: sticky;
  background-color: white;
  width: 60%;
  height: 70px;
  top : 64.3px;
  margin-top: 1%;
  padding: 1% 0 0 1% ;
  z-index: 2;
  display: flex;
}

.bodyGestorDofa{
  // background-color: red;
  margin: 2%;
  padding: 5px 0 0;
  display: grid;
  grid-template: auto / 2fr 1.2fr;
  height: auto;

  & div{
    border-radius: 15px;
    
  }



  .contenedorAcordeonInputsDofa{
    // background-color: yellow;
    padding: 0;
    
    #AcordeionInputsDofa{
      width: 95%;
      margin:  0;
    }

    // Para eliminar los estilos por defecto del range input
    input[type='range'], 
    input[type='range']::-webkit-slider-thumb {-webkit-appearance: none;}

    

    input[type=range]::-webkit-slider-runnable-track {
      background-color: $Green;
      height: 3px;
    }

    .inputRangeFortalezas{

      &::-webkit-slider-runnable-track {
        background-color: $Green !important;
        height: 3px;
      }

      &::-moz-range-track {
        background-color: $Green !important;
        height: 3px;
      }

      &::-moz-range-thumb {
        background-color: white;
        width: 12px;
        height: 12px;
        border: 3px solid $Green;
        border-radius: 50%;
        cursor: pointer;
      }

      // , &::-moz-range-thumb, &::-ms-thumb

      &::-webkit-slider-thumb {
        background-color: white;
        width: 20px;
        height: 20px;
        border: 3px solid $Green;
        border-radius: 50%;
        margin-top: -9px;
        cursor: pointer;
      }
    }

    .inputRangeAmenazas{
      &::-webkit-slider-runnable-track {
        background-color: rgb(255, 102, 0) !important;
        height: 3px;
      }

      &::-moz-range-track {
        background-color: rgb(255, 102, 0) !important;
        height: 3px;
      }

      &::-moz-range-thumb {
        background-color: white;
        width: 12px;
        height: 12px;
        border: 3px solid rgb(255, 102, 0);
        border-radius: 50%;
        cursor: pointer;
      }


      &::-webkit-slider-thumb{
        background-color: white;
        width: 20px;
        height: 20px;
        border: 3px solid rgb(255, 102, 0);
        border-radius: 50%;
        cursor: pointer;
        margin-top: -9px;
      }
    }

    .inputRangeOportunidades{
      &::-webkit-slider-runnable-track {
        background-color: $celeste !important;
        height: 3px;
      }

      &::-moz-range-track {
        background-color: $celeste !important;
        height: 3px;
      }

      &::-moz-range-thumb {
        background-color: white;
        width: 12px;
        height: 12px;
        border: 3px solid $celeste;
        border-radius: 50%;
        cursor: pointer;
      }

      &::-webkit-slider-thumb{
        background-color: white;
        width: 20px;
        height: 20px;
        border: 3px solid $celeste;
        border-radius: 50%;
        cursor: pointer;
        margin-top: -9px;
      }
    }

    .inputRangeDebilidades{
      &::-webkit-slider-runnable-track {
        background-color: $Yellow !important;
        height: 3px;
      }

      &::-moz-range-track {
        background-color: $Yellow !important;
        height: 3px;
      }

      &::-moz-range-thumb {
        background-color: white;
        width: 12px;
        height: 12px;
        border: 3px solid $Yellow;
        border-radius: 50%;
        cursor: pointer;
      }

      &::-webkit-slider-thumb{
        background-color: white;
        width: 20px;
        height: 20px;
        border: 3px solid $Yellow;
        border-radius: 50%;
        cursor: pointer;
        margin-top: -9px;
      }
    }
    // input[type='range'], input[type='range']::-webkit-slider-runnable-track, 

    // input[type='range']::-webkit-slider-thumb {-webkit-appearance: none;}

    // // Editar thumb del range html
    // // input[type=range]::-webkit-slider-thumb{
    // //   margin-top: -9px;
    // //   background-color: red;
    // // }



    // // input[type='range']::-webkit-slider-thumb { }
    // // input[type=range]::-ms-thumb{ }

    // input[type='range']::-webkit-slider-thumb 
    // {
    //   background-color: white;
    //   width: 20px;
    //   height: 20px;
    //   border: 3px solid $Blue-dark;
    //   border-radius: 50%;
    //   margin-top: -9px;
    // }

    // input[type=range]::-webkit-slider-runnable-track {
    //   background-color:$Blue-dark-soft;
    //   height: 3px;
    // }

    .inputRange{
      width: 100%;
    }
    
    .slider-wrapper {
      background-color: red;
      display: inline-block;
      width: 20px;
      height: 150px;
      padding: 0;
    }

    .observacionesFormGroupAuditorias{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 95%;
      margin: 20px 0 auto;

      .observacionesLabel{
        margin: 0;
      }

      .textAreaContainer{

        width: 100%;

        .observacionesTextArea{
          min-height: 100px;
        }
        
      }

    }

  }

  .datalistDOFANegativos{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: -11px;
    font-size: 0.7rem;
    background-color: white;
    color: rgb(139, 139, 139);

    option{
      margin-right: 15px;

    }
  }

  .datalistDOFA{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: -11px;
    font-size: 0.7rem;
    background-color: white;
    color: rgb(139, 139, 139);

    option{
      margin-left: 5px;
    }
  }

  .seccionDofaAcordeon{
    height: 50px;
    background: whitesmoke;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p{
      margin: 0;
    }

    


    &:hover{
      .buttonAnadirItem{
        display: block;
      }
    }

    .buttonAnadirItem{
      display: none;
      background-color: $White-dark;
      font-weight: 700;
      width: 100px;
      border-radius: 10px;
      height: 35px;
      
      &:hover{
        background-color: $Blue-dark;
        color: white;
        transition: 0.1s;

      }
    }

  }

  .contenedorAcordeonPuntuacionDofa{
    -webkit-box-shadow: 0px 5px 17px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 5px 17px -6px rgba(0,0,0,0.75);
    box-shadow: 0px 5px 17px -6px rgba(0,0,0,0.75);
    min-height: 30%;
    padding: 2%;

    display: grid;
    grid-template: 400px 400px / 1fr;

    .headerPesos{
      background-color: $Blue-soft;
      height: 35px;
      padding: 0 1%;
      border-radius: 4px 4px 0 0 !important;
    }

    

    .pesosDofaItem{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgb(214, 214, 214);
      border-radius: 0;
      margin: 10px 0 ;

      p{
        margin: 0;
      }
    }
  }

}

@media  screen and (max-width: 978px) {
  .bodyGestorDofa{
    // background-color: green;
    grid-template: auto 1fr / 1fr;
    
    .contenedorAcordeonInputsDofa{
      padding-bottom: 20px;
      // background-color: yellow;
      padding: 0;
      
      #AcordeionInputsDofa{
        width: 95%;
        margin:  auto;
      }
    }

  }
}

@media  screen and (max-width: 786px) {
  .bodyGestorDofa{
    // background-color: blue;
  }

  .contenedorAcordeonInputsDofa{
    #AcordeionInputsDofa{
      width: 95%;
      margin:  auto;
    }

  }

}