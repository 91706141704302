$chart-text-size: 0.75rem; // text-xs
$chart-bg-color: #fff; // background color
$chart-border-color: rgba(0, 0, 0, 0.5); // border color with opacity

:root{
    --color-bg: #f9fafb;
    --color-border: #e5e7eb;
    --color-foreground: var(--gray-700);
    --color-muted: #f3f4f6;
    --color-muted-foreground: var(--gray-500);
}

.chart-container {
  display: flex;
  aspect-ratio: 16 / 9;
  justify-content: center;
  font-size: $chart-text-size;

  .recharts-cartesian-axis-tick_text {
    fill: var(--color-muted-foreground);
  }

  .recharts-cartesian-grid_line {
    stroke: $chart-border-color;
  }

  .recharts-curve.recharts-tooltip-cursor {
    stroke: $chart-border-color;
  }

  .recharts-dot {
    stroke: transparent;
  }

  .recharts-layer {
    outline: none;
  }

  .recharts-polar-grid {
    stroke: $chart-border-color;
  }

  .recharts-radial-bar-background-sector {
    fill: var(--color-muted);
  }

  .recharts-rectangle.recharts-tooltip-cursor {
    fill: var(--color-muted);
  }

  .recharts-reference-line {
    stroke: $chart-border-color;
  }

  .recharts-sector {
    stroke: transparent;
  }

  .recharts-surface {
    outline: none;
  }
}

.tooltip-container {
  display: grid;
  min-width: 8rem;
  gap: 8px;
  border-radius: 0.5rem;
  background-color: $chart-bg-color;
  padding: 6px 12px;
  font-size: 0.75rem; // text-xs
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);

  .tooltip-label {
    font-weight: 700;
  }

  .tooltip-content {
    display: grid;
    gap: 4px;

    .tooltip-item {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      &-dot {
        align-items: center;
      }

      &-line {
        width: 1px;
        background-color: var(--color-bg);
      }

      &-dashed {
        border: 1.5px dashed var(--color-border);
        background-color: transparent;
      }

      .tooltip-indicator {
        height: 0.625rem; // dot size
        width: 0.625rem; // dot size
        border-radius: 0.125rem;
        border: 1px solid var(--color-border);
        background-color: var(--color-bg);

        &-line {
          height: 1px;
        }

        &-dashed {
          border: 1.5px dashed var(--color-border);
          background-color: transparent;
        }
      }

      .tooltip-item-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &-nest {
          align-items: flex-end;
        }

        &-standard {
          align-items: center;
        }
      }

      .tooltip-item-label {
        display: grid;
        gap: 0.5rem;

        .tooltip-item-name {
          color: var(--color-muted-foreground);
        }
      }

      .tooltip-item-value {
        font-family: monospace;
        font-weight: 500;
        color: var(--color-foreground);
      }
    }
  }
}

.legend-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  
  &-top {
    padding-bottom: 1rem;
  }

  &-bottom {
    padding-top: 1rem;
  }
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .legend-color-box {
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 0.125rem;
    background-color: var(--color-bg);
  }
}
