.card {

    &__header {
        padding-bottom: 28px;
        gap: 0;
    }

    &__title {
        font-size: 1.5rem;
        font-weight: bold;
    }

    &__description {
        font-size: 1rem;
        color: var(--color-muted-foreground);
    }

    &__content {
        display: grid;
        gap: 1rem;
    }

    &__item {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    &__stat {
        display: flex;
        align-items: baseline;
        gap: 0.25rem;
        font-size: 2rem;
        font-weight: bold;
        line-height: 1;
        font-variant-numeric: tabular-nums;

        &__unit {
            font-size: 0.875rem;
            font-weight: normal;
            color: var(--color-muted-foreground);
        }
    }


    &__chart__container {
        aspect-ratio: auto 32 / 3;
        width: 100%;

    }
}