@import '../../../root.scss';


#rowAreasDiagnostico{
    display: grid;
    grid-template: 1fr / 1fr 3fr;
    // justify-content: space-between;

    #sidebar-wrapper{
        // background-color: green;
    }

    #page-content-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        grid-column: 2;
        // background-color: blue;
    }

}

.nav-items {
    // background-color: #213265;
    min-height: 500px;
    border-radius: 10px;
    color: $Blue-dark;
}

.aside__menu {
  .accordion {
    border-radius: 1rem 0 0 .75rem;
    padding: 1.5rem 1.5rem;
    border-right: 1px solid var(--gray-200);
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }

  .card {
    background-color: transparent;
  }
}



.nav-items a {
    color: $Blue-dark;
    // color: white;
    margin: 0.75rem 0.25rem;
}

.audit-container {
    margin-top: 0.333333333em;
}

.nav-item {
    color: white;
}



.nav-items a.item-a.nav-link:hover {
    background-color: $White-dark;
    color: $Blue-dark;

    // color: white;
    // margin: 0;
    transition: 0.1s;

}

.nav-items a.item-a.nav-link {
    margin: 5px 0 ;
    border-radius: 15px;
    text-align: start;
}

.nav-items a.item-a.nav-link.active {
    color: white;
    background-color: $Blue-dark;
}

.bodyWidgets{
    // background-color: green;
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;

    .widget{

        height: 80px;
        min-width: 160px;
        margin: 10px 15px;
        border-radius: 15px;
        cursor: pointer;
        font-size: 1rem;
        // border-bottom: 8px solid #213265;
        display: grid;
        grid-template: 1fr / 2fr 0.3fr 1fr;
        justify-items: space-between;
        align-items: center;
        justify-content: center;
        text-align: start;
        transition: 0.3s;
        padding: 0 0.8%;
        // font-size: 1.2rem;
        font-weight: 700;
        color: $Blue-dark;


        p{
            margin: 0;
        }

        .widgetPorcentaje{
            grid-column-start: 3;
            // font-weight: 800;
            font-size: 1rem;
        }

        &:hover{
            transform: scale(1.1);
        }
    }
}
.table-forms-static {
    -webkit-transform: rotateX(0deg);
    overflow-y: auto;
}

td.action-list {
    display: inline-flex;
}

.table-audit-container {
    width: 97%;
}

.columnaComponentes{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    max-height: 200px;
    width: 220px;
    overflow-y: auto;
}

.sistemaSegItemToggle{
    background-color: rgba(255, 255, 255, 0);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: $Blue-dark;
    min-height: 35px;
    text-align: start;
    transition: 0.2s;
    font-weight: 700;

    &:hover{
        background-color: $White-dark-soft;
        border-radius: 10px;

    }

    &:focus{
        background-color: $Blue-dark;
        color: white;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

    }

    p{
        margin: 0;
        padding: 0 5px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.sistemaSegItemCollapse{
    background-color: #F6F6F6;
    text-align: start;

}

.componenteItem{
    font-size: 0.8rem;
    min-height: 35px;
    border-radius: 10px;
    // background-color: #CECECE;
    padding: 3px 5px;
    color: var(--gray-700);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: start;
    width: 90%;
    margin: 5px auto;
    transition: 0.2s;
    font-weight: 700;
    cursor: pointer;


    &:hover{
        background-color: $Yellow;
        color: white;

    }
}

.table-audit-container .table-responsive {
    max-height: 280px;
    overflow-y: scroll;
}

textarea.audit-form-textarea-obj.form-control {
    width: 100%;
    height: auto;
    max-height: 132px;
    border: none;
    margin: 0.5em;
    max-width: 700px;
}

svg.MuiSvgIcon-root.icon-gray {
    cursor: pointer;
    color: darkgray;
    transition: 0.15s;

    &:hover{
        transform: scale(1.05);
    }

    &:active{
        transform: scale(0.95);
    }
}

svg.MuiSvgIcon-root.icon-gray.minus:hover {
    color: red;
}

svg.MuiSvgIcon-root.icon-gray.add:hover {
    color: $Green;
}

svg.MuiSvgIcon-root.icon-gray.edit:hover {
    color: #E4A731;
}

.row-content.row {
    margin-top: 3em;
}

.table-audit-container h4 {
    text-align: left;
}

.table-forms-static.table .form-group.col-lg-12 {
    display: block;
}
