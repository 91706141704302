.lista {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        transition: background-color 0.3s;

        .item__left {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: 4px;

            .item__title {
                font-size: 18px;
                font-weight: 600;
                line-height: 1;
            }

            .item__description {
                font-size: 0.875rem;
                color: var(--gray-500);
            }
        }

        .item__right {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }


}