@import '../../../../../../root.scss';


.cardBodyInfoEstudio{
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  // background-color: blue;

  .titleCard{
    display: flex;
    justify-content: flex-start;
    text-align: justify;
  }
}

#formInfoEstudioInteligencia{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  // background-color: green;
  width: 100%;
  margin: auto;
  padding: 0;

  .filasPar{
    display: flex;
    width: 85%;
  }

  // .filasObservaciones{
  //   display: flex;
  //   width: 100%;
  // }

  .inputGroup{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;

    .form-label{
      font-weight: 700;
    }
    
    .inputContainer{
      min-width: 90%;

      .form-control{
        background-color: $White-dark-soft;
        border: none;
        border-radius: 8px;

      }

    }
    



  }

  .observaciones{
    min-height: 100px;
  }

  .inputGroupObservaciones{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .form-label{
      font-weight: 700;
    }
    
    .inputContainer{
      min-width: 95%;

      .form-control{
        background-color: $White-dark-soft;
        border: none;
        border-radius: 8px;

      }
    }



  }
  
}