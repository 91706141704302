/* ==========================================================================
    Flexbox and Grid
   ========================================================================== */

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}


/* Grow and shrink */

.flex-1 {
  flex: 1 1 0%;
}

/* Flex alignment */

/* Justify content */

.justify-start {
  justify-content: start;
}

.justify-end {
  justify-content: end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

/* Justify items */

.justify-items-start {
  justify-items: start;
}

.justify-items-end {
  justify-items: end;
}

.justify-items-center {
  justify-items: center;
}

/* Align items */

.items-start {
  align-items: start;
}

.items-end {
  align-items: end;
}

.items-center {
  align-items: center;
}


/* Flex wrap */

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}


/* ==========================================================================
    Sizing
   ========================================================================== */

/* Width
   ========================================================================== */

.w-full {
  width: 100%;
}

.w-70 {
  width: 70%;
}

/* Min-Width
   ========================================================================== */

.min-w-16 {
  min-width: 4rem;
}

/* ==========================================================================
    Borders
   ========================================================================== */

/* Border radius
   ========================================================================== */

.rounded {
  border-radius: 0.25rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

/* ==========================================================================
    Effects
   ========================================================================== */

/* Box shadow
   ========================================================================== */

.shadow-1 {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.shadow-none {
  box-shadow: none;
}

/* ==========================================================================
    Typography
   ========================================================================== */

/* Font size
   ========================================================================== */

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

/* Font weight
   ========================================================================== */

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-black {
  font-weight: 900;
}

/* Text truncate
   ========================================================================== */

.truncate-text {
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* Text color
   ========================================================================== */

.text-white {
  color: #fff;
}

@for $i from 1 through 9 {
  .text-gray-#{$i}00 {
    color: var(--gray-#{$i}00);
  }
}

.text-darkblue-600 {
  color: var(--darkblue-600);
}

/* ==========================================================================
    Backgrounds
   ========================================================================== */

.bg-transparent {
  background-color: transparent;
}

.bg-white {
  background-color: #fff;
}

/* ==========================================================================
    Fixes
   ========================================================================== */

.fix-inputs {
  .formGroupVertical, .form-group .form-label {
    margin: 0;
  }
  .form-group .form-label {
    font-weight: bold;
  }
}

/* ==========================================================================
    Layout
   ========================================================================== */

.overflow-visible {
  overflow: visible;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-auto {
  overflow: auto;
}

.m-0-auto {
  margin: 0 auto;
}

.mr-auto {
  margin-right: auto;
}

.mt-10 {
  margin-top: 10px;
}

.titulo__panel {
  font-size: 2rem;
  font-weight: 900;
  color: var(--darkblue-600);
  margin-bottom: 0;
}
