
.icon__button {
  position: sticky;
  text-align: right;
  bottom: 0;
  button {
    margin: 0;
    padding: 0;
    border: none;
    background-color: white;
    color: var(--gray-500);
    border: 1px solid var(--gray-300);
    padding: 4px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      transform: scale(1.1);
      color: var(--gray-600);
    }
  }
  .active {
    color: var(--blue-600);
  }
}

.icon__button--absolute {
  position: absolute;
  right: 24px;
  bottom: 24px;
}
