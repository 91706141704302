@import '/src/root.scss';


.mensajeTablaSinInfo{
  // background-color: green;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 600;
  // text-align: center;
  color: rgb(209, 209, 209);
  border-radius: 15px;
  border: 5px dashed rgb(221, 221, 221);
}

.headerRegistro{
  background-color: var(--gray-100);
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 16px;
  border-radius: 10px;

  p{
    margin: 0 !important ;
    font-size: 2.5rem;
    color: $Blue-dark;
    font-weight: 800;
  }
}

.inputGroup.form-group.col-lg-6 {
  display: block;
  margin-top: 0.5em;
}
