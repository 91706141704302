@import '../../../../root.scss';


.addIconContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-control{
  width: 90%;
}



.formAddEntrevistaContainer{
  display: grid;
  grid-template: 0.8fr 0.8fr auto  0.5fr / 1fr 1fr ;
  width: 90%;
  padding: 0;
  margin: 0 auto;

  #basicFormA{
    grid-row-start: 1;
  }
  #basicFormB{
    grid-column-start: 2;
    grid-row-start: 1;
  }
  #basicFormC{
    grid-row-start: 2;
  }
  #basicFormD{
    grid-column-start: 2;
    grid-row-start: 2;
  }
  #basicFormE{
    width: 97.5%;
    grid-column-start: 1;
    grid-column-end: -1;
    grid-row-start: 3;

    .form-control{
      width: 100%;
    }
  }

  .InputsSeguimientosContainer{
    display: flex;
    width: 100%;

    div{
      width: 100%;
    }
  }

  .formItem{
    width: 95%;
  }

  #solicitudAddBeneficiario, #respuestaAddBeneficiario, #observacionesAddBeneficiario{
    display: flex;
    flex-direction: column ;
    grid-column-start: 1;

    #observacionesFormGroup{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      #observacionesTextArea{
        width: 97.5%;
        min-height: 100px;
      }
    }

  }
  #solicitudAddBeneficiario{
    grid-row-start: 2;

  }

  #respuestaAddBeneficiario{
    grid-row-start: 3;

  }

  #observacionesAddBeneficiario{
    grid-row-start: 4;
    grid-column-end: 3;
  }

  .textAreaFormGroupSeguimientos{
    display: flex;
    flex-direction: column ;
    grid-column-start: 1;
    grid-column-end: 3;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      .seguimientosTextArea{
        width: 97.5%;
        min-height: 100px;
      }
    }
  }

  #confirmButtonAddBeneficiarioContainer{
    display: flex;
    justify-content: flex-end;
    grid-row-start: 5;
    grid-column-start: 1;
    grid-column-end: -1;

  }
  
  
    @media screen and (max-width: 768px) {

      // width: 100%;

      .InputsSeguimientosContainer{
        display: flex;
        flex-direction: column;
      }
 
  
      .formItem{
        width: 100%;

        .form-group{
          display: flex;
          align-items: flex-start;
          flex-direction: column;
        
          .namesContainer{
            flex-direction: column;
          }

        }

      }
      
    }
}




.form-control{
  font-size: 16px;
}

.buttonBeneficiarios{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 5px;
  border-radius: 5px;
  width: 70px;
  height: 25px;
  padding: 0 2%;
  font-size: 12px;
  font-weight: 600;
  // background-color: $White;

}

.deleteBeneficiarios{

  border: 2px solid $Red;
  color: $Red;

  &:hover{
    background-color: $Red;
    color: $White;
  }

}

.editBeneficiarios{
  border: 2px solid $Yellow;
  color: $Yellow;

  &:hover{
    background-color: $Yellow;
    color: $White;
  }
}

.saveBeneficiarios{

  border: 2px solid $Blue;
  color: $Blue;

  &:hover{
    background-color: $Blue;
    color: $White;
  }

}

.formEditBeneficiarioContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 90%;
  margin: 2% 5%;

  select{
    background-color: $White;
    width: 40%;
    border-radius: 10px;
    padding: 5px;
    font-size: 14px; 
    border: 1px solid $Gray-fonts;
    height: 30px;
  
  }

  input{
    width: 96%;
    border-radius: 10px;
    padding: 5px;
    margin: 5px 0;
    border: 1px solid $Gray-fonts;
    outline: $Blue-dark-soft;
    height: 30px;


  }




  .nombresContainerEditBeneficiario{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin: 2% 0;
  }

}

.formEditBeneficiarioObservacionesContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin: 0 5%;

  #inputObservacionesBeneficiarios{
    border-radius: 10px;
    border: 1px solid $Gray-fonts;
    width: 100%;
    min-height: 100px;
    padding: 10px;
    resize: both;
    outline: $Blue-dark-soft;
  }

}

.nombresContenedor{
  display: flex;
  width: 100%;
}