@import '/src/root.scss';


.modalCard{
    position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $BackgroundModales;
  backdrop-filter: blur(4px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;

  
}

.closeButtonContainerAud{
    // background-color: green;
    display: flex;
    position: relative;
    top: 0;
    justify-content: center;
    align-items: center;
    right: 0;
    cursor: pointer;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    // height: 20px;

    &:hover{
      background-color: $Gray-fonts;
      color: $White;
    }
  }

.fade.modal-backdrop.fade.modal-audit.show.show {
    opacity: 1;
    background-color: rgba(22, 45, 68, 0.8);
}

.mdl-aud{
    padding: 20%;
}

.fade.mdl-aud.modal.show {
    opacity: 1;
}

.mdl-aud .modal-dialog.modal-md.modal-dialog-scrollable {
    max-height: 600px;
}

.mdl-aud .modal-content {
    background-color: #f8f8f8e7;
    z-index: 2;
}

.mdl-aud .deniedButton {
    background-color: #CC1A3A;
    color: #ffffff;
    border-radius: 15px;
    height: 35px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 15px;
    box-shadow: none;
    border: 1px solid #CC1A3A;
    font-weight: 800;
}

.mdl-aud .confirmButton {
    background-color: #213265;
    color: #ffffff;
    height: 35px;
    border-radius: 15px;
    margin: 0 15px;
}

.mdl-aud .confirmButton:hover {
    border: 2px solid #213265;
    background-color: #8b97a7;
    color: #213265;
    font-weight: 800;
    box-shadow: none;
}

.mdl-aud .deniedButton:hover {
    border: 2px solid #213265;
    background-color: #8b97a7;
    color: #213265;
    font-weight: 800;
}

.button-modal-container {
    text-align: center;
}

.form-modal-audit{
    background-color: #E6E8EA;
    border-radius: 18px;
}

.form-modal-audit .form-label {
    margin: 0;
}

.mdl-aud .modal-body::-webkit-scrollbar {
    width: 15px;     /* Tamaño del scroll en vertical */
}

.mdl-aud .modal-body::-webkit-scrollbar-thumb {
    width: 5px;
    margin: 0 auto;
    background: #8d8d8d;
    border-radius: 4px;
}

.mdl-aud .modal-body::-webkit-scrollbar-thumb:hover {
    background: #2e4257;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.mdl-aud .modal-body::-webkit-scrollbar-thumb:active {
    background-color: #19334e;
}

.mdl-aud .modal-body::-webkit-scrollbar-track {
    width: 100px;
    background: #ffffff;
    border-radius: 4px;
}

.mdl-aud .modal-body::-webkit-scrollbar-track:hover,
.mdl-aud .modal-body::-webkit-scrollbar-track:active {
  background: #ffffff;
}

.mdl-audit-hdr.modal-header {
    background-color: #e6e8ea;
    border: none;
    display: block;
}

.mdl-audit-ftr.modal-footer {
    display: block;
}

.mdl-aud .modal-body {
    width: 95%;
    margin: 0 auto;
    overflow: auto;
    height: 400px;
}