.check__list {
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    border-radius: 0.75rem;

    ul {
        padding: 0;
        margin: 0;

        li {
            position: relative;
            list-style-type: none;
            padding-left: 1rem;
            margin-bottom: 0.5rem;
        }

        li:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0px;
            width: 5px;
            height: 11px;
            border-width: 0 2px 2px 0;
            border-style: solid;
            border-color: var(--green-500);
            transform-origin: bottom left;
            transform: rotate(45deg);
        }

        //El ultimo elemento no tendra margen
        li:last-child {
            margin-bottom: 0;
        }
    }
}

.check__list--scroll {
    max-height: 200px;
    overflow-y: scroll; 
  
    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}