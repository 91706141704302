.contenedor__condiciones{
  background-color: #fafafa;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  height: fit-content;

  .condiciones__header{
    padding: 8px;
    border-bottom: 1px solid #e0e0e0;
    font-size: 14px;
    color: #424242;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #eeeeee;
    border-radius: 10px 10px 0 0;
  }

  .condiciones__item{
    padding: 4px 8px;
    border-bottom: 1px solid #e0e0e0;
    font-size: 14px;
    color: #616161;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .condicion__text--valido{
      color: #00a20B;
    }

    .condicion__text--invalido{
      color: #CC1A3A;
    }

    .icono__valido{
      color: #00a20B;
    }

    .icono__invalido{
      color: #CC1A3A;
    }
  }

  .condiciones__item:last-child{
    border-bottom: none;
  }

}
