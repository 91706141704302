@import '/src/root.scss';

.container-cards {
    // border-radius: 20px;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
}

.container-scroll {
    display: grid;
    grid-template: 1fr 1fr /1fr 1fr;
    overflow-y: auto;
}

.card-panel {
    margin-bottom: 2em;
}

.corteTiempoPanelPresidencia{
    color: $Blue-dark-soft;
    font-size: 1.1rem;
    margin: 0;
}

.titlePorcentaje{
    color: $Blue-dark-soft;
    // background-color: red;
    margin: -20px 0 5px 0;
    text-align: center;
    font-weight: 700;
}