@import '../../root.scss';

#navbarContainer{

  // padding: 0 2% ;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  // z-index:5555555555555555000;


  img{
    width:82px;
    height: 35px;
    margin: auto 3%;

    &#notificationIcon{
      width: 1.2em;
      height: 1.2em;
      outline: none;
    }
  }

  #navbarMenu{
    width: auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    margin: 0 20px;
    .navbarButton{
      padding: 5px;
      border: none;
      background-color: $White;
      border-radius: 50%;
    }

    #btnExit{

      &:hover{
        background-color: rgba(219, 129, 18, 0.627);
        color: white;
      }
    }

    .navbarButton:hover{
      background-color: $White-dark-soft;
      transition: 0.2s;

    }

    .overlayTrigger{
      z-index: 1;
      position: absolute;
    }


  }

}

// #button-tooltip{
//   top: 70px;
//   right: 70px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// }

.tooltip-inner{
  border-radius: 10px;
  padding: 0;
  border: 1px solid $Blue-dark-soft;
  color: $Blue-dark;
  height: auto;
  width: 200px;
  font-size: 0.9rem;
  background-color: $White;


  button{
    padding: 5px;
    width: 100%;
    height: auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: $Blue-dark-soft;
    color: $White;

    &:hover{
      background-color: $Blue-dark;
      color: $White;
    }

  }


}

.notificationPopup{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  border-bottom: 1px solid $Gray-fonts;
  padding: 4%;

  &:hover{
    background-color: $White-dark;
  }

  span{
    font-weight: 800;

  }

  p{
    margin: 0 ;
  }
}

.navbarButton{
  color: $Gray-fonts;

}


/* ==========================================================================
   Estilos para la seleccion de idioma
   ========================================================================== */

.dropdown-idioma {
    margin: 0rem;
    margin-right: 16px;
    position: relative;
    cursor: pointer;

    .dropdownButton {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .dropdown__toggle span {
        font-size: 16px;
        font-weight: bold;
        color: #333;
    }

    .dropdown__menu {
        position: absolute;
        top: 40px;
        left: 0px;
        width: 200px;
        max-height: 240px;
        overflow-y: auto;
        background-color: #fff;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        z-index: 1;
        border-radius: 10px;

    }

    .dropdown__menu__item {
        display: block;
        padding: 8px 16px;
        font-size: 14px;
        color: #333;
        cursor: pointer;
        transition: background-color 0.2s ease-in-out;
    }

    .dropdown__menu__item:hover {
        background-color: #f0f0f0;
    }

    .dropdown__selectedOption {
      color: #333;
    }

    #dropdown__image {
      width: 32px;
      height: auto;
      margin-right: 8px;
    }
}
