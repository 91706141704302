@import '/src/root.scss';

#bodyWidgetsDetalleDep{
  display: flex;

  .colWidgetBar{
    height: auto !important;
  
    #tituloDep{
      color: $Blue-dark;
      font-weight: 800;
      font-size: 2.3rem;
      padding-right: 5px;
    }

    #corteDep{
      color: $Blue-dark-soft;

    }
  
  }

  #infoGeneralDetalleDep{
    // background-color: red;
    width: 30%;

  }

  #contenedorWidgetsDetalleDep{
    // background-color: blue;
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // overflow-y: auto;
    overflow-x: auto;
    
  }

}

.widgetPr{
  min-width: 180px;
  width: 30%;
  height: 90%;
  min-height: 120px;
  border-radius: 15px;
  margin: 0 10px;
  cursor: pointer; 
  transition: 0.2s;
  border-bottom: 10px solid  green;
  

  &:hover{
    transform: scale(1.05);
    
    // .labelWidget{
    //   color: white;
    // }
  }
}

.contenidoWidget{
  display: grid;
  grid-template: auto auto / 1fr;
  height: 100%;
  align-content: space-between;
  align-items: space-between;

  .cantidadWidget{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 1.4rem;
    font-weight: 700;
    padding: 10px 0 0;
    margin: 0;
  }
  
  .labelWidget{
    grid-row-start: 1;
    font-weight: 600;
    font-size: 1rem;
    min-height: 50px;
    width: 100%;
    color: rgb(69, 69, 69);
  }

  
  
}

#enProceso{
  border-bottom: 10px solid #2C5E99;
  // border-top: 10px solid #2C5E99;

  &:hover{
    background-color: #2C5E99;
    color: $White;
  }
}



#canceladas{
  border-bottom: 10px solid #009688;

  &:hover{
    background-color: #009688;
    color: $White;
  }
}


#diasFinalizados{
  border-bottom: 10px solid #F9CF00;

  &:hover{
    background-color: #F9CF00;
    color: $White;
  }
}

#seccionbtnSeccion{
  display: flex;
  height: 50px;
  justify-content: space-between;

  .modeButton{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48%;
    height: 100%;
    border-radius: 15px;
    background-color: rgb(228, 228, 228);
    font-size: 1.3rem;
    font-weight: 700;
    color: gray;
    cursor: pointer;
    transition: 0.2s;

    &:hover{
      transform: scale(1.03);
    }

    &:active{
      transform: scale(0.97);
    }
  }

  .registros{
    background-color: $Green;
    color: white;
  }

  .vencidos{
    background-color: $Yellow;
    color: white;
  }

}

#columnTiposInforme{
  background-color: rgb(249, 249, 249);
  border-radius: 15px;
  border: none !important;
  padding: 5px 0;
}

.tipoInforme{
  // background-color: red !important;
  min-height: 35px;
  margin-bottom: 5px;
  font-size: 1rem;
  transition: 0.2s;
}

#seccionDataInforme{
  min-height: 30vh;
}

#contenedorGraficasFiltro{
  // background-color: blue;
  width: 100%;
  padding: 2%;
}

.modeRegistros{
  background-color: rgba(132, 132, 132, 0.433) !important;
}

.modeVencidos{
  background-color: rgba(238, 170, 22, 0.486) !important;
}

.contenedorButtonFiltro{
  // background-color: red;
  display: flex;
  align-items: center;
  justify-content: flex-end;

}