@import '../../root.scss';

#menuGerenciaContainer{
  border-radius: .75rem;
  margin: 8px 16px;
  z-index: 1;
  // overflow: hidden;
  border: 1px solid var(--gray-200);
}

#linkDoc{
  background-color: $White-dark-soft;
  padding-left: 5%;
  display: flex;
  align-items: center;
  min-height: 35px;
  border-bottom: 1px solid $Blue-soft;
  text-decoration: none;
  color: $Blue-dark;

  &:hover{
    color: $Blue-dark;
    background-color: $White-dark;
  }

  .nameDoc{
    margin: 0;
    // background-color: red;
    width: 90%;
    white-space: initial;
    height: auto;
    &:hover{
      color: #707070;
    }
  }

  p{
    margin: 0;
  }
}

#linkDocA{
  background-color: $White-dark-soft;
  padding-left: 5%;
  display: flex;
  align-items: center;
  min-height: 35px;
  border-bottom: 1px solid $Blue-soft;
  text-decoration: none;
  color: $Blue-dark;

  &:hover{
    color: $Blue-dark;
    background-color: $White-dark;
  }

  .nameDoc{
    margin: 0;
    // background-color: red;
    width: 90%;
    white-space: initial;
    height: auto;
  }

  p{
    margin: 0;
  }
}



#menuGerenciaTitulo{
  display: flex;
  align-items: center;
  background-color: $Blue-dark;
  color: $White;
  // border-top-left-radius: 30px;
  border-radius: .75rem .75rem 0 0;
  height: 45px;
  padding: 0 7%;
  margin: 0;
  font-weight: 900;
  font-size: 0.7rem;

  p{
    margin: 0;
  }

  .iconMenuGerencia{
    display: flex;
    width: 100%;
    margin: 0 auto;
    padding-left: 7px;
    // justify-content: center;
  }
}

.options{
  top: 0;
  background-color: $White;
  // border-bottom-left-radius: 30px;
  border-radius: .75rem;
  -webkit-box-shadow: 5px 5px 21px -12px rgba(0,0,0,0.75);
  -moz-box-shadow: 5px 5px 21px -12px rgba(0,0,0,0.75);
  box-shadow: rgba(17, 12, 46, 0.1) 0px 10px 20px 0px;
  padding-bottom: 14px;
}

.menuOptions{
  text-decoration: none;
  box-sizing: border-box;
  background-color: $White;
  color: $Gray-fonts;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  font-weight: 700;
  height: 35px;
  // height: auto;
  width: 100%;
  border: 0;
  padding: 13.5px 12.5px;

  #enlacev1{
    color: #707070;

    &:hover{
      color: #707070;
    }
  }

  img{
    width: 20px;
    height: 20px;
    margin: 0 20px 0 0;
  }

}

.menuOptions:hover{
  background-color: $Blue-dark-soft;
  color: white;
  text-decoration: none;
}

.menuOptions:focus{
  background-color: $Blue-dark-soft;
  color: white;
  text-decoration: none;
}

.menuOptions:focus-visible{
  background-color: $Blue-dark-soft;
  color: white;
  text-decoration: none;
}

.menuOptions:active{
  background-color: $Blue-dark-soft;
  color: white;
  text-decoration: none;
}

.menuIcon{
  margin: 0 5% 0 0;
}

.accordion{
  background-color: $White;
  width: 100%;
  margin: 0;
  padding: 0;
}

button{
  border: none;
}


.card{
  margin: 0;
  padding: 0;
  border: none;
  border-radius: none;
}

.card-header{
  background-color: $White;
  height: 35px;
  cursor: pointer;
}

.card-header:focus{
  background-color: $Blue-dark-soft;
  color: white;
}

.card-header:active{
  background-color: $Blue-dark-soft;
  color: white;
}

.card-header:target{
  background-color: $Blue-dark-soft;
  color: white;
}

.AccordionCard{
  background-color: $White;
  border-radius: 0;
  width: 100%;
  margin: 0;
  border: none;
}

.accordionOptionsContainer{
  display: flex;
  flex-direction: column;
  padding: 0;
}

.accordionOptionsItem{
  display: flex;
  align-items: center;
  padding: 0 16px 0 24px;
  min-height: 35px;
  text-align: left;
  border-bottom: 1px solid $Blue-soft;
  font-size: 0.8rem;
  font-weight: 400;
  color: $Blue-dark-soft;
  background-color: $White;
  white-space: normal;

  p{
    display: flex;
    align-items: center;
    margin: auto 0;
  }
}

.accordionOptionsItem:hover{
  background-color: $White-dark;
}

.accordionOptionsItem:focus{
  // background-color: $Gray-fonts;
  background-color: $White-dark;
  // color: $White-dark;
}

a:hover{
  text-decoration: none;
  color: $Blue-dark-soft;
}

.accordionLevel2{
  display: flex;
  align-items: center;
  padding: 0 0 0 17.5% ;
  margin: auto 0;
  height: 35px;
  border-bottom: 1px solid $Blue-soft;
  font-size: 0.8rem;
  font-weight: 400;
  color: $Blue-dark-soft;
}

.accordionLevel3{
  display: flex;
  flex-wrap: nowrap;

  // grid-template: 1fr 1fr / repeat(3, 1fr);
  margin: 0;
  width: 100%;
  // color: $White;
  min-height: 30px;

  p{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin: 0;
    text-align: center;
    font-size: 0.7rem;
    font-weight: bold;
    // color: $White;

  }

  .crearOption{
    border-top: 4px solid rgb(35, 158, 35);
    background-position: 25% 75%;
    transition-property: sliding-vertically;
    transition: 0.3s;
    p{
      color: green;
    }
  }

  .crearOption:hover{
    background-color: rgb(35, 158, 35);
    p{
      color: $White;
    }
  }

  .adminOption{
    transition: 0.3s;

    border-top: 4px solid $Blue-dark;

    // background-color: rgb(83, 113, 170);
    p{
      color: $Blue-dark;
    }

    &:hover{
      background-color: $Blue-dark;
      p{
        color: $White;
      }

    }


  }

  .busquedaOption{
    transition: 0.3s;
    border-top: 4px solid $Blue-dark-soft;
    grid-row-start: 2;
    p{
      color: $Blue-dark-soft;
    }

    &:hover{
      background-color: $Blue-dark-soft;
      p{
        color: $White;
      }

    }
  }

  .informeOption{
    // border-left: 4px solid $Yellow;
    border-top: 4px solid $Yellow;

    color: $Yellow;
    transition: 0.3s;

  }

  .informeOption:hover{
    background-color: $Yellow;
    p{
      color: $White;
    }
  }


  .informeOption-op{
    // border-left: 4px solid $Yellow;
    border-top: 4px solid #2E86AB;

    color: #2E86AB;
    transition: 0.3s;

  }

  .informeOption-op:hover{
    background-color: #2E86AB;
    p{
      color: $White;
    }
  }

}

.optionsLevel3{
  text-decoration: none;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  width: 100%;
}
