@import '/src/root.scss';

#contenedorSwitchSeccionesForm{
  width: 250px;
  height: auto;
  // background-color: green;
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 15px;


  .switchBotonFormFactores{
    height: 35px;
    width: 120px;
    background-color: whitesmoke;
    color: $Blue-dark;
    font-weight: 700;
    font-size: 1rem;
    transition: 0.15s;
    // margin: 0 5px;
    border-radius: 8px;
  }
  .switchSelected{
    background-color: $Blue-soft;
  }
  .switchRegistro{
    margin: 0 5px 0 0;
  }

  .switchActividades{
    margin: 0 0 0 5px;
  }
}