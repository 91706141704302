.danger__chip {
    background-color: var(--red-50);
    color: var(--red-600);
    font-weight: 700;
    padding: 0.25rem .5rem;
    border-radius: 5px;
    border: 1px solid var(--red-100);
    font-size: 12px;
}

.regular__chip {
    background-color: var(--gray-200);
    color: var(--gray-700);
    font-weight: 700;
    padding: 0.25rem .5rem;
    border-radius: 5px;
    border: 1px solid var(--gray-100);
    font-size: 12px;
}