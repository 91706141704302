:root {
  --success: #219a00;
  --darkblue-600: #213265;
  //Slate Gray
  --gray-50: #f8fafc;
  --gray-100: #f1f5f9;
  --gray-200: #e2e8f0;
  --gray-300: #cbd5e0;
  --gray-400: #a0aec0;
  --gray-500: #718096;
  --gray-600: #4a5568;
  --gray-700: #2d3748;
  --gray-800: #1a202c;
  --gray-900: #171923;

}


/* ==========================================================================
    Buttons
   ========================================================================== */


.p-button.p-button-icon-only.p-button-rounded {
    width: 32px;
    height: 32px;
}

.p-button.p-button-success {
    background-color: var(--success);
    border-color: var(--success);
    color: var(--white);
}

.p-button {
  background-color:var(--darkblue-600);
  border-color: var(--darkblue-600);
  font-weight: bold;

  &:enabled:hover, &:not(button):not(a):not(.p-disabled):hover {
    background-color: var(--blue-800);
    border-color: var(--blue-800);
  }

  &:not(.p-button-icon-only) {
    svg {
      font-size: 18px;
      margin-right: 8px;
    }
  }

}

.p-button.p-button-sm {
  font-size: 0.875em;
  padding: 0.65625em 1.09375em;
}


/* ==========================================================================
    Accordion
   ========================================================================== */

.p-accordion-header-text{
  width: 100%;
}

/* ==========================================================================
    Badge
   ========================================================================== */

.p-badge.p-badge-info {
  background-color: var(--blue-100);
  color: var(--blue-900);
}

