@import '../../root.scss';

a{
  color: black;
  font-weight: 700;
}

.detalleItem{
  position: absolute;
  margin-bottom: -30px;
  border-radius: 5px;
  z-index: 3;
  box-shadow: 0px 2px 10px -5px rgba(0,0,0,0.75);
  transition-duration: 0.1s;
  transition-property: opacity;
  padding: 3px 5px;
  max-width: 300px;
  height: auto;
  text-align: justify;
  border: 1px solid white;
  color: white;

  // 0% :{
  //   opacity: 0;
  // }

  // opacity: {
  //   0 : 0;
  // };
}

.progressBarHorizontalGraph{
  border-radius: 3px 30px 30px 3px;
  height: 25px;
  width: 100%;
  transition: width 0.5s ease-in;
  background-color: rgb(230, 230, 230);
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

.progressHorizontalGraph{
  cursor: pointer;
  border-radius: 3px 30px 30px 3px;
  height: 25px;
  width: 0;
  transition: width 0.5s ease-in;
  display: flex;
  flex-direction: row;;
  justify-content: center;
  align-items: center;
  overflow: visible;
  margin-right: 4px;
}

.barraHorizontal{
  -webkit-box-shadow: 0px 2px 10px -5px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 2px 10px -5px rgba(0,0,0,0.75);
  box-shadow: 0px 2px 10px -5px rgba(0,0,0,0.75);
}

.mainGraphContainer{
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 6% 3%;
  overflow-y: auto;
  // height: 100%;
  // background-color: green;
  // overflow-y: auto;
}

.MuiLinearProgress-bar  {

  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;


  // background-color: #2c5e99 !important;
  //  & .MuiLinearProgress-barColorPrimary {
  //    & .MuiLinearProgress-bar1Determinate{

  //    }
  // }

}

.contArray{
  padding: 5px 10px 15px;
  // overflow-x: visible;
}

.itemContainerProgress{
  background-color: rgb(223, 223, 223);
  border-top-right-radius: 10px  !important;
  border-bottom-right-radius: 10px  !important ;
  transition: 0.17s;
  overflow: hidden;
  border-radius: 3px;

  // &:hover{
  //   transform: scale(1.01);
  // }
}

.valorItem{
  display: contents;
  position: relative;
  height: 100%;
  width: auto;
  bottom: 0;
  font-weight: 600;
  z-index: 2;
  color: rgb(92, 92, 92);
  padding: 0 6px;
  //display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  font-size: 0.7rem;
  // margin: 0px auto 4px;
  margin: -1px auto 4px;
}

.valorItemMulti{
  // position: absolute;
  // bottom: 0;
  font-weight: 600;
  // z-index: 3;
  // color: white;
  padding-left: 5px;
  // margin: -1px auto 4px;

}

.progressContainer{
  width: 100%;
  height: 25px;
  background-color: rgb(241, 239, 239);
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.progressBar{
  border-top-right-radius: 10px  !important;
  border-bottom-right-radius: 10px  !important ;
  height: 25px;
  border: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: white;
  font-weight: 600;
  padding-left: 10px;
  font-size: 0.8rem;

}
