@import '../root.scss';


.labelsProgresoCompleteContainer {
  // display: none;
  width: 290px;
  height: 0;

  position: fixed;
  // background-color: green;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  height: 0px;
  background-color: rgba(0, 0, 255, 0);
  border-radius: 10px;
  padding: 10px;
  color: white;
  margin: -30px 0 0 0;
  z-index: 100;
  // top: -10px;

  .labelsWidthDataComplete {

    display: none;
    width: 100%;
    display: grid;
    grid-template: 1fr / 1fr 1fr;
    justify-items: flex-start;

    .dataProgresoComplete {
      grid-column-start: 2;
    }

    p {
      display: none;
      padding: 0;
      margin: 0;
    }
  }
}

.containerProgressComplete {
  max-width: 300px;
  position: relative;
  //z-index: 1000;
  margin: 0 auto;

  &:hover .labelsProgresoCompleteContainer {
    position: absolute;
    display: flex;
    font-size: 0.8rem;
    background-color: rgb(103, 139, 164);
    height: 110px;
    // bottom: 10px;

    .labelsWidthDataComplete {
      P {
        display: block;
      }
    }
  }

  h1 {
    text-align: center;
  }

  .progressbarComplete-container {
    position: relative;
    width: 100%;
    height: 30px;
    border: 1px solid #FFF;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .progressbarComplete-complete {
      position: absolute;
      left: 0;
      top: 0px;
      height: 100%;
      background-color: #5225bd;
      border-radius: 10px;
      animation: a 2500ms infinite ease-in-out;
      z-index: 1;

      .progressbar-liquidComplete {
        z-index: 1;
        width: 65px;
        height: 70px;
        animation: a 2500ms infinite ease-in-out, u 3000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
        position: absolute;
        right: -5px;
        top: -10px;
        background-color: #5225bd;
        border-radius: 45%;
      }
    }

    .progressComplete {
      background-color: rgba(255, 0, 0, 0);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 50px;
      z-index: 2;
      font-size: 1.1rem;

      p {
        background-color: rgba(0, 0, 0, 0);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50px;
        // background-color: red;
        z-index: 2;
        padding: 0;
        margin: 0;
      }
    }
  }
}

@keyframes a {
  0% {
    background-color: #ec8a2e;
  }

  50% {
    background-color: #ff7505;
  }

  100% {
    background-color: #ec8a2e;
  }
}

@keyframes u {
  from {
    transform: rotate(0deg);
  }

  from {
    transform: rotate(360deg);
  }
}