  .card-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem;
  }
  
  .info__items {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .info__item {
    
        .item__tag {
            font-size: 16px;
            color: var(--gray-500);
            line-height: 1;
        }

        .item__value {
            font-size: 20px;
            font-weight: 700;
            color: var(--gray-700);
        }

        .item__unit {
            font-size: 14px;
            color: var(--gray-500);
            margin-left: 4px;
            font-weight: 400;
        }
    }
  }
  
  .grid-item {
    display: grid;
    flex: 1;
    grid-auto-rows: min-content;
    gap: 0.125rem;
  }
  
  .text-muted {
    font-size: 0.875rem;
    color: var(--color-gray-500);
  }
  
  .text-xl {
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1;
  }
  
  .text-sm {
    font-size: 0.875rem;
    font-weight: normal;
  }
  
  .tabular-nums {
    font-variant-numeric: tabular-nums;
  }
  
  .chart-container {
    margin: auto;
    aspect-ratio: 1;
    width: 100%;
    max-width: 80%;
  }