@import '../../../../root.scss';

.agregarNotaMmodalContainer{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $BackgroundModales;
  backdrop-filter: blur(4px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;

  .agregarNotaCard{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    text-align: center;
    // padding: 6%;
    // top: 10rem;
    background-color: #f8f8f8e7;
    padding: 1.2rem;
    width: 45%;
    // min-height: 500px;
    height: auto;
    border-radius: 30px;

    .formAddNotas{
      padding: 0 5%;
    }

    #checkboxNotaContainer{
      display: flex;
      flex-direction: row-reverse;
    }

    .asuntoNotaContainer{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      #asuntoNotaInput{
        width: 100%;
      }
    }

    .textAreaNotaContainer{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;

      #textAreaNotas{
        height: 200px;
        width: 100%;
      }
    
    
    }

    .buttonNota{
      width: 100px;
      height: 35px;
      color: $White;
      border-radius: 15px;
      margin: 0 15px;

      &#enviarNota{
        background-color: $Blue-dark;
  
        &:hover{
          border: 1px solid $Green;
          background-color: $Green;
          color: $White;
        }
      }
  
      &#CancelarAddNota{
        background-color: $Blue-dark;
  
        &:hover{
          border: 1px solid $Red;
          background-color: $Red;
          color: $White;
        }
      }
    }



    .closeButtonContainer{
      top: 20px;
      margin-right: 30px;
      position: absolute;
      // background-color: green;
      display: flex;
      width: 100%;
      justify-content: flex-end;

      #closeIconNota{
        // height: 35px;
        // padding-left:  20px;
        // padding-right:  20px;
        // border-radius: 15px;
        // margin: 0 15px;
        cursor: pointer;
  
        &:hover{
          background-color: $Blue-dark-soft;
          color: $White;
          font-weight: 700;
          border-radius: 50%;
        }
      }
    }

  }


  @media only screen and(max-width: 976px){
    .agregarNotaCard{
      width: 90%;
    }

  }
}

