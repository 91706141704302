@import '../../../../root.scss';

.pagesTitles{
  margin: 0;
  height: 35px;
}

form{
  margin: 0;
}

.busquedaContainer{
  // background-color: green;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .inputBusqueda{
    height: 35px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-left: 15px;
    outline: none;
  }

  .busquedaButton{
    background-color: $Blue-dark;
    color: $White;
    border: 1px solid $Blue-dark;
    font-weight: bold;
    height: 35px;
    width: 100px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    &:hover{
    border: 1px solid $Green;
    background-color: $Green;

    }
  }
}