@import '../../../../root.scss';


.agregarAnalistaModalContainer{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $BackgroundModales;
  backdrop-filter: blur(4px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;

  .inputBuscadorContainer{

    #inputBuscadorAnalistas{
      height: 30px;
      border: 2px solid $Blue-dark;
      width: 280px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      padding: 0 10px;
      outline: none;
    }

    .buttonBuscador{
    padding-right: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border: solid #213265;
    height: 30px;
    width: 80px;
    background-color: #213265;
    color: $White;
    }
  }


  .agregarAnalistaCard{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    text-align: center;
    // padding: 6%;
    // top: 10rem;
    background-color: #f8f8f8e7;
    padding: 1.2rem;
    width: 50%;
    min-height: 500px;
    border-radius: 30px;

    .tableAnalistasContainer{
      width: 90%;

      .tableAnalistas{
        font-size: 14px;
      }
    }

    .buttonAddAnalista{
      background-color: $Blue-dark;
      color: $White;
      height: 35px;
      padding-left:  20px;
      padding-right:  20px;
      border-radius: 10px;
      margin: 0 15px;
      font-weight: 600;


      &:hover{
        border: 0;
        font-weight: 600;
        background-color: $Green;
        transition: 0.2s;
        color: $White;

      }
    }

    .asuntoNotaContainer{
      display: flex;
      justify-content: space-around;

      #asuntoNotaInput{
        width: 70%;
      }
    }

    .closeButtonContainer{
      top: 20px;
      margin-right: 30px;
      position: absolute;
      // background-color: green;
      display: flex;
      width: calc(100% - 30px);
      justify-content: flex-end;
    }


  }

  .buttonModalContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .deniedButton{
      background-color: $Red;
      color: $White;
    }

    .cancelButton{
      background-color: $Gray-dark;
      color: $White;
    }
  }

  @media only screen and(max-width: 768px){
    .modalCard{
      width: 90%;
    }
  }
}

