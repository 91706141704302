.porcentajes_cont{
  // background-color: red;
  display: flex;
  justify-content: space-around;
  margin-top: 7px;

  .porcentajeItem{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px ;
  }
}
