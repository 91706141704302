@import "../../root.scss";

.tituloSelectSolicitar{
  font-size: 3rem;
  font-weight: 600;
  color: white;
}

.contenedorTipoSolicitud{

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // background-color: red;
  background-image: url('https://www.ciacosinte.site/cia2/static/media/fondo_solicitar.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: auto;
  border-radius: 10px;
  margin-top: 20px;

  .cardBodySelectSolicitar{
    background-color: rgba(54, 84, 129, 0.586);
    border-radius: 10px;
    height: auto;
    width: 100%;
    margin: 0;
  }

}

.formsContainer {
  display: grid;
  grid-template: 1fr 50px / 1fr 1fr;
  // background-color: red;

  .formItem {
    display: flex;
    flex-direction: column;
    // justify-content: flex-end;
  }

  .formBasic {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;
    // min-width: 40%;
    padding: 10px;

    form {
      width: 100%;
      margin-top: 10px;
      padding-right: 30px;

      .observacionesLabel{
        display: flex;
        align-items: flex-start;

      }

      label {
        font-size: 0.9rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        select {
          width: 20%;
          height: 30px;
          border-radius: 7px;
          border: 1px solid $Gray-fonts;
          outline: none;
          background-color: $White;
        }

        input {
          width: 70%;
          height: 30px;
          margin: 3px 0;
          padding-left: 10px;
          padding-right: 10px;
          border-radius: 7px;
          border: 1px solid $Gray-fonts;
          outline: none;
        }
      }
    }

    .idSelectContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 70%;
      .inputID {
        width: 75%;
      }
    }
  }

  textarea {
    width: 70%;
    height: 100px;
    padding: 10px;
    margin-top: 5px;
    border-radius: 7px;
    outline: none;
    resize: both;
  }

  .buttonContainer {
    grid-column-end: 3;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: auto;
  }
}
.cardContainer {
  border-top-left-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.infoGeneral {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 200px;
  margin: 0 1px;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  height: 30px;
}

.contenedorSelectSolicitar{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 85%;
  height: auto;
  margin-top: -35px;
  // background-color: green;
}

.selectSolicitar{
  border-radius: 10px;
  border: 0;
}

.helpIcon{
  display: flex;
  justify-content: flex-end;
  width: auto;
  // background-color: red;


  .contenedorIcono{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
      background-color: $Blue-dark-soft;
    border-radius: 20px;

    &:hover{
      background-color: white;
      transition: 0.3s;
    }
  }

}

@media only screen and (max-width: 762px) {

  .tituloSelectSolicitar{
    font-size: 1.4rem;
  }

  .formsContainer {
    display: grid;
    grid-template: 1fr 1fr 0.3fr / 1fr;
    justify-items: center;

    #formItemA {
      grid-row-start: 1;
      margin: 0;
    }

    #formItemB {
      grid-row-start: 2;
    }

    .buttonContainer {
      grid-column-start: 1;
      grid-row-start: 3;
      margin: 0;
    }

    .confirmButton {
      margin: 0;
    }
  }
}
