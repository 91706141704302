@import '../../root.scss';

.MuiPaper-elevation4{
  box-shadow: none !important;
  border-bottom: 1px solid rgb(235, 235, 235);
}

#dbContainer{
  // display: grid;
  // grid-template: 70px 8fr / 2fr 8fr;
  min-height: 100vh;
  z-index: 1;
  background-color: #f8fafc;
  header{
    // z-index: 2;

    .MuiPaper-elevation4{
      display: none;
    }
  }

  #navbar{
    z-index: 2;
    background-color: $White;
    grid-column-start: 1;
    grid-column-end: 3;
    margin-bottom: 70px;
  }

  #menu{
    z-index: 1;
    overflow-y: auto;
    margin-top: 70px;
    // padding-top: 70px;
    border: none;
  }

  // .makeStyles-drawerPaper-6{

  //   padding-bottom: 100px;
  // }

  // .makeStyles-drawerPaper-5::-webkit-scrollbar{
  //   width: 10px;
  //   margin: 100px 0;
  // }

  // .makeStyles-drawerPaper-5::-webkit-scrollbar-thumb{
  //   border-radius: 30px;
  //   background-color: $Blue-dark;
  //   // display: none;
  //   // color: red;
  // }

  // .makeStyles-drawerPaper-5::-webkit-scrollbar-thumb:hover{
  //   background: #b3b3b3;
  //   box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  // }

  // .makeStyles-drawerPaper-5::-webkit-scrollbar-track{
  //   background-color: $White;
  //   border-radius: 30px;
  //   height: 50vh;
  // }

  #db{

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // background-color: $White-dark;
    height: auto;
    width: 8%;
    padding-top: 70px;

    // grid-column-start: 2;
    // grid-column-end: 3;
    box-sizing: border-box;

    p#footer{
      // width: 70%;
      display: flex;
      justify-content: center;
      // width: 100%;
      position: float;
      text-align: center;
      // top: 98%;
      margin: 10px;
      bottom: 0;
      color: $Blue-dark;
      font-weight: 600;
    }
  }
}



.MuiPaper-root{
  box-shadow: 0;
}

.makeStyles-drawerClose-9 {
  width: 49px;
  overflow-x: hidden;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  background-color: #e0e0e0;
  padding-top: 20px;
}

.makeStyles-drawerOpen-6{
  background-color: #e0e0e0;
  // width: 96px;
}

// .makeStyles-drawerClose-5{
//   background-color: #e0e0e0;
//   // width: 96px;
// }

// .makeStyles-drawerClose-9{
//   background-color: #e0e0e0;
//   width: 96px;
// }

.makeStyles-drawerOpen-5::-webkit-scrollbar{
  width: 10px;
  margin: 100px 0;
}

.makeStyles-drawerOpen-5::-webkit-scrollbar-thumb{
  border-radius: 30px;
  background-color: $Blue-dark;
  // display: none;
  // color: red;
}

.makeStyles-drawerOpen-5::-webkit-scrollbar-thumb:hover{
  // background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.makeStyles-drawerOpen-5::-webkit-scrollbar-track{
  background-color: $White;
  border-radius: 30px;
  height: 50vh;
}
