@import '../../root.scss';

.diaActual{
  font-size: 60px;
  font-weight: 700;
}

// .cardbodywidget{
//   padding: 10px;
// }

.labelWidget{
  font-weight: 600;
  font-size: 1rem;
}

.mainWidgets{
  display: flex;
  width: 100%;
  background-color: $White;
  overflow-y: hidden;
  overflow-x: auto;
  align-items: center;
  border-radius: 10px;
  padding: .5rem;

  #widgetFecha{
    width: 280px;
    min-width: 270px;

    h2{
      font-weight: 700;
    }
  }

}

#titleDateWidgets{
  color: $Blue-dark;
}

.contenedorWidgets{
  background-color: $White;
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  float: right;
  justify-content: space-between;
  align-items: center;
  width: auto;
  height: auto;
  padding: 1.5rem;

  &::-webkit-scrollbar{
    background-color: $White;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb{
    background-color: $White-dark;
    border-radius: 10px;
  }

  #widgetVencimiento{
    border-bottom: 8px solid #2C5E99;

    // &:hover{
    //   background-color: #2C5E99;
    //   color: White;
    //   transition: 0.3s;

    //   #diasVencimiento{
    //     background-color: $White;
    //     color: #2C5E99;
    //   }
    // }
  }

  #widgetProceso{
    border-bottom: 8px solid #05A4DB;

    // &:hover{
    //   background-color: #05A4DB;
    //   color: White;
    //   transition: 0.3s;

    //   #diasEnProceso{
    //     background-color: $White ;
    //     color: #05A4DB ;
    //   }
    // }
  }

  #widgetNovedad{
    border-bottom: 8px solid #009688;

    // &:hover{
    //   background-color: #009688;
    //   color: White;
    //   transition: 0.3s;

    //   #diasNovedad{
    //     background-color: $White;
    //     color: #009688;
    //   }

    // }
  }

  #widgetVisitas{
    border-bottom: 8px solid #8BC34A;

    // &:hover{
    //   background-color: #8BC34A;
    //   color: White;
    //   transition: 0.3s;

    //   #diasVisitas{
    //     background-color: $White;
    //     color: #8BC34A;
    //   }
    // }
  }

  #widgetFinalizados{
    border-bottom: 8px solid #F9CF00;

    // &:hover{
    //   background-color: #F9CF00;
    //   color: White;
    //   transition: 0.3s;

    //   #diasFinalizados{
    //     background-color: $White;
    //     color: #F9CF00;
    //   }
    // }
  }

  .widgetInList{
    transition: 0.15s;

    &:hover{
      transform: scale(1.05);
      // border-bottom: 10px;
    }
  }


  .widget{
    min-width: 200px;
    max-width: 250px;
    height: 100px;
    border-radius: 15px;
    margin: 0 10px;
    cursor: pointer;
  }

  .contenidoWidget{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    // grid-template: 20px auto / 1fr;
    padding: 3px;

    .cantidadWidget{
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      font-size: 20px;
      font-weight: 700;
      // padding: 20px 0 0 ;
      margin: 0;
      width: 100%;
    }

    .labelWidget{
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 11px;
      width: 100%;
    }

    .labelDias{
      grid-row-start: 3;
      display: flex;
      align-items: center;
      width: 100%;
    }

    .listDias{
      display: grid;
      grid-template: auto auto auto / auto auto auto ;

      row-gap: 10px red;
      width: 100%;
      justify-items: center;
      text-decoration: none;
      background-color: $White-dark;
      overflow: hidden;
      border-radius: 10px;
    }
    #diasVencimiento{
      background-color: #2C5E99;
      color: $White;
    }

    #diasEnProceso{
      background-color: #05A4DB;
      color: $White;
    }

    #diasNovedad{
      background-color: #009688;
      color: $White;
    }

    #diasVisitas{
      background-color: #8BC34A;
      color: $White;
    }

    #diasFinalizados{
      background-color: #F9CF00;
      color: $White;
    }

  }

}

@media only screen and (max-width: 1020px)  {

  .mainWidgets{

    flex-direction: column;
  }



  .contenedorWidgets{
    flex-direction: column;

    .widget{
      height: 140px;
      margin-bottom: 20px;
      // padding: 5px;
    }
  }
}
