@import '/src/root.scss';

.bodyWidgets{
  // background-color: green;
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;

  .widgetFactores{
      height: 80px;
      min-width: 150px;
      margin: 10px 15px;
      border-radius: 15px;
      cursor: pointer;
      font-size: 1rem;
      // border-bottom: 8px solid #213265;
      display: grid;
      grid-template: 1fr / 2fr 1fr;
      align-items: center;
      justify-content: center;
      text-align: start;
      transition: 0.3s;
      padding: 0 0.8%;
      // font-size: 1.2rem;
      font-weight: 700;
      color: $Blue-dark;


      p{
          margin: 0;
      }

      .widgetPorcentaje{
          display: flex;
          // font-weight: 800;
          font-size: 1rem;
          justify-content: flex-end;
      }

      &:hover{
          transform: scale(1.1);
      }
  }
}

.accordionFactores{
  border-radius: 10px;
  overflow: hidden;

  .toggleSeccion{
    background-color: $Blue-soft;
    display: flex;
    align-items: center;
    // justify-content: center;
    padding: 0 10px ;
    height: 45px;
    border-bottom: 1px solid rgb(197, 197, 197) ;
    color: $Blue-dark;


    p{
      margin: 0;
      text-align: justify ;
    }
    
  }

  .collapseSeccion{
    border: 1px solid $Blue-soft;
  }
  
}

.tabla-ubicaciones {
  -webkit-transform: rotateX(0deg);
}

.table-ubi-container {
  margin-top: 1.5em;
}