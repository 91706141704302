@import "../../root.scss";

.tarjetaSelectsProcesos{
    background-image: url('https://www.ciacosinte.site/cia2/static/media/fondo_procesos.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .fondoAzulTarjetaSelects{
        background-color: rgba(54, 84, 129, 0.586);
        border-radius: 10px;

    }

}

.contenedorTotalSolicitudes{
    display: flex;
    align-items: center;
    border-radius: 13px;
    margin: auto;
    height: 80%;
    padding: 10px;
    margin: 0 15px;
    font-weight: 900;
    // font-size: 0.5rem;
    color: $White;
    background-color: rgba(185, 185, 185, 0.708);
}

.tituloSelectsProcesos{
    font-size: 3rem;
    font-weight: 600;
    color: white;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: -20px;
    text-align: left;
}
.solicituesTotalGeneral{
    display: flex;
    margin: 0;
    font-size: 1.7rem;


}

.totalSolicitudes{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    background-color:  $Blue-dark;
    color: $White;
    border-radius: 10px;
    margin: 0 10px;
    font-size: 1.4rem;
}

.select {
    font-size: small;
    border-radius: 15px;
    color: $Blue-dark;
    margin: 0;
    border: solid 2px;
}

#label {
    font-weight: 800;
    color: $Blue-dark;
    margin: 0;
}

#inputBuscadorGeneral {
    border: none;
    // min-width: 70px;
    // max-width: 180px;
    width: 90%;
    background-color: whitesmoke;

}
#inputBuscadorGeneral:focus {
    outline: none !important;
    outline-width: 0 !important;
}

#inputGroup {
    padding: 0 0 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border: solid 2px;
    // border-radius: 15px;
    background-color: whitesmoke;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    color: $Blue-dark;
    height: 33px;
    width: 100%;

}

.cardbodySollicitud{
    z-index: 0;
}

.tableContainer{
    // overflow-y: overlay;

    padding: 15px;
    border-radius: 10px;
    // -webkit-transform: rotateX(180deg);
    // transform: rotateX(180deg);
    // z-index: -1000000000000000000000;

}

.observacionesRegistroBox{
    white-space:nowrap;
    // white-space:pre-line;
    max-width: 200px;
    text-overflow: ellipsis;
    height: auto;
    // overflow: hidden;
    transition: 0.5s;
    text-align: justify;

    // &:hover{
    // background-color: rgb(128, 168, 196);
    // min-width: 350px;
    // // overflow-wrap: anywhere;
    // white-space: initial;
    // text-overflow: ellipsis;
    // transition: 0.3s;
    // overflow-y: hidden;
    // color: $White;
    // }
}

.tableContainer::-webkit-scrollbar {
    // background-color: green;
    border-radius: 15px;

}


.tableContainer::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background-color: $Gray-fonts;
}

.tableContainer::-webkit-scrollbar-track {
    border-radius: 15px;
    background-color: $White-dark;
}

.table{
    // overflow-anchor: 10px;
    // overflow-x: scroll;
    // overflow: visible;
    // padding: 0 10px;
    // overflow-y: overlay;

    border-radius: 10px;
    font-size: 14px;
    text-align: start;
  // -webkit-transform: rotateX(180deg);
  // transform: rotateX(180deg);

}

.contenedorFiltrosMain{
    background-color: $White;
    margin: 15px;
    padding: 0;
    border-radius: 10px;
    display: grid;
    grid-template-columns: auto auto ;
    justify-content: space-between;
    align-items: center;

    .filtroItem{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: nowrap;
        padding: 1% 2%;
        margin: 5px 0;
        min-width: 190px;
    }

    .filtroItemBusqueda{
        // display: flex;
        // flex-direction: column;
        // flex-wrap: nowrap;
        // padding: 1% 2%;
        min-width: 230px !important;

    }
}

.contenedorFiltros{
    background-color: $White;
    margin: 15px;
    padding: 0;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 0.8fr 0.8fr 0.8fr 1fr ;
    align-items: center;

    .filtroItem{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: nowrap;
        padding: 1% 2%;
        margin: 5px 0;
    }

    .filtroItemBusqueda{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 1% 2%;
    }
}





@media only screen and ( max-width: 1020px ) {
    .contenedorFiltros{
        display: grid;
        grid-template: 1fr 1fr  / 1fr 1fr ;

        #buscadorGeneral{
            grid-column: 1 / 3;
        }
    }

}

@media only screen and ( max-width: 768px ) {
    .contenedorFiltros{
        display: grid;
        grid-template: 1fr 1fr 1fr / 1fr ;

        #estadoFiltro{
            grid-row: 2;
        }

        #buscadorGeneral{
            grid-row: 3;
        }

    }

    .tableContainer{
        overflow-x: scroll;

    }

}

// .rowTitleTablaEstudios{
//     background-color: green;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     height: 30px;
//     margin: 0 ;
//     padding: 0 100px;
//     width: 100%;
//     p{
//         background-color: aquamarine;
//     }

// }

.rowAdministrarRegistros{
    .td{
        text-align: left;
        // height: 80px;
    }

}

/**
 * Estilos del botón de pago en la tabla de registros
 */

.boton__pago {
    background-color: transparent;
    margin-left: 4px;
}
