@import '../../root.scss';

#signInContainer{
  display: flex;
  flex-flow: column nowrap;
  width: 70%;
  margin: 10% auto 5% auto;
  color: $Blue-dark;

  span{
    font-size: 28px;
    font-weight: bolder ;
    margin-bottom: 1.4rem;
  }

  form{
    display: flex;
    flex-flow: column nowrap;
    // margin-top: 1%;

    label{
      display: flex;
      flex-direction: column;
      // margin-top: 1%;
      font-size:0.8rem;
      font-weight: bolder;
    }

    input{
      height: 30px;
      border: none;
      border-bottom: 2px solid $Blue-dark;
      outline: none;
      padding: 0 15px;
      color: rgb(99, 99, 99);
      // pointer-events: none;
    }

    input:focus{
      transform: scale(1.08, 1.1);
      border-bottom: 3px solid rgb(12, 39, 112);
      transition: 0.6s ease;
      overflow: hidden;
    }

    .alert{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size:0.8rem;
      // color: green;
      height: 30px;
      padding: 0;
      text-align: center;
      margin: 0;
    }

    #formOptions{
      display: flex;
      padding-top: 6%;
      justify-content: space-between;
      // align-items: center;
      // font-size: 0.11rem;

      p#helpText{
        color: #707070;
        font-size: 0.8rem;
        margin: auto 0;
      }

      #loginButton{
        background-color: #213265;
        border-radius: 30px;
        font-size: 0.7rem;
        font-weight: 400;
        color: white;
        height: 30px;
        width: 100px;
        border: none;
      }

      #loginButton:hover{
        font-weight: 600;
      }
    }
  }

  #help{
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
    // align-items: center;
    color: rgb(150, 150, 150);
    cursor: pointer;


    p{
      color: rgb(150, 150, 150);
      font-size: 0.8rem;
      margin: auto 10px;
    }

  }
}

.modal-content{
  border-radius: 20px;
}

.modal-header{
  display: flex;
  background-color: $Yellow;
  justify-content: space-between;
  align-items: center;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

// .modalTitle{
//   background-color: $Yellow;
// }

.header__contrasena__expirada {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 2rem 0;

  .contrasena__expirada {
    color:$Blue-dark;
    font-weight: 700;
    font-size: 24px;
  }

  .contrasena__expirada__detalle {
    color:$Gray-fonts;
    font-weight: 300;
    font-size: 16px;
    margin-top: 8px;
  }
}




@media screen and (max-width: 768px) {
  .g-recaptcha {
    position: relative;
    left: 50%;
    -o-transform: scale(0.8) translateX(-50%);
    -ms-transform: scale(0.8) translateX(-50%);
    -moz-transform: scale(0.8) translateX(-50%);
    -webkit-transform: scale(0.8) translateX(-50%);
    transform: scale(0.8) translateX(-50%);
    -o-transform-origin: left center;
    -ms-transform-origin: left center;
    -moz-transform-origin: left center;
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}

.password__container__login {
  display: flex;
  flex-direction: row;
  justify-content: center;

  input {
    flex: 1;
  }

  .button_show_hide {
    margin-left: 1rem;
    display: flex;
    align-items: center;
  }
}
