@import '../root.scss';


.labelsProgresoContainer{
  // display: none;
  width: 290px;
  height: 0;
  position: absolute;
  // background-color: green;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  background-color: rgba(0, 0, 255, 0);
  border-radius: 10px;
  padding: 10px;
  color: white;
  margin: -42px 0 0 0;
  z-index: 40;
  // top: -10px;

  .labelsWidthData{

    display: none;
    width: 100%;
    display: grid;
    grid-template: 1fr / 1fr 1fr;
    justify-items: flex-start;

    .dataProgreso{
      grid-column-start: 2 ;
    }

    p{
    display: none;

      padding: 0;
      margin: 0;
    }
  }

  

}

.containerProgress {
  max-width: 300px;
  margin: 0 auto;
  // z-index: 1000;
  position: relative;

  &:hover .labelsProgresoContainer{
    position: absolute;
    display: flex;
    background-color: rgb(103, 139, 164);
    color: white;
    height: 110px;
    font-size: 0.8rem;
    // margin-top: 30px;
    z-index: 1001;

    // bottom: 10px;

      .labelsWidthData{
        p{
          display: block;
        }
      }
  }

  h1 {
    text-align: center;
  }
  .progressbar-container {
    position: relative;
    width: 100%;
    height: 30px;
    border: 1px solid #FFF;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    

    .progressbar-complete {
      position: absolute;
      left: 0;
      top: 0px;
      height: 100%;
      background-color: #5225bd;
      border-radius: 10px;
      animation: g 2500ms infinite ease-in-out;
      z-index: 1;
      .progressbar-liquid {
        z-index: 1;
        width: 65px;
        height: 70px;
        animation: g 2500ms infinite ease-in-out, r 3000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
        position: absolute;
        right: -5px;
        top: -10px;
        background-color: #5225bd;
        border-radius: 45%;

        

      }
    }
    .progress {
      background-color: rgba(255, 0, 0, 0);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 50px;
      z-index: 2;
      font-size: 1.1rem;


      p{
        background-color: rgba(0, 0, 0, 0);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50px;
        // background-color: red;
        z-index: 2;
        padding: 0;
        margin: 0;
      }
    }
  }
}

@keyframes g {
  0% { background-color: #58bd25;
  

  }
  50% { background-color: #5ba436; }
  100% { background-color: #58bd25; }


}

@keyframes r {
  from { transform: rotate(0deg); }
  from { transform: rotate(360deg); }
}