.rowPestanas{
  display: flex;

  .pestana{
    font-size: 0.8rem;
    padding: 6px 6px;
    border-radius: 7px 7px 0 0;
    background-color: white;
    margin-right: 4px;
    cursor: pointer;
    transition: 0.18s;

    &:hover{
      -webkit-box-shadow: 1px -1px 13px -6px rgba(101, 101, 101, 0.75);
      -moz-box-shadow: 1px -1px 13px -6px rgba(101, 101, 101, 0.75);
      box-shadow: 1px -1px 13px -6px rgba(101, 101, 101, 0.75);

    }
  }

}