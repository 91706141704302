@import '/src/root.scss';

.filtersData{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 20px 40px;
  // grid-template-rows: 1;
  // grid-auto-columns: 1fr 1fr;

  #tipoReporte{
    grid-column-start: 1;
    display: flex;
    flex-direction: column  !important;
    align-items: flex-start;
  }

  #input16{
    grid-column-start: 2;
    color: white;
    text-align: end;
    font-size: 4rem;
    font-weight: 700;
  }
}

@media only screen and (max-width: 768px) {

  .filtersData{
    flex-direction: column-reverse;

    #input16{
      font-size: 1.8rem;
      text-align: center;

    }
  }

}

@media only screen and ( max-width: 425px) {

  .filtros_input-cont{
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .filtros_input-item{
    width: 100%;
  }

  #tipoReporte{
    width: 100%;
  }

}

@media only screen and ( max-width: 976px) {

  #tipoReporte{
    width: 100%;
  }

}

@media only screen and ( min-width: 769px ) and ( max-width: 976px) {

  .filtros_input-cont{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
  }

  .filtersData{
    flex-direction: column-reverse;

    #input16{
      font-size: 3rem;
      text-align: center;
      width: 100%;
    }

  }

}

@media only screen and ( min-width: 977px )  {

  .filtros_input-cont{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto;
  }

  .filtersData{
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    #input16{
      font-size: 1.6rem;
      text-align: end;
      // width: 100%;
    }

    #tipoReporte{
      grid-column-start: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 50%;
    }

  }

}



