@import '../root.scss';

.loginModal{
  background-color: $BackgroundModales;

}

.leyendas-cont{
  display: flex;
  flex-direction: column;
  // grid-template-columns: 4;
  // grid-auto-rows: auto;
  align-items: space-around;
  flex-wrap: nowrap;
  // justify-content: space-around;
  width: 100%;
  height: 80px;
  overflow-y: auto;
  // background-color: blue;

  .leyendas-item{
    width: 100%;
    // min-width: 50%;
    // background-color: green;
    align-items: center;
    margin: 2px auto;
  }
}

.backdropFilt{
  backdrop-filter: blur(4px);
}