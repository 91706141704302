
.indicator__handle {
  width: 1px;
  position: relative;
  outline-offset: 1px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.indicator__handle--top {
  align-items: flex-start;
  margin-top: 6rem;
}


.indicator__handle__icon {
  z-index: 10;
  display: flex;
  height: 1rem;
  width: 0.75rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  background-color: #e2e8f0;
}

.indicator__handle__icon--large {
  height: 5rem;
}

[data-panel-group-direction="vertical"] {
  height: 1px;
  width: 100%;
}

[data-panel-group-direction="vertical"] > div.indicator__handle {
  transform: rotate(90deg);
  width: 100%;
}

