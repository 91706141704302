@import '../../../root.scss';


.notificationList{

  .notificationItem{
    // display: flex;
    // flex-direction: column;
    // justify-content: space-around;
    // border-bottom: 2px solid $Blue-dark-soft;
    padding: 1%;
    font-size: 1.1rem;
    p{
      margin: auto 0;
    }
  }
}


  #tdEmisor{
    width: 90px;
  }

  #tdDate{
    width: 200px;
  }