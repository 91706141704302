@import '/src/root.scss';

.info_factor{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  // background-color: red;<
  height: 100px;
  width: 100%;
  margin: 0 auto;
  font-size: 1.4rem;
  font-weight: 800;
  color: $Blue-dark;

  .btn_agregar{
    font-size: 0.9rem;
    height: 35px;
    width: 100px;
    border-radius: 10px;
    background-color: $Blue-soft;
    font-weight: 600;
    color: $Blue-dark;
    transition: 0.2s;

    &:hover{
      transform: scale(1.05);
    }

    &:active{
      transform: scale(0.95);
    }
  }

}

#mensaje_acts_vacias{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border: 2px dashed rgb(220, 220, 220);
  height: 60px;
  font-size: 1.4rem;
  font-weight: 700;
  color: rgb(184, 184, 184);
  // background-color: green;
}

.reg_item_factores{
  // background-color: red;
  margin-bottom: 5px;
  display: flex !important;
  // grid-template-rows: 1fr;
  // grid-auto-columns: auto;
  flex-direction: row;
  justify-content: space-evenly;
}

.colreg_factores{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30px;
  // background-color: #EEEEEE;
  // color: #213265;
  margin: 0;
  // font-weight: 800;
  width: 100%;
  flex: 1 1;

}


.colreg_factores.name-files{
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 10px;
}

.btn_filtros{
  display: flex;
  align-items: center;
  border-radius: 6px;
  height: 32px;
  padding: 0 10px;
  transition: 0.2s;

  p{
    margin: 0;
  }

  &:hover{
    background-color: $Blue-soft;

    #btn_filtros-icon{
      transform: rotate(45deg);
    }

  }
}

#btn_filtros-icon{
  transition: 0.2s;
}

.filtros_header{
  display: flex;
  justify-content: space-between;
  // background-color: red;
  width: 100%;
}


.filtros_container::-webkit-scrollbar{
  width: 12px;
  margin: 100px 0;
}

.filtros_container::-webkit-scrollbar-thumb{
  border-radius: 30px;
  background-color: $Gray-fonts;
  // display: none;
  // color: red;
}

.filtros_container::-webkit-scrollbar-thumb:hover{
  // background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.filtros_container::-webkit-scrollbar-track{
  background-color: $White;
  border-radius: 30px;
  height: 50vh;
}

.filtros_items{
  overflow-y: auto;
  max-height: 300px;
  min-height: 200px;

  display: flex;
  flex-wrap: wrap;

}

.buttonRegContainer {
  display: flex;
  justify-content: center;

  button {
    background-color: #ececec;
    width: auto;
    height: auto;
    padding: 1em;
    cursor: pointer;
    margin-right: 0.5em;
    font-weight: 700;
    color: #213265;
  }

  button {
    background-color: #ececec;
    width: auto;
    height: auto;
    padding: 1em;
    cursor: pointer;
    margin-right: 0.5em;
    font-weight: 700;
    color: #213265;
    border-radius: 5px;
  }

  button:hover {
    transform: scale(1.05);
    background-color: #cbd7e6;
  }
}

.iconReg {
  cursor: pointer;
}

.iconReg.deleteReg:hover {
  color: red;
}


