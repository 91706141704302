@import '../../../../../root.scss';

.MuiDropzoneArea-text{
  color: $Blue-dark-soft;
  // background-color: green;
}

.MuiDropzoneArea-dialogTitle{
  background-color: green;

}


.MuiDropzoneArea{
  border-radius: 15px;
}

.tablaArchivosGestionSolicitudInteligencia{
  // overflow-x: scroll;
}


.MuiDropzoneSnackbar-message	{
  content: 'Pila de archivos actualizada';
}