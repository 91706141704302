.trending-info {
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 0.5rem;
    font-size: 0.875rem;

    &__grid {
        display: grid;
        gap: 0.5rem;
    }

    &__trend {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-weight: 700;
        line-height: 1;
        color: var(--gray-700);
    }

    &__icon {
        height: 1rem;
        width: 1rem;
    }

    &__period {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        line-height: 1;
        color: var(--gray-500);
    }
}