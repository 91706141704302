@import '../../../root.scss';

.deniedButton{
    background-color: $Red;
    color: $White;
    width: 90px;
    height: 35px;
    border: none !important;
    border-radius: 10px !important;
    margin-right: 40px;
    font-size: 0.9rem;
    font-weight: 600;
    transition: 0.25s;
}
