@import "../../../root.scss";

.datepicker-form {
  height: 32px;
  width: 100%;
  margin: 3px 0;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 7px;
  border: 1px solid #707070;
  outline: none;
  font-size: 15px;
  margin-top: 0.6565555em;
}

.datepicker-form-repo {
  height: 32px;
  width: 100%;
  margin: 4px 0;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 7px;
  border: 1px solid #707070;
  outline: none;
}

.react-datepicker-wrapper {
  display: block;
  width: 100%;
}

.inputsInvalid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.MuiDropzoneArea-root.dropzone-dinamyc-class {
  height: 100px;
  min-height: 100px;
}

.previous-content {
  // display: none;
  color: white;
  position: relative;
  background-color: black;
  width: 100%;
  height: 50%;
  // display: none;
  justify-content: center;
  align-items: center;
  /* display: none; */
  opacity: 0.5;
  transition: 0.3s;
  font-size: 1.3rem;
  display: flex;
}

.previous-content--small {
  font-size: 10px;
  top: -40px;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.container-previous-image {
  text-align: center;
  width: 100%;
  height: 120px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    .previous-content {
      top: 0;
      height: 100%;
      position: relative;
      background-color: black;
      z-index: 2;
      display: flex;
      transition: 0.15s;
      margin-top: -30px;
    }

    .previous-content--small {
      margin-top: 0px;
      font-size: 10px;
      height: 70%;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}

.container-previous-image--small {
  display: block;
  height: 80px;
}

.previous-image {
  height: 100%;
  // display: block;
  // &:hover{
  //     .previous-content{
  //         top: 0;
  //         position: relative;
  //         background-color: green;
  //         z-index: 2;
  //         // display: flex;
  //         transition: 0.2s;
  //         margin-top: -30px;
  //     }
  // }
}

button.react-datepicker__close-icon.button-close-timer:after {
  background: #223265;
}

/* Estilos para el boton de cerrar del datepicker de "react-datepicker" */

button.react-datepicker__close-icon.button-close-datepicker:after {
  background: #223265;
}

/* Efecto hover para el boton de cerrar del datepicker de "react-datepicker" */

button.react-datepicker__close-icon.button-close-datepicker:hover:after {
  background: $Red;
}

button.react-datepicker__close-icon.button-close-timer-repo:after {
  background: #747474;
}

button.react-datepicker__close-icon.button-close-timer {
  padding-right: 0.5em;
  padding-top: 0.5em;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 3px dashed #e0e0e0;
  display: flex;
  cursor: pointer;
  border-radius: 4px;
  height: 100px;
  min-height: 100px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.file-preview {
  margin: 0 10px;
}

.custom-file-upload p {
  font-size: 18px;
  font-weight: 700;
}

.contenedor-pregunta-encuesta {
  display: block !important;
  margin: 1rem 2rem;
  padding: 1.5rem 2rem;
  border-radius: 10px;
  box-shadow: rgba(17, 12, 46, 0.1) 0px 10px 100px 0px;
  .contenedor-encabezado-encuesta {
    .contenedor-peso-pregunta {
      font-size: 16px;
      display: flex;
      justify-content: space-between;

      span {
        color: $Blue-dark;
        font-weight: 700;
      }
      svg {
        color: $Blue-soft;
        width: 18px;
        margin-right: 8px;
      }
    }
  }
}
.MuiSlider-root {
  height: 8px;
  margin-top: 3rem;
  color: $Blue !important;
  .MuiSlider-thumb {
    height: 24px;
    width: 24px;
    background-color: #fff;
    border: 2px solid $Blue-dark;
    margin-top: -8px;
    margin-left: -12px;
    white-space: nowrap;
    text-align: center;

    & .MuiSlider-valueLabel {
      left: calc(-50% + 10px);
      top: -28px;
      background-color: rgb(203, 215, 230, 0.5);
      width: auto;
      padding: 0 8px;
      border-radius: 10px;
      font-size: 10px;
      font-weight: 700;
      line-height: 1px;

      & * {
        background: transparent;
        color: $Blue-dark;
      }

      span {
        width: 100%;
      }
    }
  }

  .MuiSlider-valueLabel {
    position: relative;
  }
  .MuiSlider-track {
    height: 8px;
    border-radius: 4px;
  }

  .MuiSlider-mark {
    height: 8px !important;
  }

  .MuiSlider-rail {
    height: 8px;
    border-radius: 4px;
  }

  .MuiSlider-thumb.Mui-focusVisible,
  .MuiSlider-thumb:hover {
    box-shadow: 0px 0px 0px 4px rgba(63, 81, 181, 0.16) !important;
  }
  .MuiSlider-thumb.MuiSlider-active {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16) !important;
  }
}

.contenedor-progreso-encuesta {
  padding: 2rem;
  text-align: center;

  span {
    font-size: 18px;
    font-weight: 700;
    color: $Blue-dark;
    margin-top: 16px;
  }
}

.modal__form__nested__dialog {
  min-width: 75vw;
}

.modal__form__nested {
  //z-index: 3000 !important;
  background-color: rgba(0, 0, 0, 0.5);

  .modal__form__nested__body {
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
  }

  .modal__form__nested__close {
    position: sticky;
    top: 0;
    right: 0;
    left: 100%;
    background-color: $Red;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    transition: 0.3s;
    cursor: pointer;
    margin: 0 1rem;

    &:hover {
      background-color: #dc3545;
      scale: 1.1;
    }
  }
}

.modal__nested__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  border-radius: 10px;
  padding: 0.5rem;
  background-color: #e9ebf0;
  box-shadow: rgba(17, 12, 46, 0.1) 0px 10px 100px 0px;
  border: 1px solid #bcc2d1;

  .modal__open__button {
    cursor: pointer;
    margin-right: 0;
  }
  .modal__nested__title {
    display: flex;
    margin-right: 2rem;
    justify-content: center;
    align-items: center;


    .modal__nested__icon {
      color: $Blue-dark;
      width: 24px;
      height: 24px;
      margin-right: 8px;
      background-color: #d3d6e0;
      border-radius: 50%;
      padding: 4px;
    }
  }
}
